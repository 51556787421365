import React from "react";
import { blogContent } from "src/Page_Content/blogData";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
function Blog() {
  const { searchQuery } = useSelector((state) => state.searchBlog);
  const filteredContent = blogContent.filter((section) =>
    section.heading.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <>
      {/* blogs */}
      {filteredContent.length > 0 ? (
        filteredContent
          .slice()
          .reverse()
          .map((section, index) => (
            <div className="group shadow-blog2" data-aos="fade-up">
              {/* blog thumbnail */}
              <div className="overflow-hidden relative">
                <img src={section?.image} alt="" className="w-full" />
                {/* <div className="text-size-22 leading-6 font-semibold text-white px-15px py-5px md:px-6 md:py-2 bg-primaryColor rounded text-center absolute top-5 right-5">
                              <h3>
                                24 <br />
                                Feb
                              </h3>
                            </div> */}
              </div>
              {/* blog content */}
              <div className="pt-26px pb-5 px-30px">
                <h3 className="text-2xl md:text-size-32 lg:text-size-28 2xl:text-size-34 leading-34px md:leading-10 2xl:leading-13.5 font-bold text-blackColor2 hover:text-primaryColor dark:text-blackColor2-dark dark:hover:text-primaryColor">
                  {section?.heading}
                </h3>
                <div className="mb-14px pb-19px border-b border-borderColor dark:border-borderColor-dark">
                  <ul className="flex flex-wrap items-center gap-x-15px">
                    <li>
                      <span className="text-contentColor text-sm hover:text-primaryColor dark:text-contentColor-dark dark:hover:text-primaryColor">
                        <i className="icofont-calendar" /> {section?.date}
                      </span>
                    </li>
                    <li>
                      <span className="text-contentColor text-sm hover:text-primaryColor dark:text-contentColor-dark dark:hover:text-primaryColor">
                        <i className="icofont-speech-comments" /> 0 Comments
                      </span>
                    </li>
                  </ul>
                </div>
                {section.paragraphs?.map((paragraph, pIndex) => (
                  <p
                    key={pIndex}
                    className="text-base text-contentColor dark:text-contentColor-dark mb-15px !leading-30px"
                    dangerouslySetInnerHTML={{
                      __html: paragraph,
                    }}
                  />
                ))}
                <div className="flex justify-between items-center">
                  <div>
                    <Link
                      to={`/blog-details?name=${section?.heading}`}
                      // to={`/blog-details`}

                      className="uppercase text-secondaryColor hover:text-primaryColor"
                    >
                      READ MORE <i className="icofont-double-right" />
                    </Link>
                  </div>
                  {/* <div className="text-primaryColor hover:text-secondaryColor space-y-1">
                                <a href="/">
                                  <i className="icofont-share bg-whitegrey1 dark:bg-whitegrey1-dark hover:text-whiteColor hover:bg-primaryColor w-8 h-7 leading-7 text-center inline-block rounded transition-all duration-300" />
                                </a>
                                <a href="/">
                                  <i className="icofont-heart bg-whitegrey1 dark:bg-whitegrey1-dark hover:text-whiteColor hover:bg-primaryColor w-8 h-7 leading-7 text-center inline-block rounded transition-all duration-300" />
                                </a>
                              </div> */}
                </div>
              </div>
            </div>
          ))
      ) : (
        <div className="flex items-center justify-center min-h-64 ">
          <h1 className="text-2xl font-bold text-gray-800 p-2">
            No blog posts found for "{searchQuery}"
          </h1>
        </div>
      )}

      {/* pagination */}
      {/* <div>
            <ul className="flex items-center justify-center gap-15px mt-60px mb-30px">
              <li>
                <a
                  href="/"
                  className="w-10 h-10 leading-10 md:w-50px md:h-50px md:leading-50px text-center text-blackColor2 hover:text-whiteColor bg-whitegrey1 hover:bg-primaryColor dark:text-blackColor2-dark dark:hover:text-whiteColor dark:bg-whitegrey1-dark dark:hover:bg-primaryColor cursor-not-allowed"
                >
                  <i className="icofont-double-left" />
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="w-10 h-10 leading-10 md:w-50px md:h-50px md:leading-50px text-center text-whiteColor hover:text-whiteColor bg-primaryColor hover:bg-primaryColor dark:text-blackColor2-dark dark:hover:text-whiteColor dark:bg-whitegrey1-dark dark:hover:bg-primaryColor"
                >
                  1
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="w-10 h-10 leading-10 md:w-50px md:h-50px md:leading-50px text-center text-blackColor2 hover:text-whiteColor bg-whitegrey1 hover:bg-primaryColor dark:text-blackColor2-dark dark:hover:text-whiteColor dark:bg-whitegrey1-dark dark:hover:bg-primaryColor"
                >
                  2
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="w-10 h-10 leading-10 md:w-50px md:h-50px md:leading-50px text-center text-blackColor2 hover:text-whiteColor bg-whitegrey1 hover:bg-primaryColor dark:text-blackColor2-dark dark:hover:text-whiteColor dark:bg-whitegrey1-dark dark:hover:bg-primaryColor"
                >
                  3
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="w-10 h-10 leading-10 md:w-50px md:h-50px md:leading-50px text-center text-blackColor2 hover:text-whiteColor bg-whitegrey1 hover:bg-primaryColor dark:text-blackColor2-dark dark:hover:text-whiteColor dark:bg-whitegrey1-dark dark:hover:bg-primaryColor"
                >
                  <i className="icofont-double-right" />
                </a>
              </li>
            </ul>
          </div> */}
    </>
  );
}

export default Blog;
