import { Navigate, useRoutes } from "react-router-dom";
// layouts
// import SimpleLayout from "./layouts/simple";
import ProfileDashboardLayout from "./layouts/dashboard/ProfileDashboardLayout";
import OrganizationLayout from "./layouts/dashboard/OrganizationLayout";
import Page404 from "./pages/Page404";
import DashboardAppPage from "./pages/DashboardAppPage";
import RecordingPage from "./pages/RoomRecording/RecordingPage";
import MangeUsers from "./pages/admin-organization/ManageUser/MangeUsers";
import ServerRoom from "./pages/admin-organization/ServerRoom/ServerRoom";
import ServerRecordings from "./pages/admin-organization/ServerRecordings/ServerRecordings";
import SiteSettings from "./pages/admin-organization/SiteSettings/SiteSettings";
import RoomConfiguration from "./pages/admin-organization/RoomConfiguration/RoomConfiguration";
import Role from "./pages/admin-organization/Role/Role";
import Moderator from "./pages/room/Moderator/Home/Home";
import SingleRoom from "./pages/room/Moderator/SingleRoom/SingleRoom";
// ----------------------------------------------------------------------
import { AuthProtect } from "./utils/ProtectRoutes/AuthProtect/AuthProtect";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import AdminProfile from "./pages/user/AdminProfile/AdminProfile";
import ForgetPassword from "./pages/user/AdminProfile/ForgetPassword";
import DeleteAccount from "./pages/user/AdminProfile/DeleteAccount";
import JoinRoom from "./pages/JoinRoom/JoinRoom";
import JoinRoomLayout from "./layouts/joinRoom/JoinRoomLayout";
import PublicRecordes from "./pages/publicRecordes/PublicRecordes";
import OnlyJoin from "./pages/JoinRoom/OnlyJoin";
import AdminPanel from "./pages/admin-organization/Admin/AdminPanel";
// import Pricing from "./pages/Pricing/Pricing";
import Monitor from "./pages/admin-organization/Monitor/Monitor";
import VerifyEmail from "./pages/verifyEmail/VerifyEmail";
import ForgetPasswordUser from "./pages/ForgetPassword/ForgetPassword";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import SubscriptionPlans from "./pages/admin-organization/Subscriptionplans/Subscriptionplans";
import Checkout from "./pages/checkout/Checkout";
import MyMeeting from "./pages/user/MyMeeting/MyMeeting";
// import Features from "./pages/Features/Features";
import Feedback from "./pages/Feedback/Feedback";
import MyScbscription from "./pages/user/My Subscription/MyScbscription";
import MyTransaction from "./pages/user/My Transaction/MyTransaction";
import AllTransaction from "./pages/admin-organization/AllTransaction/AllTransaction";
import SuccessPage from "./pages/SuccessPage/SuccessPage";
import CancelPage from "./pages/CancelPage/CancelPage";
import ModeratorLayout from "./layouts/moderator/ModeratorLayout";
//new template page
import NewLayout from "./components/New components/NewLayout";
import Home from "./pages/New pages/Home/Home";
import LMS from "./pages/New pages/LMS/LMS";
import OnlineClasses from "./pages/New pages/Online_Classes/OnlineClasses";
import KindsPlatform from "./pages/New pages/KidsPlatform/KindsPlatform";
import IntegrationPage from "./pages/New pages/Integration/Integration";
import Marketplace from "./pages/New pages/Marketplace/Marketplace";
import Testimonial from "./pages/New pages/Testimonial/Testimonial";
import NewPricing from "./pages/New pages/Pricing/Pricing";
import TermsConditions from "./pages/New pages/Policy/TermsConditions";
import PricingPolicy from "./pages/New pages/Policy/PricingPolicy";
import Privacy from "./pages/New pages/Policy/Privacy";
import CancellationRefund from "./pages/New pages/Policy/CancellationRefund";
import Blog from "./pages/New pages/Blog/Blog";
import BlogLayout from "./pages/New pages/Blog/BlogLayout";
import BlogDetails from "./pages/New pages/Blog/BlogDetails";
import Survey from "./pages/New pages/Survey/Survey";
import Contact from "./pages/New pages/Contact/Contact";
import FAQ from "./pages/New pages/FAQ/FAQ";
// import JoinClasses from "./pages/New pages/Online_Classes/JoinClasses";
import JoinScheduleRoom from "./pages/New pages/JoinRoom/JoinRoom";
import CourseDetails from "./pages/New pages/CourseDetails/CourseDetails";
import Instructors from "./pages/New pages/Instructors/Instructors";
import InstructorDetails from "./pages/New pages/Instructors/InstructorDetails";
import ApplyAsInstructor from "./pages/New pages/Instructors/ApplyAsInstructor";
import AllRooms from "./pages/New pages/Online_Classes/AllRooms";
import PracticalTest from "./pages/New pages/PracticalTest/PracticalTest";
import MoreCourses from "./pages/New pages/LMS/MoreCourses";
export default function Router() {
  const routes = useRoutes([
    //new template layout

    {
      path: "/",
      element: <NewLayout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "lms-ai",
          element: <LMS />,
        },
        {
          path:"all-courses",
          element:<MoreCourses/>
        },
        { path: "course-details", element: <CourseDetails /> },
        {
          path: "online_classes",
          element: <OnlineClasses />,
        },
        {
          path: "join-classes",
          // element: <JoinClasses />,
          element: <AllRooms />,
        },
        {
          path: "Join-meeting",
          element: <JoinScheduleRoom />,
        },
        {
          path: "kids-platforms",
          element: <KindsPlatform />,
        },
        {
          path: "integrationPage",
          element: <IntegrationPage />,
        },
        {
          path: "marketplace",
          element: <Marketplace />,
        },
        {
          path: "price",
          element: <NewPricing />,
        },
        {
          path: "checkout",
          element: <Checkout />,
        },
        {
          path:"practical-test",
          element:<PracticalTest/>
        },
        {
          path: "testimonial",
          element: <Testimonial />,
        },
        {
          path: "terms-and-conditions",
          element: <TermsConditions />,
        },
        {
          path: "privacy-policy",
          element: <Privacy />,
        },
        {
          path: "pricing-policy",
          element: <PricingPolicy />,
        },
        {
          path: "cancellation-refund-policy",
          element: <CancellationRefund />,
        },

        {
          path: "/",
          element: <BlogLayout />,
          children: [
            {
              path: "/blog",
              element: <Blog />,
            },
            {
              path: "/blog-details",
              element: <BlogDetails />,
            },
          ],
        },
        {
          path: "survey",
          element: <Survey />,
        },
        {
          path: "contact",
          element: <Contact />,
        },
        {
          path: "faq",
          element: <FAQ />,
        },
        {
          path: "instructors",
          element: <Instructors />,
        },
        {
          path: "instructor-details",
          element: <InstructorDetails />,
        },
        {
          path: "become-an-instructor",
          element: <ApplyAsInstructor />,
        },
      ],
    },

    {
      path: "login",
      element: <SignIn />,
    },

    {
      path: "signup",
      element: <SignUp />,
    },

    {
      path: "room/:id/public_recordings",
      element: (
        <JoinRoomLayout>
          <PublicRecordes />
        </JoinRoomLayout>
      ),
    },

    {
      path: "room/:id/join",
      element: <JoinRoom />,
    },

    {
      path: ":id/verifyEmail",
      element: <VerifyEmail />,
    },
    {
      path: "forgetpassword",
      element: <ForgetPasswordUser />,
    },
    {
      path: ":id/resetPassword",
      element: <ResetPassword />,
    },

    {
      path: "success",
      element: <SuccessPage />,
    },
    {
      path: "cancel",
      element: <CancelPage />,
    },
    { path: "404", element: <Page404 /> },


    {
      element: <ModeratorLayout />,
      children: [
        {
          path: "room",
          element: (
            <AuthProtect>
              <Moderator />
            </AuthProtect>
          ),
        },
        {
          path: "feedback",
          element: <Feedback />,
        },
        {
          path: "room/:id",
          element: (
            <AuthProtect>
              <SingleRoom />
            </AuthProtect>
          ),
        },
        {
          path: "recordings",
          element: (
            <AuthProtect>
              <RecordingPage />
            </AuthProtect>
          ),
        },
        {
          path: "only_join",
          element: (
            <AuthProtect>
              <OnlyJoin />
            </AuthProtect>
          ),
        },
      ],
    },

    {
      path: "/settings",
      element: (
        <AuthProtect>
          <ProfileDashboardLayout />
        </AuthProtect>
      ),
      children: [
        { element: <Navigate to="/settings/profile" />, index: true },
        { path: "profile", element: <AdminProfile /> },
        { path: "forgetPassword", element: <ForgetPassword /> },
        { path: "deleteAccount", element: <DeleteAccount /> },
        { path: "mymeeting", element: <MyMeeting /> },
        { path: "mysubscription", element: <MyScbscription /> },
        { path: "mytransaction", element: <MyTransaction /> },
      ],
    },
    {
      path: "/organization",
      element: (
        <AuthProtect>
          <OrganizationLayout />
        </AuthProtect>
      ),
      children: [
        { element: <Navigate to="/organization/admin" />, index: true },
        { path: "admin", element: <AdminPanel /> },
        { path: "dashboard", element: <DashboardAppPage /> },
        { path: "users", element: <MangeUsers /> },
        { path: "server_rooms", element: <ServerRoom /> },
        { path: "server_recordings", element: <ServerRecordings /> },
        { path: "site_settings", element: <SiteSettings /> },
        { path: "room_configuration", element: <RoomConfiguration /> },
        { path: "roles", element: <Role /> },
        { path: "monitor", element: <Monitor /> },
        { path: "subscription_plans", element: <SubscriptionPlans /> },
        { path: "alltransaction", element: <AllTransaction /> },
      ],
    },
    // {
    //   element: <SimpleLayout />,
    //   children: [
    //     { element: <Navigate to="/" />, index: true },

    //     { path: "*", element: <Navigate to="/404" /> },
    //   ],
    // },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
