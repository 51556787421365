import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
// import Chart from 'react-apexcharts';
// @mui
import { Box, Card, CardHeader, Typography, useTheme } from "@mui/material";
// utils

// components
import { useEffect, useState } from "react";
import RoomSharedDetails from "src/components/dashboard/roomSharedDetails/RoomSharedDetails";

// ----------------------------------------------------------------------

AppRoomShared.propTypes = {
  title: PropTypes.string,
  // subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
};

export default function AppRoomShared({
  title,
  // subheader,
  chartData,
  // direction,
  // users,
  // setSelectedNamesData,
  // ...other
}) {
  const theme = useTheme();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (chartData) {
      console.log(chartData, "chartData");

      setData(chartData);
    }
  }, [chartData]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clickedUserDetails, setClickedUserDetails] = useState(null);
  const [userName, setUserName] = useState("");

  const userNames = Array.from(new Set(data.map((item) => item.userName)));
  const roomNames = Array.from(new Set(data.map((item) => item.roomName)));

  const chartSeries = roomNames.map((room) => ({
    name: room,
    data: userNames.map((user) => {
      const roomData = data.find(
        (item) => item.userName === user && item.roomName === room
      );
      return roomData ? roomData.sharedWith.length : 0;
    }),
  }));
console.log(chartSeries,"chartSeries");

  const chartOptions = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: { show: false },
      zoom: { enabled: false },
      animations: { enabled: true },
      // foreColor: theme.palette.text.disabled,
      // fontFamily: theme.typography.fontFamily,,
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const userName = userNames[config.dataPointIndex];
          setUserName(userName);
          const userDetails = data.filter(
            (item) => item.userName === userName && item.sharedWith.length > 0
          );
          setClickedUserDetails(userDetails);
          setIsModalOpen(true);
        },
      },
    },
    xaxis: {
      categories: userNames,
      title: {
        text: "User Names",
      },
    },
    yaxis: {
      title: {
        text: "Number of Users Sharing",
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    legend: {
      show: false,
      position: "top",
    },

    grid: {
      strokeDashArray: 3,
      borderColor: theme.palette.divider,
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
  };

  return (
    <Card>
      <CardHeader
        title={title}
        // subheader={subheader}
        titleTypographyProps={{ style: { fontSize: "26px", fontWeight: 600 } }}
        subheaderTypographyProps={{
          style: { fontSize: "26px", fontWeight: 600 },
        }}
        style={{
          backgroundColor: theme.palette.primary.main,
          padding: "15px",
          color: "white",
        }}
      />
      <Box sx={{ mx: 3, mt: 2 }} dir="ltr">
        {chartData?.length > 0 ? (
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            height={350}
          />
        ) : (
          <Box
            sx={{
              width: "100%",
              height: 380,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{ color: "#E8063C" }}
              // style={{
              //   fontSize: "1.6rem",
              // }}
            >
              No Data !
            </Typography>
          </Box>
        )}
      </Box>
      <RoomSharedDetails
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        clickedUserDetails={clickedUserDetails}
        userName={userName}
      />
    </Card>
  );
}
