import React from "react";
import PrimaryButton from "src/components/New components/PrimaryButton";
import OverviewSection from "./OverviewSection";
// import Slider from "./Slider";
//images
import MainGif from "src/assets/images/Kids-platforms/kids platform banner.gif";
import Kids_Platform_image from "src/assets/images/Kids-platforms/Kids Platform - Image.png";
import cultivates_creativity_image from "src/assets/images/Kids-platforms/cultivates-creativity.svg";
import enhances_learning_enjoyment_icon from "src/assets/images/Kids-platforms/enhances-learning-enjoyment.svg";
import nurtures_aspiring_writers_icon from "src/assets/images/Kids-platforms/nurtures-aspiring-writers.svg"
import improves_writing_proficiency_icon from "src/assets/images/Kids-platforms/improves-writing-proficiency.svg"
import Write_StoriesWrite_Stories_image from "src/assets/images/Kids-platforms/Kids page.gif"
import Voice_To_Text_image from "src/assets/images/Kids-platforms/Voice_To_Text_image.gif"
import Dynamic_Dashboard_image from "src/assets/images/Kids-platforms/Kids page (2).gif"
import Class_management_image from "src/assets/images/Kids-platforms/Untitled-design-2.gif";
import placeholderUser from "src/assets/images/Kids-platforms/placeholderUser.webp";

import { Link } from "react-router-dom";

function KindsPlatform() {
  return (
    <div>
      {/* banner section */}
      <section>
        {/* banner section */}
        <div className="hero kindergarden bg-lightGrey11 dark:bg-lightGrey11-dark relative z-0 overflow-hidden py-50px ">
          {/* animated icons */}
          <div>
            <img
              className="absolute left-10 bottom-[233px] md:left-[248px] md:bottom-[143px] lg:left-10 lg:bottom-[112px] 3xl:bottom-[233px] animate-move-var opacity-35 z-10"
              src="./assets/images/herobanner/herobanner__1.png"
              alt=""
            />
            <img
              className="absolute left-0 top-0 md:left-[50px] md:top-[110px] lg:left-[30px] lg:top-[75px] 2xl:left-[50px] 2xl:top-16 3xl:top-[110px] animate-spin-slow"
              src="./assets/images/herobanner/herobanner__2.png"
              alt=""
            />
            <img
              className="absolute md:left-[210px] md:top-[50px] animate-move-var2 hidden md:block"
              src="./assets/images/herobanner/herobanner__3.png"
              alt=""
            />
            <img
              className="absolute top-20 left-[872px] md:left-[872px] lg:left-[595px] 2xl:left-[872px] hidden md:block animate-move-hor"
              src="./assets/images/herobanner/herobanner__4.png"
              alt=""
            />
            <img
              className="absolute top-0 right-0 md:right-[110px] md:top-[100px] lg:right-[13px] lg:top[90px] 2xl:right-[82px] 2xl:top-[100px] 3xl:right-[110px] animate-move-hor"
              src="./assets/images/herobanner/herobanner__5.png"
              alt=""
            />
          </div>
          {/* Swiper */}
          <div className="swiper ecommerce-slider">
            <div className="swiper-wrapper">
              {/* Hero  1 */}
              <div className="swiper-slide px-15px">
                <div className="container 2xl:container-secondary-md relative overflow-hidden">
                  <div className="grid grid-cols-1 md:grid-cols-12 items-center gap-30px">
                    {/* banner Left */}
                    <div
                      data-aos="fade-up"
                      className="md:col-start-1 md:col-span-12 lg:col-start-1 lg:col-span-7"
                    >
                      <div className="3xl:pr-135px">
                        <h3 className="uppercase text-secondaryColor text-size-15 mb-5px md:mb-15px font-inter tracking-[4px] font-semibold">
                          Story writing Platform for Kids
                        </h3>
                        <h1 className="text-size-35 md:text-size-65 lg:text-5xl 2xl:text-size-65 leading-42px md:leading-18 lg:leading-15 2xl:leading-18 text-blackColor dark:text-blackColor-dark md:tracking-half lg:tracking-normal 2xl:tracking-half font-bold mb-15px">
                          {/* Best Kindergarten Awesome
                          <span className="text-secondaryColor">Solution</span> */}
                          Kids creative adventure begins with Storylane
                        </h1>
                        <p className="text-size-15md:text-lg text-blackColor dark:text-blackColor-dark font-medium">
                          Storylane is a creative haven for children, aiming to
                          ignite their imagination and enhance their writing
                          skills. Its remarkable attribute, WriteStories,
                          empowers kids to craft captivating narratives inspired
                          by captivating visuals, fostering a sense of joy and
                          creativity.
                        </p>
                        <div className="mt-30px">
                          <PrimaryButton primary={true} onClick={() => window.location.href = 'https://app.storylane.in/login'}>
                            Start a Free Trial
                          </PrimaryButton>
                        </div>
                      </div>
                    </div>
                    {/* banner right */}
                    <div
                      data-aos="fade-up"
                      className="md:col-start-1 md:col-span-8 lg:col-start-8 lg:col-span-5"
                    >
                      <div className="relative">
                        <Link to={"https://app.storylane.in/"}>
                        <img src={MainGif} alt="" className="w-full" />
                        </Link>
                     
                      </div>
                    </div>
                  </div>
                </div>
              </div>
           
            </div>
            {/* <div className="swiper-button-next" />
            <div className="swiper-button-prev" /> */}
          </div>
          {/* <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
            <span
              className="swiper-pagination-bullet"
              tabIndex={0}
              role="button"
              aria-label="Go to slide 1"
            />
            <span
              className="swiper-pagination-bullet swiper-pagination-bullet-active"
              tabIndex={0}
              role="button"
              aria-label="Go to slide 2"
              aria-current="true"
            />
            <span
              className="swiper-pagination-bullet"
              tabIndex={0}
              role="button"
              aria-label="Go to slide 3"
            />
          </div> */}
        </div>
      </section>

      {/* about  section */}
      <section>
        <div className="container pt-70px">
          {/* about section  */}
          <div className="grid grid-cols-1 lg:grid-cols-2 pt-30px gap-x-30px">
            {/* about left */}
            <div
              className="relative z-0 mb-30px lg:mb-0 pb-0 md:pb-30px xl:pb-0 overflow-visible"
              data-aos="fade-up"
            >
              <div className="tilt">
                <img className="" src={Kids_Platform_image} alt="" />=
              </div>
              {/* experience */}
              {/* <div className="px-10px py-3 md:py-25px border-l-4 border-primaryColor shadow-experience absolute bottom-0 left-0 bg-white dark:bg-whiteColor-dark animate-move-var w-[290px]">
                <div className="counter flex items-center">
                  <p className="text-[40px] text-primaryColor font-bold uppercase pr-10px leading-1">
                    <span data-countup-number={25}>25</span>+
                  </p>
                  <p className="text-blackColor dark:text-blackColor-dark font-bold leading-26px">
                    YEARS EXPERIENCE JUST ACHIVED
                  </p>
                </div>
              </div> */}
            </div>
            {/* about right */}
            <div data-aos="fade-up">
              <span className="text-sm font-semibold text-primaryColor bg-whitegrey3 px-6 py-5px mb-5 rounded-full inline-block">
                About Us
              </span>
              <h3 className="text-3xl md:text-size-45 leading-10 md:leading-2xl font-bold text-blackColor dark:text-blackColor-dark pb-25px">
                {/* Welcome to the
                <span className="relative after:w-full after:h-[7px] after:bg-secondaryColor after:absolute after:left-0 after:bottom-3 md:after:bottom-5">
                  Online
                </span>
                Learning Center */}
                Introduction to the Kids Platform
              </h3>
              <p className="text-sm md:text-base leading-7 text-contentColor dark:text-contentColor-dark mb-6 pl-3 border-l-2 border-primaryColor">
                Storylane is an exceptional tool for nurturing writing skills,
                offering a secure space where parents and teachers can track
                progress and provide feedback, making it a favorite for children
                and caregivers alike. With engaging features, it’s destined to
                become a cherished companion for young learners.
              </p>
              <ul className="space-y-5">
                <li className="flex items-center group">
                  <i className="icofont-check px-2 py-2 text-primaryColor bg-whitegrey3 bg-opacity-40 group-hover:bg-primaryColor group-hover:text-white group-hover:opacity-100 mr-15px dark:bg-whitegrey1-dark" />
                  <p className="text-sm md:text-base font-medium text-blackColor dark:text-blackColor-dark">
                    Blends creativity with learning in a safe, interactive space
                  </p>
                </li>
                <li className="flex items-center group">
                  <i className="icofont-check px-2 py-2 text-primaryColor bg-whitegrey3 bg-opacity-40 group-hover:bg-primaryColor group-hover:text-white group-hover:opacity-100 mr-15px dark:bg-whitegrey1-dark" />
                  <p className="text-sm md:text-base font-medium text-blackColor dark:text-blackColor-dark">
                    Empowers children to improve writing skills with parental
                    and teacher support
                  </p>
                </li>
                <li className="flex items-center group">
                  <i className="icofont-check px-2 py-2 text-primaryColor bg-whitegrey3 bg-opacity-40 group-hover:bg-primaryColor group-hover:text-white group-hover:opacity-100 mr-15px dark:bg-whitegrey1-dark" />
                  <p className="text-sm md:text-base font-medium text-blackColor dark:text-blackColor-dark">
                    Fosters imagination while building essential communication
                    skills
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* program  section */}
      <section>
        <div className="container pt-100px pb-130px">
          {/* program Heading */}
          <div className="mb-5 md:mb-10" data-aos="fade-up">
            <div className="text-center">
              <span className="text-sm font-semibold text-primaryColor bg-whitegrey3 px-6 py-5px mb-5 rounded-full inline-block">
                What We Offer
              </span>
            </div>
            <h3 className="text-3xl md:text-[35px] lg:text-size-38 3xl:text-size-42 leading-10 mf:leading-45px 2xl:leading-50px 3xl:leading-2xl font-bold text-blackColor dark:text-blackColor-dark text-center">
              {/* Early learning
              <span className="relative after:w-full after:h-[7px] z-0 after:bg-secondaryColor after:absolute after:left-0 after:bottom-3 md:after:bottom-5 after:z-[-1]">
                Programs
              </span>
              <br />
              For Your Baby. */}
              FEATURES
            </h3>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-30px text-center">
            <div className="shadow-dropdown-secodary" data-aos="fade-up">
              <div className="p-10px">
                <img
                  src={Write_StoriesWrite_Stories_image}
                  alt=""
                  className="w-full"
                />
              </div>
              <div className="pt-30px pr-5 pb-10 pl-30px md:py-5 md:px-10px lg:pt-30px lg:pr-10px lg:pb-10 lg:pl-5 2xl:pt-30px 2xl:pr-5 2xl:pb-10 2xl:pl-30px">
                <p className="text-primaryColor font-bold uppercase mb-15px">
                  Write Stories
                </p>
                {/* <h4 className="text-2xl md:text-size-34 lg:text-2xl 2xl:text-xl 3xl:text-size-34 font-bold">
                  <a
                    href="/"
                    className="leading-1.2 text-blackColor hover:text-primaryColor dark:text-blackColor-dark dark:hover:text-primaryColor mb-5"
                  >
                    Play Group
                  </a>
                </h4> */}
                <span className="font-medium text-blackColor  dark:text-blackColor-dark ">
                  Inspire your students to embark on a creative journey,
                  crafting their own stories after selecting a book.
                </span>
              </div>
            </div>
            <div className="shadow-dropdown-secodary" data-aos="fade-up">
              <div className="p-10px">
                <img
                  src={Voice_To_Text_image}
                  alt=""
                  className="w-full"
                />
              </div>
              <div className="pt-30px pr-5 pb-10 pl-30px md:py-5 md:px-10px lg:pt-30px lg:pr-10px lg:pb-10 lg:pl-5 2xl:pt-30px 2xl:pr-5 2xl:pb-10 2xl:pl-30px">
                <p className="text-primaryColor font-bold uppercase mb-15px">
                  Voice To Text
                </p>

                <span className="font-medium text-blackColor  dark:text-blackColor-dark ">
                  Students can speak, with their words instantly converted into
                  text, helping them improve their oratory skills as well.t
                </span>
              </div>
            </div>
            <div className="shadow-dropdown-secodary" data-aos="fade-up">
              <div className="p-10px">
                <img
                  src={Dynamic_Dashboard_image}
                  alt=""
                  className="w-full"
                />
              </div>
              <div className="pt-30px pr-5 pb-10 pl-30px md:py-5 md:px-10px lg:pt-30px lg:pr-10px lg:pb-10 lg:pl-5 2xl:pt-30px 2xl:pr-5 2xl:pb-10 2xl:pl-30px">
                <p className="text-primaryColor font-bold uppercase mb-15px">
                  Dynamic Dashboard
                </p>

                <span className="font-medium text-blackColor  dark:text-blackColor-dark ">
                  Experience interactive learning as progress is tracked,
                  achievements celebrated, and milestone stickers lead them to a
                  grand castle.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Popular Subject Sectoion*/}
      <div
        style={{
          // backgroundImage: `url('assets/images/about/about_bg_1.jpg')`,
          backgroundImage: `url(${Class_management_image})`,

        }}
        className=" pt-50px pb-110px bg-cover"
      >
        <div className="container">
          {/* about section  */}
          <div className="grid grid-cols-1 lg:grid-cols-2 pt-30px gap-x-30px">
            {/* about left */}
            <div
              className="mb-30px lg:mb-0 pb-0 md:pb-30px xl:pb-0"
              data-aos="fade-up"
            >
              <div className="relative">
                <div>
                  <img
                    className="absolute bottom-9 lg:bottom-[-50px] right-[50px] animate-move-hor"
                    src="./assets/images/service/service__shape__1.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            {/* sbject right */}
            <div className="relative z-0 overflow-visible">
              {/* animted area */}
              <div data-aos="fade-up">
                <img
                  className="absolute sm:block xl:left-1/4 z-[-1] top-6 animate-move-var"
                  src="./assets/images/service/service__shape__bg__1.png"
                  alt=""
                />
              </div>
              {/* subject card */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-30px">
                {/* card 1 */}
                <div data-aos="fade-up">
                  <div className="p-35px group bg-whiteColor rounded-xl transition-all duration-300 shadow-dropdown-secodary hover:bg-primaryColor hover:-translate-y-5px hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor">
                    {/* card svg */}
                    <div className="-translate-y-2 flex justify-between overflow-hidden mb-10px">
                      <div className="relative ">
                        <img
                          loading="lazy"
                          src={cultivates_creativity_image}
                          alt=""
                          className="w-15 h-15"
                        />
                      </div>
                      <div className="service__small__img w-7 h-[60px]">
                        <svg
                          className="icon__hover__img w-7 h-[60px] opacity-0 group-hover:opacity-100"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z"
                            stroke="#FFB31F"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    {/* card content */}
                    <div>
                      <a
                        href="/"
                        className="text-2xl lg:text-lg 2xl:text-2xl font-semibold mb-15px md:mb-0 2xl:mb-15px hover:text-secondaryColor font-hind  dark:text-whiteColor dark:hover:text-secondaryColor dark:transition-all dark:duration-300"
                      >
                        Cultivates Creativity
                      </a>
                      <p className="text-sm lg:text-xs 2xl:text-sm text-contentColor group-hover:text-whiteColor mb-15px lg:mb-2 2xl:mb-15px transition-all duration-300">
                        Storylane sparks imagination by providing visual
                        prompts, nurturing unique creative voices in children
                      </p>
                      <div>
                        <div className="text-sm text-black dark:text-blackColor-darkColor group-hover:text-whiteColor dark:text-blackColor-dark dark:group-hover:text-whiteColor-dark">
                          <a
                            href="/"
                            className="text-sm font-medium hover:text-secondaryColor transition-none"
                          >
                            View Subject
                            <i className="icofont-long-arrow-right" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* card 2 */}
                <div data-aos="fade-up">
                  <div className="md:translate-y-[30px]">
                    <div className="p-35px group bg-whiteColor rounded-xl transition-all duration-300 shadow-dropdown-secodary hover:bg-primaryColor hover:-translate-y-5px hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor ">
                      {/* card svg */}
                      <div className="-translate-y-2 flex justify-between overflow-hidden mb-10px">
                        <div className="relative ">
                          <img
                            loading="lazy"
                            src={enhances_learning_enjoyment_icon}
                            alt=""
                            className="w-15 h-15"
                          />
                        </div>
                        <div className="service__small__img w-7 h-[60px]">
                          <svg
                            className="icon__hover__img w-7 h-[60px] opacity-0 group-hover:opacity-100"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z"
                              stroke="#FFB31F"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                      {/* card content */}
                      <div>
                        <a
                          href="/"
                          className="text-2xl lg:text-lg 2xl:text-2xl font-semibold mb-15px md:mb-0 2xl:mb-15px hover:text-secondaryColor font-hind  dark:text-whiteColor dark:hover:text-secondaryColor dark:transition-all dark:duration-300"
                        >
                          Enhances Learning Enjoyment
                        </a>
                        <p className="text-sm lg:text-xs 2xl:text-sm text-contentColor group-hover:text-whiteColor mb-15px lg:mb-2 2xl:mb-15px transition-all duration-300">
                          It makes learning fun with interactive writing
                          activities, fostering a love for language and
                          storytelling.
                        </p>
                        <div>
                          <div className="text-sm text-black dark:text-blackColor-darkColor group-hover:text-whiteColor dark:text-blackColor-dark dark:group-hover:text-whiteColor-dark">
                            <a
                              href="/"
                              className="text-sm font-medium hover:text-secondaryColor transition-all duration-300"
                            >
                              View Subject
                              <i className="icofont-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* card 3 */}
                <div data-aos="fade-up">
                  <div className="p-35px group bg-whiteColor rounded-xl transition-all duration-300 shadow-dropdown-secodary hover:bg-primaryColor hover:-translate-y-5px hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor">
                    {/* card svg */}
                    <div className="-translate-y-2 flex justify-between overflow-hidden mb-10px">
                    <div className="relative ">
                        <img
                          loading="lazy"
                          src={nurtures_aspiring_writers_icon}
                          alt=""
                          className="w-15 h-15"
                        />
                      </div>
                      <div className="service__small__img w-7 h-[60px]">
                        <svg
                          className="icon__hover__img w-7 h-[60px] opacity-0 group-hover:opacity-100"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z"
                            stroke="#FFB31F"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    {/* card content */}
                    <div>
                      <a
                        href="/"
                        className="text-2xl lg:text-lg 2xl:text-2xl font-semibold mb-15px md:mb-0 2xl:mb-15px hover:text-secondaryColor font-hind  dark:text-whiteColor dark:hover:text-secondaryColor dark:transition-all dark:duration-300"
                      >
                        Nurtures Aspiring Writers
                      </a>
                      <p className="text-sm lg:text-xs 2xl:text-sm text-contentColor group-hover:text-whiteColor mb-15px lg:mb-2 2xl:mb-15px transition-all duration-300">
                        Promoting collaboration, it teaches kids to work
                        together, exchange ideas, and appreciate each other's
                        creativity.
                      </p>
                      <div>
                        <div className="text-sm text-black dark:text-blackColor-darkColor group-hover:text-whiteColor dark:text-blackColor-dark dark:group-hover:text-whiteColor-dark">
                          <a
                            href="/"
                            className="text-sm font-medium hover:text-secondaryColor transition-none"
                          >
                            View Subject
                            <i className="icofont-long-arrow-right" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* card 4 */}
                <div data-aos="fade-up">
                  <div className="md:translate-y-[30px]">
                    <div className="p-35px group bg-whiteColor rounded-xl transition-all duration-300 shadow-dropdown-secodary hover:bg-primaryColor hover:-translate-y-5px hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor ">
                      {/* card svg */}
                      <div className="-translate-y-2 flex justify-between overflow-hidden mb-10px">
                      <div className="relative ">
                          <img
                            loading="lazy"
                            src={improves_writing_proficiency_icon}
                            alt=""
                            className="w-15 h-15"
                          />
                        </div>
                        <div className="service__small__img w-7 h-[60px]">
                          <svg
                            className="icon__hover__img w-7 h-[60px] opacity-0 group-hover:opacity-100"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z"
                              stroke="#FFB31F"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                      {/* card content */}
                      <div>
                        <a
                          href="/"
                          className="text-2xl lg:text-lg 2xl:text-2xl font-semibold mb-15px md:mb-0 2xl:mb-15px hover:text-secondaryColor font-hind  dark:text-whiteColor dark:hover:text-secondaryColor dark:transition-all dark:duration-300"
                        >
                          Improves Writing Proficiency
                        </a>
                        <p className="text-sm lg:text-xs 2xl:text-sm text-contentColor group-hover:text-whiteColor mb-15px lg:mb-2 2xl:mb-15px transition-all duration-300">
                          Through practice and feedback, Storylane helps
                          children refine their writing skills and communication
                          abilities.
                        </p>
                        <div>
                          <div className="text-sm text-black dark:text-blackColor-darkColor group-hover:text-whiteColor dark:text-blackColor-dark dark:group-hover:text-whiteColor-dark">
                            <a
                              href="/"
                              className="text-sm font-medium hover:text-secondaryColor transition-all duration-300"
                            >
                              View Subject
                              <i className="icofont-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* overview section */}
      <OverviewSection />
      <>
        {/* About Instructor Section*/}
        <section className="bg-lightGrey10 dark:dark:bg-lightGrey10-dark relative">
          <div>
            <img
              src="./assets/images/about/about_6.png"
              alt=""
              className="absolute top-[110px] left-[216px] animate-move-hor z-1"
            />
            <img
              src="./assets/images/about/about_7.png"
              alt=""
              className="absolute top-[360px] left-[162px] md:left-[262px] lg:left-[318px] 2xl:left-[162px] animate-spin-slow z-1"
            />
            <img
              src="./assets/images/about/about_9.png"
              alt=""
              className="absolute top-[430px] left-[156px] md:top-[630px] md:left-[476px] lg:top-[433px] lg:left-[196px] 2xl:top-[430px] 2xl:left-[156px] animate-move-var z-1"
            />
          </div>
          <div className="container pt-20 pb-20 2xl:pt-30 2xl:pb-90px">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-15">
              <div
                className="lg:col-start-1 lg:col-span-1 md:col-start-1 md:col-span-2"
                data-aos="fade-up"
              >
                <div className="relative">
                  <span className="text-sm font-semibold text-primaryColor bg-whitegrey3 px-6 py-5px mb-5 rounded-full inline-block">
                    Course List
                  </span>
                  <h3 className="text-3xl md:text-size-35 2xl:text-size-38 3xl:text-size-42 leading-10 md:leading-45px 2xl:leading-50px 3xl:leading-2xl font-bold text-blackColor pb-25px dark:text-blackColor-dark">
                    WHAT DO STORYLANE USERS SAY+
                  </h3>
                  <p className="text-sm md:text-base text-contentColor mb-5 2xl:mb-45px dark:text-contentColor-dark">
                    Construction is a general term meaning the art and science
                    to form systems organizations and comes from Latin
                    Construction is
                  </p>
                  <div>
                    <a
                      className="text-size-15 text-whiteColor bg-secondaryColor px-25px py-10px border border-secondaryColor hover:text-secondaryColor hover:bg-whiteColor inline-block rounded dark:hover:text-whiteColor dark:hover:bg-secondaryColor"
                      href="https://storylane.in/testimonials/"
                    >
                      Explore More <i className="icofont-long-arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div data-aos="fade-up">
                <div className="bg-whiteColor px-25px py-50px mb-22px relative dark:bg-whiteColor-dark">
                  <p className="text-base lg:text-sm 2xl:text-base text-contentColor dark:text-contentColor-dark">
                    “This competition is very nice. The kids show great interest by looking at the images and writing stories. They are thinking of different ways to write the stories. My son is interested in writing more stories as well. They are fascinating.”
                  </p>
                  <div className="text-xl lg:text-3xl text-whiteColor bg-primaryColor w-10 h-10 lg:w-15 lg:h-15 flex items-center justify-center absolute top-0 left-0 md:-translate-y-1/2 md:-translate-x-1/2 z-20">
                    <i className="icofont-quote-left" />
                  </div>
                  <span className="w-0 h-0 border-l-12 border-r-12 border-t-15 border-l-transparent border-r-transparent border-t-whiteColor absolute bottom-[-14px] left-[27px] dark:border-t-whiteColor-dark" />
                </div>
                <div className="flex items-center gap-5 2xl:gap-20">
                  <div>
                    <img
                      // src="./assets/images/about/about_5.png"
                      src={placeholderUser}
                      alt=""
                      className="w-[100px] rounded-full"
                    />
                  </div>
                  <div>
                    <h4 className="text-xl lg:text-lg 2xl:text-xl font-semibold text-blackColor dark:text-blackColor-dark">
                      	
Sowmya
                    </h4>
                    <p className="text-base lg:text-size-15 2xl:text-base text-lightGrey9 dark:text-lightGrey9-dark">
                    Parent of Y. Mokshagna from Mount Litera Zee school
                    </p>
                  </div>
                </div>
              </div>
              <div data-aos="fade-up">
                <div className="bg-whiteColor px-25px py-50px mb-22px relative dark:bg-whiteColor-dark">
                  <p className="text-base lg:text-sm 2xl:text-base text-contentColor dark:text-contentColor-dark">
                    “This story-writing program has truly improved her writing skills and boosted her imagination. I’m impressed by her level of involvement. Over the past few days, we’ve been brainstorming different story ideas, and she has been well-prepared. ”
                  </p>
                  <div className="text-xl lg:text-3xl text-whiteColor bg-primaryColor w-10 h-10 lg:w-15 lg:h-15 flex items-center justify-center absolute top-0 left-0 md:-translate-y-1/2 md:-translate-x-1/2 z-20">
                    <i className="icofont-quote-left" />
                  </div>
                  <span className="w-0 h-0 border-l-12 border-r-12 border-t-15 border-l-transparent border-r-transparent border-t-whiteColor absolute bottom-[-14px] left-[27px] dark:border-t-whiteColor-dark" />
                </div>
                <div className="flex items-center gap-5 2xl:gap-20">
                  <div>
                    <img
                      // src="./assets/images/about/about_5.png"
                      src={placeholderUser}
                      alt=""
                      className="w-[100px]  rounded-full"
                    />
                  </div>
                  <div>
                    <h4 className="text-xl lg:text-lg 2xl:text-xl font-semibold text-blackColor dark:text-blackColor-dark">
                    Umaeswari
                    </h4>
                    <p className="text-base lg:text-size-15 2xl:text-base text-lightGrey9 dark:text-lightGrey9-dark">
                    Parent of Kavimithra from Senthil public school, Salem
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      {/* <div className="mt-10 mb-10">
        <Slider />
      </div> */}
    </div>
  );
}

export default KindsPlatform;
