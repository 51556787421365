import React from 'react'

function MainHeaderText({children}) {
  return (
  
        <h3 className="text-3xl md:text-size-45 leading-10 md:leading-2xl font-bold text-blackColor dark:text-blackColor-dark pb-25px">
              {children}
              </h3>
    
  )
}

export default MainHeaderText
