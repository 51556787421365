import React from "react";
import { Link, Outlet } from "react-router-dom";
import Preloader from "./Preloader";
import Navbar from "src/pages/New pages/navbar/Navbar";
import atlearn_logo from "src/assets/images/Logo/atlearnSecondLogo.svg";
import ThemeController from "./Layout/Theme/ThemeController";
import ScrollUpButton from "./Layout/ScrollUpButton";
import { blogContent } from "src/Page_Content/blogData";
function NewLayout() {
  return (
    <div className="relative font-inter font-normal text-base leading-[1.8] bg-bodyBg dark:bg-bodyBg-dark z-0">
      <Preloader />

      <ThemeController />

      <ScrollUpButton />

      <Navbar />

      <Outlet />

      <footer className="bg-darkblack">
        <div className="container-fluid-2  pb-5 lg:pb-10">
          {/* footer main */}

          <section>
            <div className="grid grid-cols-12 gap-30px md:gap-y-5 lg:gap-y-0 pt-60px pb-50px md:pt-30px md:pb-30px lg:pt-50px lg:pb-20">
              {/* left */}
              <div
                className="col-start-1 col-span-12 md:col-span-6 lg:col-span-3 mr-30px"
                data-aos="fade-up"
              >
                <h4 className="text-size-22 font-bold text-whiteColor mb-3">
                  About us
                </h4>
                <p className="text-base lg:text-sm 2xl:text-base text-darkgray mb-30px leading-1.8 2xl:leading-1.8">
                  At Atlearn, we're transforming education with an all-in-one
                  online classroom platform that integrates essential tools for
                  students and educators, delivering an immersive and intuitive
                  learning experience.
                </p>
                <div className="flex items-center">
                  <div>
                    <i className="icofont-clock-time text-3xl text-whiteColor h-78px w-78px bg-primaryColor leading-78px mr-22px block text-center" />
                  </div>
                  <div>
                    <h6 className="text-lg text-whiteColor font-medium leading-29px">
                      OPENING HOURS
                    </h6>
                    <p className="text-sm text-whiteColor text-opacity-60 mb-1">
                      Monday to Friday : 10am -6pm
                    </p>
                    {/* <p className="text-sm text-whiteColor text-opacity-60">
                      Sunday - Closed
                    </p> */}
                  </div>
                </div>
              </div>

              {/* Policy */}
              <div
                className="col-start-1 col-span-12 md:col-start-7 lg:col-start-4 md:col-span-6 lg:col-span-2"
                data-aos="fade-up"
              >
                <h4 className="text-size-22 font-bold text-whiteColor mb-3">
                  Policy
                </h4>
                <ul className="flex flex-col gap-y-3">
                  <li>
                    <Link
                      to="/terms-and-conditions"
                      className="text-darkgray relative hover:text-secondaryColor after:transition-all after:duration-300 after:w-0 after:h-2px after:absolute after:bg-secondaryColor hover:after:w-full after:bottom-0 after:left-0"
                    >
                      Terms & Conditions
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/privacy-policy"
                      className="text-darkgray relative hover:text-secondaryColor after:transition-all after:duration-300 after:w-0 after:h-2px after:absolute after:bg-secondaryColor hover:after:w-full after:bottom-0 after:left-0"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/pricing-policy"
                      className="text-darkgray relative hover:text-secondaryColor after:transition-all after:duration-300 after:w-0 after:h-2px after:absolute after:bg-secondaryColor hover:after:w-full after:bottom-0 after:left-0"
                    >
                      Pricing Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/cancellation-refund-policy"
                      className="text-darkgray relative hover:text-secondaryColor after:transition-all after:duration-300 after:w-0 after:h-2px after:absolute after:bg-secondaryColor hover:after:w-full after:bottom-0 after:left-0"
                    >
                      Cancellation/Refund Policy
                    </Link>
                  </li>
                </ul>
              </div>

              {/* Usefull Links */}
              <div
                className="col-start-1 col-span-12 md:col-start-1 lg:col-start-6 md:col-span-6 lg:col-span-2 "
                data-aos="fade-up"
              >
                <h4 className="text-size-22 font-bold text-whiteColor mb-3">
                  Usefull Links
                </h4>
                <ul className="flex flex-col gap-y-3">
                  <li>
                    <Link
                      to="/blog"
                      className="text-darkgray relative hover:text-secondaryColor after:transition-all after:duration-300 after:w-0 after:h-2px after:absolute after:bg-secondaryColor hover:after:w-full after:bottom-0 after:left-0"
                    >
                      Blogs
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/faq"
                      className="text-darkgray relative hover:text-secondaryColor after:transition-all after:w-0 after:h-2px after:absolute after:bg-secondaryColor hover:after:w-full after:bottom-0 after:left-0"
                    >
                      FAQ
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact"
                      className="text-darkgray relative hover:text-secondaryColor after:transition-all after:w-0 after:h-2px after:absolute after:bg-secondaryColor hover:after:w-full after:bottom-0 after:left-0"
                    >
                      Contact
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/testimonial"
                      className="text-darkgray relative hover:text-secondaryColor after:transition-all after:w-0 after:h-2px after:absolute after:bg-secondaryColor hover:after:w-full after:bottom-0 after:left-0"
                    >
                      Testimonial
                    </Link>
                  </li>
                </ul>
              </div>

              {/* Quick Links */}
              <div
                className="col-start-1 col-span-12 md:col-start-7 lg:col-start-8 md:col-span-6 lg:col-span-2"
                data-aos="fade-up"
              >
                <h4 className="text-size-22 font-bold text-whiteColor mb-3">
                  Quick Links
                </h4>
                <ul className="flex flex-col gap-y-3">
                  <li>
                    <Link
                      to="https://lms.atlearn.in/"
                      className="text-darkgray relative hover:text-secondaryColor after:transition-all after:duration-300 after:w-0 after:h-2px after:absolute after:bg-secondaryColor hover:after:w-full after:bottom-0 after:left-0"
                    >
                      Enroll In Course
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/online_classes"
                      className="text-darkgray relative hover:text-secondaryColor after:transition-all after:duration-300 after:w-0 after:h-2px after:absolute after:bg-secondaryColor hover:after:w-full after:bottom-0 after:left-0"
                    >
                      Join Online Class
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/marketplace"
                      className="text-darkgray relative hover:text-secondaryColor after:transition-all after:duration-300 after:w-0 after:h-2px after:absolute after:bg-secondaryColor hover:after:w-full after:bottom-0 after:left-0"
                    >
                      Become a Tutuor
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/lms-ai"
                      className="text-darkgray relative hover:text-secondaryColor after:transition-all after:duration-300 after:w-0 after:h-2px after:absolute after:bg-secondaryColor hover:after:w-full after:bottom-0 after:left-0"
                    >
                      Explore AI Tools
                    </Link>
                  </li>
                </ul>
              </div>

              {/* Recent Post */}
              <div
                className="col-start-1 col-span-12 md:col-start-1 lg:col-start-10 md:col-span-6 lg:col-span-3 pl-0 2xl:pl-50px"
                data-aos="fade-up"
              >
                <h4 className="text-size-22 font-bold text-whiteColor mb-3">
                  Recent Blogs
                </h4>
                <ul className="flex flex-col gap-y-5">
                  {blogContent
                    ?.slice(-3)
                    ?.reverse()
                    ?.map((section, index) => (
                      <li>
                        <div className="flex items-center gap-3 group cursor-pointer">
                          <div>
                            <img
                              src={section?.image}
                              alt=""
                              className="w-61px h-54px object-cover"
                            />
                          </div>
                          <div>
                            <p className="text-xs text-darkgray mb-7px">
                              {section?.date}
                            </p>
                            <h6 className="text-size-15 text-whiteColor font-bold group-hover:text-secondaryColor transition-all duration-300">
                              <Link
                                to={`/blog-details?name=${section?.heading}`}
                              >
                                {section?.heading}
                              </Link>
                            </h6>
                          </div>
                        </div>
                      </li>
                    ))}

                  {/* Repeat similar structure for other posts */}
                </ul>
              </div>
            </div>
          </section>

          {/* footer copyright  */}
          <div>
            <div className="grid grid-cols-1 lg:grid-cols-12 gap-5 lg:gap-30px pt-10 items-center">
              <div className="lg:col-start-1 lg:col-span-3">
                <a href="/">
                  <img src={atlearn_logo} alt="" />
                </a>
              </div>
              <div className="lg:col-start-4 lg:col-span-6">
                <p className="text-whiteColor">
                  Copyright © 2024 by Atlearn. All Rights Reserved.
                </p>
              </div>
              <div className="lg:col-start-10 lg:col-span-3">
                <ul className="flex gap-3 lg:gap-2 2xl:gap-3 lg:justify-end">
                  <li>
                    <a
                      href="https://www.facebook.com/Atlearn.in"
                      className="w-40.19px lg:w-35px 2xl:w-40.19px h-37px lg:h-35px 2xl:h-37px leading-37px lg:leading-35px 2xl:leading-37px text-whiteColor bg-whiteColor bg-opacity-10 hover:bg-primaryColor text-center"
                    >
                      <i className="icofont-facebook" />
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="/"
                      className="w-40.19px lg:w-35px 2xl:w-40.19px h-37px lg:h-35px 2xl:h-37px leading-37px lg:leading-35px 2xl:leading-37px text-whiteColor bg-whiteColor bg-opacity-10 hover:bg-primaryColor text-center"
                    >
                      <i className="icofont-twitter" />
                    </a>
                  </li> */}

                  <li>
                    <a
                      href="https://www.facebook.com/Atlearn.in"
                      className="w-40.19px lg:w-35px 2xl:w-40.19px h-37px lg:h-35px 2xl:h-37px leading-37px lg:leading-35px 2xl:leading-37px text-whiteColor bg-whiteColor bg-opacity-10 hover:bg-primaryColor text-center"
                    >
                      <i className="icofont-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com"
                      className="w-40.19px lg:w-35px 2xl:w-40.19px h-37px lg:h-35px 2xl:h-37px leading-37px lg:leading-35px 2xl:leading-37px text-whiteColor bg-whiteColor bg-opacity-10 hover:bg-primaryColor text-center"
                    >
                      <i className="icofont-instagram" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/@Atlearn_In/featured"
                      className="w-40.19px lg:w-35px 2xl:w-40.19px h-37px lg:h-35px 2xl:h-37px leading-37px lg:leading-35px 2xl:leading-37px text-whiteColor bg-whiteColor bg-opacity-10 hover:bg-primaryColor text-center"
                    >
                      <i className="icofont-youtube-play" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default NewLayout;
