import React, { useEffect } from "react";

const Survey = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div className="mb-16">
      <div data-tf-live="01JBXMXJQYEZ5PW4EBZF05RCXD"></div>
    </div>
    //   <div className="flex justify-center items-center py-12">
    //   <div
    //     data-tf-live="01JBXMXJQYEZ5PW4EBZF05RCXD"
    //     className="w-full max-w-lg shadow-lg rounded-lg overflow-hidden p-6 bg-white"
    //   ></div>
    // </div>
  );
};

export default Survey;
