import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MainButton from "src/components/Button/MainButton/MainButton";
import GroupIcon from "@mui/icons-material/Group";
import CircleIcon from "@mui/icons-material/Circle";
import RoomVideoicon from "src/images/landingpages/videocallIcon.svg";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { convertToDate } from "src/utils/FormateDateUtils";
import apiClients from "src/apiClients/apiClients";

function OnlyJoin() {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [url, setUrl] = useState("");
  const [rooms, setRooms] = useState();
  const [loadingPage, setLoadigPage] = useState(false);
  const [filteredRooms, setFilteredRooms] = useState([]);
  useEffect(() => {
    if (user) {
      console.log(user);
      if (user?.permission?.["CreateRoom"] !== "false") {
        navigate("/room");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    setLoadigPage(true);
    try {
      const response = await apiClients.getRooms();
      if (response.data) {
        let responseData = response.data;
        // Sort the data in descending order
        responseData.sort((a, b) => {
          const dateA = convertToDate(a.last_session);
          const dateB = convertToDate(b.last_session);
          // Handle null dates (invalid date strings)
          if (dateA === null) return 1;
          if (dateB === null) return -1;

          return dateB - dateA;
        });

        setRooms(responseData);
        setFilteredRooms(responseData);
        setLoadigPage(false);
      } else {
        setRooms([]);
        setFilteredRooms([]);
        setLoadigPage(false);
      }
    } catch (error) {
      setLoadigPage(false);
      console.log(error);
    }
  };

  const handleJoin = () => {
    if (url) {
      // Use a regular expression to extract the desired part
      const match = url.match(/\/room(.*?)\/join/);

      if (match && match[1]) {
        const extractedString = match[1];
        if (extractedString) {
          navigate(`/room${extractedString}/join`);
        }
      } else {
        console.log("Pattern not found in the URL");
      }
    } else {
      toast.error("Enter Meeting Url");
    }
  };
  const handleCopy = (id) => {
    navigator.clipboard.writeText(`${window.location}/${id}/join`);
    toast.success(
      "The meeting URL has been copied. The link can be used to join the meeting."
    );
  };

  return (
    <div>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={9} lg={7}>
          <Box
            sx={{
              marginTop: rooms?.length ? "100px" : "200px",
              backgroundColor: "",
              width: "100%",
              // height: "70vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                marginTop: "20px",
                background: "rgb(255, 255, 255)",
                minHeight: "30vh",
                width: "100%",
                borderRadius: "12px",
                boxShadow:
                  "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              }}
            >
              <Box sx={{ p: 4 }}>
                <Typography variant="h3">Enter Meeting URL</Typography>
                <Typography>
                  Please enter the URL of your atlearn meeting in the field
                  below
                </Typography>
              </Box>

              <Divider />
              <Box>
                <Container></Container>
                <Container maxWidth="md">
                  <Grid
                    container
                    spacing={4}
                    justifyContent="center"
                    alignItems={"center"}
                  >
                    <Grid item xs={8}>
                      <Box mt={1}>
                        <TextField
                          placeholder="Enter meeting URL"
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          value={url}
                          onChange={(e) => setUrl(e.target.value)}
                        />
                      </Box>
                    </Grid>
                    <Grid mt={1} item xs={4}>
                      <MainButton onClick={handleJoin} style={{ width: "80%" }}>
                        Join
                      </MainButton>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Container maxWidth={"xl"} sx={{ marginTop: { xs: 3, sm: 10 } }}>
        {loadingPage ? null : rooms?.length ? ( // <LoaderHome />
          <>
            <Box sx={{ flexGrow: 1, mt: 2, mb: 2, height: "100%" }}>
              <h1 class="text-2xl font-bold text-center inline-block px-4 py-2 rounded-lg mb-2 border-b-4 border-primary">
                Rooms
              </h1>

              <Grid
                container
                spacing={2}
                sx={{ display: "flex", alignItems: "stretch" }}
              >
                {filteredRooms.map((item, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                    <Card
                      onClick={() => {
                        navigate(`/room/${item.friendly_id}`);
                      }}
                      sx={{
                        // minHeight: cardMinHeight,
                        height: "100%",
                        boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.5)",
                        transition: "transform 0.2s",
                        "&:hover": {
                          transform: "scale(1.02)",
                          border: "1px solid #E8063C",
                        },
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        cursor: "pointer",
                      }}
                    >
                      <CardContent>
                        <Stack direction="column">
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: "-10px",
                              mb: 1,
                            }}
                          >
                            {item?.online && (
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  // alignItems: "center",
                                  gap: 2,
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  <GroupIcon sx={{ fontSize: "1rem" }} />
                                  <span style={{ fontSize: "14px" }}>
                                    {item?.participants}
                                  </span>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  <CircleIcon
                                    sx={{
                                      color: "green",
                                      fontSize: "0.8rem",
                                    }}
                                  />
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      color: "green",
                                    }}
                                  >
                                    online
                                  </span>
                                </Box>
                              </Box>
                            )}
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <img src={RoomVideoicon} alt="Meeting Icon" />
                          </Box>

                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            mt={3}
                          >
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                            >
                              {item.name}
                            </Typography>
                          </Stack>
                          {item?.shared_owner && (
                            <Typography variant="body2" color="text.secondary">
                              shared by {item?.user?.name}
                            </Typography>
                          )}
                          {item?.last_session ? (
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              mt={2}
                              padding={1}
                            >
                              {item?.last_session}
                            </Typography>
                          ) : (
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              mt={2}
                              padding={1}
                            >
                              No previous session created
                            </Typography>
                          )}
                        </Stack>
                      </CardContent>
                      <CardActions>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            mt: 4,
                            width: "100%",
                          }}
                        >
                          <Tooltip
                            title={
                              item?.user
                                ? "You don't have permission to Delete"
                                : "Delete Room"
                            }
                          >
                            <Button
                              disabled={item?.user ? true : false}
                              // onClick={(e) => {
                              //   e.stopPropagation();
                              //   handleDeleteConirmationOpen(item?.id);
                              // }}
                              sx={{
                                padding: "10px 20px",
                              }}
                            >
                              <DeleteOutlineIcon
                                sx={{
                                  color: item?.user ? "gray" : "red",
                                }}
                              />
                            </Button>
                          </Tooltip>
                          <Tooltip title={"Copy Room"}>
                            <Button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleCopy(item.friendly_id);
                              }}
                              sx={{ padding: "10px 20px" }}
                            >
                              <ContentCopyIcon sx={{ color: "primary.main" }} />
                            </Button>
                          </Tooltip>
                        </Box>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </>
        ) : null}
      </Container>
    </div>
  );
}

export default OnlyJoin;
