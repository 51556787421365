import lms_integration_Icon from "src/assets/images/Integration/lms-integration.svg"
import articulate_360_icon from "src/assets/images/Integration/articulate-360.svg"
import scorm_file_uploads_icon from "src/assets/images/Integration/scorm-file-uploads.svg"
import Assignment_Integration from "src/assets/images/Integration/assignment_integration.webp"
import Test_Quiz_image from "src/assets/images/Integration/quiz_test_integration.webp"
import teacher_s_registration_image from "src/assets/images/Integration/teacher_s_registration.webp"
import course_management_image from "src/assets/images/Integration/course_management.webp"
import online_events_management_image from "src/assets/images/Integration/online_events_management.webp"
import community_tools_image from "src/assets/images/Integration/community_tools.webp"
import analytics_and_reporting_image from "src/assets/images/Integration/analytics_and_reporting.webp"
import personalized_learning_paths_icon from "src/assets/images/Integration/personalized-learning-paths.svg"
import streamlined_course_management_image from "src/assets/images/Integration/streamlined-course-management.svg"
import advanced_analytics_and_insights_image from "src/assets/images/Integration/advanced-analytics-and-insights.svg"
import learning_community_engagement_tools_image from "src/assets/images/Integration/learning-community-engagement-tools.svg"
import learning_competitive_advantage_icon from "src/assets/images/Integration/learning-competitive-advantage.svg"
import seamless_integration_with_external_tools_icon from "src/assets/images/Integration/seamless-integration-with-external-tools.svg"

export const CourseIntegration = {
  heading: "Course Integration & Creation",
  paragraph:
    "Seamlessly integrate with leading course creation platforms, enabling educators to design and deliver captivating e-learning content. Leverage AI-driven insights to personalize learning experiences, ensuring that each student receives tailored support throughout their educational journey.Easily integrate with top course creation platforms to design and deliver engaging e-learning content.",
};
export const CourseIntegrationfeatures = [
  {
    title: "LMS Integration",
    image:lms_integration_Icon,
    description:
      "A comprehensive solution for creating and delivering online courses, seamlessly integrated across other LMS systems like Teachable, Buddyboss, WizIQ, Braincert and Learncube.",
  },
  {
    title: "Articulate 360",
    image:articulate_360_icon,
    description:
      "A comprehensive platform for creating dynamic, interactive e-learning content, integrated directly into the LMS for a smooth experience",
  },
  {
    title: "SCORM File Uploads",
    image:scorm_file_uploads_icon,
    description:
      "Support for SCORM-compliant courses, ensuring interoperability with industry-standard courseware.",
  },
];

export const AssignmentIntegration = {
  imageSrc: Assignment_Integration,
  imageAlt: "About AI",
  // tagLabel: "About Us",
  headerText: "Assignment Integration",
  highlight: true,
  description: [
    `Streamline the creation, submission, and grading of assignments with robust integration into assignment management tools. Our AI capabilities can assist in analyzing student submissions, providing valuable feedback and reducing administrative burdens for educators.`,
  ],
};

export const test_Quiz_Integration = {
  imageSrc:Test_Quiz_image,
  imageAlt: "",
  // tagLabel: "About Us",
  headerText: "Test / Quiz Integration",
  description: [
    `Automate the entire test assessment process, from test creation to user permissions and translations. With our intelligent scheduling features, manage project timelines effectively for assessments and evaluations, ensuring timely and accurate results.`,
  ],
};

export const teachersRegistration = {
  imageSrc: teacher_s_registration_image,
  imageAlt: "",
  headerText: "Teacher's Registration",
  description: [
    `Automate the entire test assessment process, from test creation to user permissions and translations. With our intelligent scheduling features, manage project timelines effectively for assessments and evaluations, ensuring timely and accurate results.`,
  ],
  points: [
    "Teachers can conveniently display their available time slots, visible to all users without the need for a login",
    "Students can effortlessly book multiple time slots and make secure payments, ensuring a smooth scheduling process for live classes",
  ],
};

export const courseManagementSystem = {
  imageSrc: course_management_image,
  imageAlt: "",
  headerText: "Course Management System",
  description: [
    `Utilize a user-friendly drag-and-drop interface to create and manage courses, complete with support for video lessons, quizzes, and interactive elements. AI-driven analytics provide insights into course performance, helping instructors refine their content for better learner outcomes.`,
  ],
};

export const onlineEventManagement = {
  imageSrc: online_events_management_image,
  imageAlt: "",
  headerText: "Online Event Management",
  description: [
    `Easily schedule and host live events, webinars, and workshops with integrated streaming options and attendee management capabilities. Our platform's AI features can analyze attendee engagement in real-time, allowing for dynamic adjustments to enhance participation.`,
  ],
};
export const communityTools = {
  imageSrc: community_tools_image,
  imageAlt: "",
  headerText: "Community Tools",
  description: [
    `Foster collaboration and engagement through interactive discussion forums, chat features, and gamified learning elements. Our AI-enhanced tools promote meaningful interactions, creating a vibrant learning community that encourages peer support and knowledge sharing.`,
  ],
};

export const analyticsandReporting = {
  imageSrc: analytics_and_reporting_image,
  imageAlt: "",
  headerText: "Analytics and Reporting",
  description: [
    `Track and analyze essential performance metrics such as course completion rates and user engagement. Our detailed reporting tools provide valuable insights for course providers, enabling data-driven decisions to enhance educational offerings and improve student outcomes.`,
  ],
};

export const integrationBenefits = {
  heading: "Atlearn 3rd Party Integration Benefits",
  paragraph:
    "Integrating with Atlearn can offer numerous benefits, enhancing both functionality and user experience. Here are some key advantages.",
};

export const integrationBenefitsFeatures = [
  {
    title: "Personalized Learning Paths",
    image:personalized_learning_paths_icon,
    description:
      "Leverage Atlearn's AI capabilities to create customized learning experiences tailored to individual student needs, enhancing engagement and improving retention rates.",
  },
  {
    title: "Streamlined Course Management",
    image:streamlined_course_management_image,
    description:
      "Our user-friendly LMS simplifies course creation, assignment management, and testing processes, allowing educators to focus more on teaching rather than administrative tasks.",
  },
  {
    title: "Advanced Analytics and Insights",
    image:advanced_analytics_and_insights_image,
    description:
      "Gain access to comprehensive analytics powered by AI, providing valuable insights into student performance and engagement, enabling data-driven decisions for continuous improvement.",
  },
  {
    title: "Community Engagement Tools",
    image:learning_community_engagement_tools_image,
    description:
      " Foster collaboration through discussion forums and interactive features, promoting peer learning and engagement within the educational community.",
  },
  {
    title: "Competitive Advantage",
    image:learning_competitive_advantage_icon,
    description:
      "Integrating with our advanced digital platform for learning and AI features will give you a competitive edge, allowing you to offer unique value propositions that set you apart from other education providers.",
  },
  {
    title: "Seamless Integration with External Tools",
    image:seamless_integration_with_external_tools_icon,
    description:
      "Atlearn's platform easily integrates with third-party tools and services, allowing you to connect with other educational technologies, learning resources, and communication platforms to create a holistic learning environment.",
  },
];
