import React from 'react'
import TeachableLogo_img from "src/assets/images/LMS/Brand/Teachable logo.png";
import Buddyboss_logo_img from "src/assets/images/LMS/Brand/Buddyboss logo.png";
import WizIQ_logo_img from "src/assets/images/LMS/Brand/WizIQ  logo.png";
import Braincert_logo_img from "src/assets/images/LMS/Brand/Braincert logo.png";
import Learncube_logo_img from "src/assets/images/LMS/Brand/Learncube logo.png";

function BrandSection() {
  return (
    <div data-aos="fade-up">
    <div className="container2-md flex flex-wrap items-center justify-center bg-white dark:bg-whiteColor-dark rounded-md mx-auto md:-translate-y-1/2 w-full shadow-brand">
      <div className="basis-1/2 md:basis-1/4 lg:basis-1/5 flex justify-center py-5 lg:py-35px 2xl:py-45px">
        <a href="https://teachable.com/">
          <img src={TeachableLogo_img} alt="" />
        </a>
      </div>
      <div className="basis-1/2 md:basis-1/4 lg:basis-1/5 flex justify-center py-5 lg:py-35px 2xl:py-45px">
        <a href="https://www.buddyboss.com/integrations/">
          <img src={Buddyboss_logo_img} alt="" />
        </a>
      </div>
      <div className="basis-1/2 md:basis-1/4 lg:basis-1/5 flex justify-center py-5 lg:py-35px 2xl:py-45px">
        <a href="https://www.wiziq.com/integrations/all-virtual-classroom-plugins/">
          <img src={WizIQ_logo_img} alt="" />
        </a>
      </div>
      <div className="basis-1/2 md:basis-1/4 lg:basis-1/5 flex justify-center py-5 lg:py-35px 2xl:py-45px">
        <a href="https://www.braincert.com/compare/teachable-alternative">
          <img src={Braincert_logo_img} alt="" />
        </a>
      </div>
      <div className="basis-1/2 md:basis-1/4 lg:basis-1/5 flex justify-center py-5 lg:py-35px 2xl:py-45px">
        <a href="https://www.learncube.com/category/product-updates.html">
          <img src={Learncube_logo_img} alt="" />
        </a>
      </div>
    </div>
  </div>
  )
}

export default BrandSection
