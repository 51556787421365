import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { blogContent } from "src/Page_Content/blogData";

function BlogDetails() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get("name") || ""; // Ensure name is a string
  console.log(name, "nameeeeee");

  const { searchQuery } = useSelector((state) => state.searchBlog);

  const matchingSection = blogContent.find((section) => {
    const query = (searchQuery || name).toLowerCase();
    return query && section.heading.toLowerCase().includes(query);
  });
  console.log(matchingSection, "matchingSectionmatchingSection");

  return (
    <div>
      {matchingSection ? (
        <div data-aos="fade-up">
          {/* blog thumbnail */}
          <div className="overflow-hidden relative mb-30px">
            <img src={matchingSection?.image} alt="" className="w-full" />
          </div>
          {/* blog content */}
          <div>
            <h3 data-aos="fade-up" className="text-2xl md:text-size-32 lg:text-size-28 2xl:text-size-34 leading-34px md:leading-10 2xl:leading-13.5 font-bold text-blackColor2 hover:text-primaryColor dark:text-blackColor2-dark dark:hover:text-primaryColor">
              {matchingSection?.heading}
            </h3>
            {matchingSection.paragraphs?.map((paragraph, pIndex) => (
              <p
                key={pIndex}
                className="text-lg text-darkdeep4 mb-25px !leading-30px"
                data-aos="fade-up"
                dangerouslySetInnerHTML={{
                  __html: paragraph,
                }}
              />
            ))}
            {matchingSection.subSections?.map((subSection, subIndex) => (
              <div key={subIndex}>
                <h4
                  className="text-size-26 font-bold text-blackColor dark:text-blackColor-dark  !leading-30px"
                  data-aos="fade-up"
                >
                  {subSection.subHeading}
                </h4>

                {subSection.paragraphs?.map((subParagraph, spIndex) => (
                  <p
                    key={spIndex}
                    className="text-lg text-darkdeep4 mb-25px !leading-30px mt-4"
                    data-aos="fade-up"
                    dangerouslySetInnerHTML={{
                      __html: subParagraph,
                    }}
                  />
                ))}
                {subSection?.image && (
                    <div className="overflow-hidden relative mb-30px">
                    <img src={subSection?.image} alt="" className="w-full" />
                  </div>
                )}
                {subSection.features && (
                  <ul
                    data-aos="fade-up"
                    className="text-darkdeep4 mt-4"
                    style={{
                      listStyleType: "disc",
                      paddingLeft: "20px",
                      lineHeight: "1.8em",
                      marginBottom: "20px",
                    }}
                  >
                    {subSection.features.map((feature, featureIndex) => (
                      <li key={featureIndex}>
                        <strong>{feature.heading}:</strong>{" "}
                        {feature.description}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
        </div>
      ) :  <div className="flex items-center justify-center min-h-64 ">
      <h1 className="text-2xl font-bold text-gray-800 p-2">
      No blog posts available at this time. Please check back later!
      </h1>
    </div>}
    </div>
  );
}

export default BlogDetails;
