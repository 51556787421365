import React, { useEffect } from "react";
import Isotope from "isotope-layout";
import RegisterSection from "./RegisterSection";
import BrandSection from "src/components/New components/BrandSection";
//images
import AI_Enhanced_Learning_img from "src/assets/images/LMS/intro to AI learning.gif";
import personalized_learning_img from "src/assets/images/LMS/personalized learning - icon .svg";
import administrator_img from "src/assets/images/LMS/administrator.svg";
import tutor_icon from "src/assets/images/LMS/tutor icon.svg";
import LmsBannerImage from "src/assets/images/LMS/banner_image_lms_ai.webp";
import automatedIcon from "src/assets/images/LMS/automated-grading---feedback.svg";
import { Link } from "react-router-dom";
import SplitSection from "src/components/New components/SplitSection";
import {
  E_Learning_Solution,
  Elevate_Your_Learning,
  Unlock_Smart_Learning,
} from "src/Page_Content/LMS";
import { useState } from "react";
import Thinkific_img from "src/assets/images/LMS/Brand/Thinkific.jpg";
import Articulate_img from "src/assets/images/LMS/Brand/Articulate.png";
import LearnDash_img from "src/assets/images/LMS/Brand/LearnDash.png";
import docebo_seeklogo from "src/assets/images/LMS/Brand/docebo-seeklogo.svg";
import Moodle_img from "src/assets/images/LMS/Brand/Moodle.png";
import Braincert_logo from "src/assets/images/LMS/Brand/Braincert logo.png";
import GoHighLevel_image from "src/assets/images/LMS/Brand/GoHighLevel.png";

function LMS() {
  const [shuffledImages, setShuffledImages] = useState([]);
  useEffect(() => {
    const filter = () => {
      // Isotope setup
      const grid = document.querySelector(".filter-contents");
      if (grid) {
        const iso = new Isotope(grid, {
          itemSelector: ".grid-item",
          percentPosition: true,
          masonry: {
            columnWidth: ".grid-item",
          },
        });

        // Filter functions
        const filterFns = {
          numberGreaterThan50: function (itemElem) {
            const number = itemElem.querySelector(".number").textContent;
            return parseInt(number, 10) > 50;
          },
          ium: function (itemElem) {
            const name = itemElem.querySelector(".name").textContent;
            return name.match(/ium$/);
          },
        };

        // Bind filter button click
        const filtersElem = document.querySelector(".filters-button-group");
        filtersElem.addEventListener("click", (event) => {
          if (!event.target.matches("button")) return;

          let filterValue = event.target.getAttribute("data-filter");
          filterValue = filterFns[filterValue] || filterValue;
          iso.arrange({ filter: filterValue });
        });

        // Toggle "is-checked" class on buttons
        const buttonGroups = document.querySelectorAll(".button-group");
        buttonGroups.forEach((buttonGroup) => {
          buttonGroup.addEventListener("click", (event) => {
            if (!event.target.matches("button")) return;

            buttonGroup
              .querySelector(".is-checked")
              ?.classList.remove("is-checked");
            event.target.classList.add("is-checked");
          });
        });
      }
    };

    filter(); // Initialize the filter function on component mount
  }, []);

  // Array of logo URLs or placeholders
  const images = [
    Thinkific_img,
    Articulate_img,
    LearnDash_img,
    docebo_seeklogo,
    // articulate_seeklogo,
    // thinkific_seeklogo
    Moodle_img,
    Braincert_logo,
    GoHighLevel_image,
  ];

  useEffect(() => {
    setShuffledImages(images);
  }, []);

  return (
    <div>
      <section>
        {/* bannaer section */}
        <div className="container2-xl bg-darkdeep1 pt-50px  rounded-2xl relative overflow-hidden shadow-brand">
          <div className="container grid grid-cols-1 lg:grid-cols-2 items-center">
            {/* banner Left */}
            <div data-aos="fade-up">
              <h3 className="uppercase text-secondaryColor text-size-15 mb-5px md:mb-15px font-inter tracking-5px">
                AI Learning
              </h3>
              <h1 className="text-2xl text-whiteColor md:text-6xl lg:text-size-50 2xl:text-6xl leading-10 md:leading-18 lg:leading-62px 2xl:leading-18 md:tracking-half lg:tracking-normal 2xl:tracking-half font-bold mb-15px">
                {/* Cloud-based LMS <br className="hidden md:block" />
          Trusted by 1000+ */}
                Empowering Education with Smart Learning
              </h1>
              <p className="text-size-15md:text-lg text-white font-medium mb-45px">
                Atlearn empowers education by leveraging smart, AI-driven
                technologies to enhance the learning experience and foster
                student success
              </p>
              <div>
                <a
                  href="https://lms.atlearn.in/"
                  className=" mb-8 text-sm md:text-size-15 font-semibold text-darkdeep2 bg-whiteColor border border-whiteColor px-5 md:px-30px py-3 md:py-4 hover:text-whiteColor hover:bg-darkblack rounded inline-block mr-6px md:mr-30px shadow-hero-action dark:bg-whiteColor-dark dark:hover:bg-whiteColor dark:text-whiteColor dark:hover:text-whiteColor-dark dark:border-none"
                >
                  View Courses
                </a>
                <a
                  href="https://lms.atlearn.in/"
                  className="text-sm md:text-size-15 font-semibold text-whiteColor py-3 md:py-4 hover:text-secondaryColor inline-block"
                >
                  Create Courses <i className="icofont-long-arrow-right" />
                </a>
              </div>
            </div>
            {/* banner right */}
            <div data-aos="fade-up">
              <div className="tilt relative mb-8">
                <img src={LmsBannerImage} alt="" className="w-full " />
              </div>
            </div>
          </div>
          <div>
            <img
              className="absolute left-1/2 bottom-[15%] animate-spin-slow"
              src="./assets/images/register/register__2.png"
              alt=""
            />
            <img
              className="absolute left-[42%] sm:left-[65%] md:left-[42%] lg:left-[5%] top-[4%] sm:top-[1%] md:top-[4%] lg:top-[10%] animate-move-hor"
              src="./assets/images/herobanner/herobanner__6.png"
              alt=""
            />
            <img
              className="absolute right-[5%] bottom-[15%]"
              src="./assets/images/herobanner/herobanner__7.png"
              alt=""
            />
            <img
              className="absolute top-[5%] left-[45%]"
              src="./assets/images/herobanner/herobanner__7.png"
              alt=""
            />
          </div>
        </div>
      </section>

      {/*  brand section */}

      {/* <BrandSection /> */}

      {/* about and counter section */}
      <section>
        <div className="container">
          {/* about section  */}
          <div className="grid grid-cols-1 lg:grid-cols-2 pt-30px gap-x-30px">
            {/* about left */}
            <div
              className="relative z-0 mb-30px lg:mb-0 pb-0 md:pb-30px xl:pb-0 overflow-visible"
              data-aos="fade-up"
            >
              <div className="tilt">
                <img
                  // className="md:ml-[70px]"
                  src={AI_Enhanced_Learning_img}
                  alt=""
                />
                {/* <img
                    className="absolute right-0 sm:right-[-17px] md:right-36 lg:right-4 bottom-[91px] md:bottom-0"
                    src="./assets/images/about/about_3.png"
                    alt=""
                  />
                  <img
                    className="absolute top-[-18px] left-[30px] animate-move-hor z-[-1]"
                    src="./assets/images/about/about_4.png"
                    alt=""
                  />
                  <img
                    className="absolute top-[30%] left-0 z-[-1]"
                    src="./assets/images/about/about_11.png"
                    alt=""
                  /> */}
              </div>
              {/* experience */}
              {/* <div className="px-10px py-3 md:py-25px border-l-4 border-primaryColor shadow-experience absolute bottom-0 left-0 bg-white dark:bg-whiteColor-dark animate-move-var w-[290px]">
                  <div className="counter flex items-center">
                    <p className="text-[40px] text-primaryColor font-bold uppercase pr-10px leading-1">
                      <span data-countup-number={25}>25</span>+
                    </p>
                    <p className="text-blackColor dark:text-blackColor-dark font-bold leading-26px">
                      YEARS EXPERIENCE JUST ACHIVED
                    </p>
                  </div>
                </div> */}
            </div>
            {/* about right */}
            <div
              data-aos="fade-up"
              className="flex flex-col justify-center items-center"
            >
              <div>
                <span className="text-sm font-semibold text-primaryColor bg-whitegrey3 px-6 py-5px mb-5 rounded-full inline-block">
                  About Us
                </span>
                <h3 className="text-3xl md:text-size-45 leading-10 md:leading-2xl font-bold text-blackColor dark:text-blackColor-dark pb-25px">
                  Introduction to AI-Enhanced Learning
                  {/* <span className="relative after:w-full after:h-[7px] after:bg-secondaryColor after:absolute after:left-0 after:bottom-3 md:after:bottom-5">
              Online
            </span>
            Learning Center */}
                </h3>
                <p className="text-sm md:text-base leading-7 text-contentColor dark:text-contentColor-dark mb-6 pl-3 border-l-2 border-primaryColor">
                  Atlearn is revolutionizing education with a unified platform
                  that seamlessly integrates AI into our Learning Management
                  System (LMS) to provide personalized and engaging learning
                  experiences.
                </p>
                <ul className="space-y-5">
                  <li className="flex items-center group">
                    <i className="icofont-check px-2 py-2 text-primaryColor bg-whitegrey3 bg-opacity-40 group-hover:bg-primaryColor group-hover:text-white group-hover:opacity-100 mr-15px dark:bg-whitegrey1-dark" />
                    <p className="text-sm md:text-base font-medium text-blackColor dark:text-blackColor-dark">
                      We provide tools for course setup, student management, and
                      classroom creation to streamline the educational process.
                    </p>
                  </li>
                  <li className="flex items-center group">
                    <i className="icofont-check px-2 py-2 text-primaryColor bg-whitegrey3 bg-opacity-40 group-hover:bg-primaryColor group-hover:text-white group-hover:opacity-100 mr-15px dark:bg-whitegrey1-dark" />
                    <p className="text-sm md:text-base font-medium text-blackColor dark:text-blackColor-dark">
                      Our AI technology boosts engagement with smart
                      recommendations and insights, optimizing learning
                      outcomes.
                    </p>
                  </li>
                  <li className="flex items-center group">
                    <i className="icofont-check px-2 py-2 text-primaryColor bg-whitegrey3 bg-opacity-40 group-hover:bg-primaryColor group-hover:text-white group-hover:opacity-100 mr-15px dark:bg-whitegrey1-dark" />
                    <p className="text-sm md:text-base font-medium text-blackColor dark:text-blackColor-dark">
                      Dynamic features enhance collaboration, creating an
                      interactive and enriching environment for students and
                      educators.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* about counter */}
          <div className="counter flex flex-wrap py-[70px] lg:py-20 gap-y-30px lg:gap-y-0">
            <div
              className="basis-full sm:basis-1/2 lg:basis-1/4"
              data-aos="fade-up"
            >
              <div className="flex gap-4">
                <div>
                  <img src="./assets/images/counter/counter__1.png" alt="" />
                </div>
                <div>
                  <p className="text-size-34 leading-[1.1] text-blackColor font-bold font-hind dark:text-blackColor-dark">
                    <span data-countup-number={27}> 27</span>
                    <span>+</span>
                  </p>
                  <p className="uppercase text-blackColor font-medium leading-[18px] dark:text-blackColor-dark">
                    Total Acheivment
                  </p>
                </div>
              </div>
            </div>
            <div
              className="basis-full sm:basis-1/2 lg:basis-1/4"
              data-aos="fade-up"
            >
              <div className="flex gap-4">
                <div>
                  <img src="./assets/images/counter/counter__2.png" alt="" />
                </div>
                <div>
                  <p className="text-size-34 leading-[1.1] text-blackColor font-bold font-hind dark:text-blackColor-dark">
                    <span data-countup-number={145}>145</span>
                    <span>+</span>
                  </p>
                  <p className="uppercase text-blackColor font-medium leading-[18px] dark:text-blackColor-dark">
                    TOTAL STUDENTS
                  </p>
                </div>
              </div>
            </div>
            <div
              className="basis-full sm:basis-1/2 lg:basis-1/4"
              data-aos="fade-up"
            >
              <div className="flex gap-4">
                <div>
                  <img src="./assets/images/counter/counter__3.png" alt="" />
                </div>
                <div>
                  <p className="text-size-34 leading-[1.1] text-blackColor font-bold font-hind dark:text-blackColor-dark">
                    <span data-countup-number={10}>10</span>
                    <span>k</span>
                  </p>
                  <p className="uppercase text-blackColor font-medium leading-[18px] dark:text-blackColor-dark">
                    tOTAL INSTRUCTOR
                  </p>
                </div>
              </div>
            </div>
            <div
              className="basis-full sm:basis-1/2 lg:basis-1/4"
              data-aos="fade-up"
            >
              <div className="flex gap-4">
                <div>
                  <img src="./assets/images/counter/counter__4.png" alt="" />
                </div>
                <div>
                  <p className="text-size-34 leading-[1.1] text-blackColor font-bold font-hind dark:text-blackColor-dark">
                    <span data-countup-number={214}>214</span>
                    <span>+</span>
                  </p>
                  <p className="uppercase text-blackColor font-medium leading-[18px] dark:text-blackColor-dark">
                    OVER THE WORLD
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Popular Subject Sectoion*/}
      <section className="bg-lightGrey10 dark:bg-lightGrey10-dark pt-50px pb-110px">
        <div className="container">
          {/* about section  */}
          <div className="grid grid-cols-1 lg:grid-cols-2 pt-30px gap-x-30px">
            {/* sbject left */}
            <div className="relative z-0 overflow-visible">
              {/* animted area */}
              <div data-aos="fade-up">
                <img
                  className="absolute sm:block xl:left-1/4 z-[-1] top-6 animate-move-var"
                  src="./assets/images/service/service__shape__bg__1.png"
                  alt=""
                />
              </div>
              {/* subject card */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-30px">
                {/* card 1 */}
                <div data-aos="fade-up">
                  <div className="p-35px group bg-whiteColor rounded-xl transition-all duration-300 shadow-dropdown-secodary hover:bg-primaryColor hover:-translate-y-5px hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor">
                    {/* card svg */}
                    <div className="-translate-y-2 flex justify-between overflow-hidden mb-10px">
                      <div className="relative ">
                        <img
                          loading="lazy"
                          src={personalized_learning_img}
                          alt=""
                          className="w-15 h-15"
                        />
                      </div>
                      <div className="service__small__img w-7 h-[60px]">
                        <svg
                          className="icon__hover__img w-7 h-[60px] opacity-0 group-hover:opacity-100"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z"
                            stroke="#FFB31F"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    {/* card content */}
                    <div>
                      <a
                        href="https://lms.atlearn.in/"
                        className="text-2xl lg:text-lg 2xl:text-2xl font-semibold mb-15px md:mb-0 2xl:mb-15px hover:text-secondaryColor font-hind  dark:text-whiteColor dark:hover:text-secondaryColor dark:transition-all dark:duration-300"
                      >
                        Integrating With Popular Learning Management Systems
                        (LMS)
                      </a>
                      {/* <p className="text-sm lg:text-xs 2xl:text-sm text-contentColor group-hover:text-whiteColor mb-15px lg:mb-2 2xl:mb-15px transition-all duration-300">
                    Construction is a general term the art and science to form
                  </p> */}
                      <div>
                        <div className="text-sm text-black dark:text-blackColor-darkColor group-hover:text-whiteColor dark:text-blackColor-dark dark:group-hover:text-whiteColor-dark">
                          <a
                            href="https://lms.atlearn.in/"
                            className="text-sm font-medium hover:text-secondaryColor transition-none"
                          >
                            Start Learning
                            <i className="icofont-long-arrow-right" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* card 2 */}
                <div data-aos="fade-up">
                  <div className="md:translate-y-[30px]">
                    <div className="p-35px group bg-whiteColor rounded-xl transition-all duration-300 shadow-dropdown-secodary hover:bg-primaryColor hover:-translate-y-5px hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor ">
                      {/* card svg */}
                      <div className="-translate-y-2 flex justify-between overflow-hidden mb-10px">
                        <div className="relative ">
                          <img
                            loading="lazy"
                            src={automatedIcon}
                            alt=""
                            className="w-15 h-15"
                          />
                        </div>
                        <div className="service__small__img w-7 h-[60px]">
                          <svg
                            className="icon__hover__img w-7 h-[60px] opacity-0 group-hover:opacity-100"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z"
                              stroke="#FFB31F"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                      {/* card content */}
                      <div>
                        <a
                          href="https://lms.atlearn.in/"
                          className="text-2xl lg:text-lg 2xl:text-2xl font-semibold mb-15px md:mb-0 2xl:mb-15px hover:text-secondaryColor font-hind  dark:text-whiteColor dark:hover:text-secondaryColor dark:transition-all dark:duration-300"
                        >
                          Integrate With Popular Live Streaming Platforms
                        </a>
                        {/* <p className="text-sm lg:text-xs 2xl:text-sm text-contentColor group-hover:text-whiteColor mb-15px lg:mb-2 2xl:mb-15px transition-all duration-300">
                      Construction is a general term the art and science to form
                    </p> */}
                        <div>
                          <div className="text-sm text-black dark:text-blackColor-darkColor group-hover:text-whiteColor dark:text-blackColor-dark dark:group-hover:text-whiteColor-dark">
                            <a
                              href="https://lms.atlearn.in/"
                              className="text-sm font-medium hover:text-secondaryColor transition-all duration-300"
                            >
                              VAutomate Grading
                              <i className="icofont-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* card 3 */}
                <div data-aos="fade-up">
                  <div className="p-35px group bg-whiteColor rounded-xl transition-all duration-300 shadow-dropdown-secodary hover:bg-primaryColor hover:-translate-y-5px hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor">
                    {/* card svg */}
                    <div className="-translate-y-2 flex justify-between overflow-hidden mb-10px">
                      <div className="relative ">
                        <img
                          loading="lazy"
                          src={tutor_icon}
                          alt=""
                          className="w-15 h-15"
                        />
                      </div>
                      <div className="service__small__img w-7 h-[60px]">
                        <svg
                          className="icon__hover__img w-7 h-[60px] opacity-0 group-hover:opacity-100"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z"
                            stroke="#FFB31F"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    {/* card content */}
                    <div>
                      <a
                        href="https://lms.atlearn.in/"
                        className="text-2xl lg:text-lg 2xl:text-2xl font-semibold mb-15px md:mb-0 2xl:mb-15px hover:text-secondaryColor font-hind  dark:text-whiteColor dark:hover:text-secondaryColor dark:transition-all dark:duration-300"
                      >
                        Integrating With Assignment Tools
                      </a>
                      {/* <p className="text-sm lg:text-xs 2xl:text-sm text-contentColor group-hover:text-whiteColor mb-15px lg:mb-2 2xl:mb-15px transition-all duration-300">
                    Construction is a general term the art and science to form
                  </p> */}
                      <div>
                        <div className="text-sm text-black dark:text-blackColor-darkColor group-hover:text-whiteColor dark:text-blackColor-dark dark:group-hover:text-whiteColor-dark">
                          <a
                            href="https://lms.atlearn.in/"
                            className="text-sm font-medium hover:text-secondaryColor transition-none"
                          >
                            Begin Tutoring
                            <i className="icofont-long-arrow-right" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* card 4 */}
                <div data-aos="fade-up">
                  <div className="md:translate-y-[30px]">
                    <div className="p-35px group bg-whiteColor rounded-xl transition-all  duration-300 shadow-dropdown-secodary hover:bg-primaryColor hover:-translate-y-5px hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor ">
                      {/* card svg */}
                      <div className="-translate-y-2 flex justify-between overflow-hidden mb-10px">
                        <div className="relative ">
                          <img
                            loading="lazy"
                            src={administrator_img}
                            alt=""
                            className="w-15 h-15"
                          />
                        </div>
                        <div className="service__small__img w-7 h-[60px]">
                          <svg
                            className="icon__hover__img w-7 h-[60px] opacity-0 group-hover:opacity-100"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z"
                              stroke="#FFB31F"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                      {/* card content */}
                      <div>
                        <a
                          href="https://lms.atlearn.in/"
                          className="text-2xl lg:text-lg 2xl:text-2xl font-semibold mb-15px md:mb-0 2xl:mb-15px hover:text-secondaryColor font-hind  dark:text-whiteColor dark:hover:text-secondaryColor dark:transition-all dark:duration-300"
                        >
                          Integrating With Course Management Products
                        </a>
                        {/* <p className="text-sm lg:text-xs 2xl:text-sm text-contentColor group-hover:text-whiteColor mb-15px lg:mb-2 2xl:mb-15px transition-all duration-300">
                      Construction is a general term the art and science to form
                    </p> */}
                        <div>
                          <div className="text-sm text-black dark:text-blackColor-darkColor group-hover:text-whiteColor dark:text-blackColor-dark dark:group-hover:text-whiteColor-dark">
                            <a
                              href="https://lms.atlearn.in/"
                              className="text-sm font-medium hover:text-secondaryColor transition-all duration-300"
                            >
                              Get Started
                              <i className="icofont-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* about right */}
            <div
              className="mb-30px lg:mb-0 pb-0 md:pb-30px xl:pb-0"
              data-aos="fade-up"
            >
              <div className="relative">
                <div>
                  <img
                    className="absolute bottom-9 lg:bottom-[-50px] right-[50px] animate-move-hor"
                    src="./assets/images/service/service__shape__1.png"
                    alt=""
                  />
                </div>
                <span className="text-sm font-semibold text-primaryColor bg-whitegrey3 px-6 py-5px mb-5 rounded-full inline-block">
                  Integrations
                </span>
                <h3 className="text-3xl md:text-size-35 2xl:text-size-38 3xl:text-size-42 leading-10 md:leading-45px 2xl:leading-50px 3xl:leading-2xl font-bold text-blackColor dark:text-blackColor-dark pb-25px">
                  {/* We Offer Integration Aross Various LMS,SCORM-Compliant Courses, Management and 3rd Party E-learning Products */}
                  Comprehensive E-Learning Integration
                </h3>
                {/* <div className="flex justify-center">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {Array.from({ length: 7 }).map((_, index) => (
            <img
              key={index}
              src={`https://via.placeholder.com/150?text=Image+${index + 1}`}
              alt={""}
              className="w-full h-auto rounded-lg shadow-md"
            />
          ))}
        </div>
      </div> */}

                <div className="flex justify-center pb-25px">
                  <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {shuffledImages.map((src, index) => (
                      <img
                        key={index}
                        src={src}
                        alt={`Integration Logo ${index + 1}`}
                        className="w-full h-auto rounded-lg shadow-md"
                        // style={getRandomStyle()}
                      />
                    ))}
                  </div>
                </div>
                {/* <p className="text-sm md:text-base text-contentColor dark:text-contentColor-dark mb-10px 2xl:mb-50px">
              Construction is a general term meaning the art and science to form
              systems organizations, and comes from Latin Construction is
            </p> */}
                <p className="text-sm md:text-base leading-7 text-contentColor dark:text-contentColor-dark mb-10 pl-3 border-l-[3px] border-secondaryColor">
                  We provide seamless integration across popular LMS platforms,
                  SCORM-compliant courses, management systems, and a range of
                  third-party e-learning products to enhance your learning
                  ecosystem
                </p>
                <div>
                  <a
                    className="text-size-15 text-whiteColor bg-secondaryColor px-25px py-10px border border-secondaryColor hover:text-secondaryColor hover:bg-whiteColor inline-block rounded dark:hover:bg-whiteColor-dark dark:hover:text-whiteColor"
                    href="https://lms.atlearn.in/"
                  >
                    Explore More <i className="icofont-long-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* courses section */}
      <section>
        <div className="pt-50px pb-10 md:pt-70px md:pb-50px lg:pt-20 2xl:pt-100px 2xl:pb-70px bg-whiteColor dark:bg-whiteColor-dark">
          <div className="filter-container container">
            <div className="flex gap-15px lg:gap-30px flex-wrap lg:flex-nowrap items-center">
              {/* courses Left */}
              <div className="basis-full lg:basis-[500px]" data-aos="fade-up">
                <span className="text-sm font-semibold text-primaryColor bg-whitegrey3 px-6 py-5px mb-5 rounded-full inline-block">
                  Course List
                </span>
                <h3
                  className="text-3xl md:text-[35px] lg:text-size-42 leading-[45px] 2xl:leading-[45px] md:leading-[50px] font-bold text-blackColor dark:text-blackColor-dark"
                  data-aos="fade-up"
                >
                  Courses Available
                </h3>
              </div>
              {/* courses right */}
              <div className="basis-full lg:basis-[700px]">
                <ul
                  className="filter-controllers flex flex-wrap sm:flex-nowrap justify-start lg:justify-end button-group filters-button-group"
                  data-aos="fade-up"
                >
                  <li>
                    <button
                      data-filter="*"
                      className="is-checked dark:is-checked pr-5 md:pr-10 lg:pr-17px 2xl:pr-10 text-contentColor font-medium hover:text-primaryColor dark:text-contentColor-dark dark:hover:text-primaryColor"
                    >
                      See All
                    </button>
                  </li>
                  <li>
                    <button
                      data-filter=".filter1"
                      className="pr-5 md:pr-10 lg:pr-17px 2xl:pr-10 text-contentColor font-medium hover:text-primaryColor dark:text-contentColor-dark dark:hover:text-primaryColor"
                    >
                      Data science
                    </button>
                  </li>
                  <li>
                    <button
                      data-filter=".filter2"
                      className="pr-5 md:pr-10 lg:pr-17px 2xl:pr-10 text-contentColor font-medium hover:text-primaryColor dark:text-contentColor-dark dark:hover:text-primaryColor"
                    >
                      Engineering
                    </button>
                  </li>
                  <li>
                    <button
                      data-filter=".filter3"
                      className="pr-5 md:pr-10 lg:pr-17px 2xl:pr-10 text-contentColor font-medium hover:text-primaryColor dark:text-contentColor-dark dark:hover:text-primaryColor"
                    >
                      Featured
                    </button>
                  </li>
                  <li>
                    <button
                      data-filter=".filter4"
                      className="text-contentColor font-medium hover:text-primaryColor dark:text-contentColor-dark dark:hover:text-primaryColor"
                    >
                      Architecture
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            {/* course cards */}
            <div
              className="container p-0 filter-contents flex flex-wrap sm:-mx-15px mt-7 lg:mt-10"
              data-aos="fade-up"
            >
              {/* card 1 */}
              <div className="w-full sm:w-1/2 lg:w-1/3 group grid-item filter1 filter3">
                <div className="tab-content-wrapper sm:px-15px mb-30px">
                  <div className="p-15px bg-whiteColor shadow-brand dark:bg-darkdeep3-dark dark:shadow-brand-dark">
                    {/* card image */}
                    <div className="relative mb-4">
                      <a
                        href="course-details.html"
                        className="w-full overflow-hidden rounded"
                      >
                        <img
                          src="./assets/images/grid/grid_1.png"
                          alt=""
                          className="w-full transition-all duration-300 group-hover:scale-110"
                        />
                      </a>
                      <div className="absolute left-0 top-1 flex justify-between w-full items-center px-2">
                        <div>
                          <p className="text-xs text-whiteColor px-4 py-[3px] bg-secondaryColor rounded font-semibold">
                            Data &amp; Tech
                          </p>
                        </div>
                        <a
                          className="text-white bg-black bg-opacity-15 rounded hover:bg-primaryColor"
                          href="/"
                        >
                          <i className="icofont-heart-alt text-base py-1 px-2" />
                        </a>
                      </div>
                    </div>
                    {/* card content */}
                    <div>
                      <div className="grid grid-cols-2 mb-15px">
                        <div className="flex items-center">
                          <div>
                            <i className="icofont-book-alt pr-5px text-primaryColor text-lg" />
                          </div>
                          <div>
                            <span className="text-sm text-black dark:text-blackColor-dark">
                              23 Lesson
                            </span>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div>
                            <i className="icofont-clock-time pr-5px text-primaryColor text-lg" />
                          </div>
                          <div>
                            <span className="text-sm text-black dark:text-blackColor-dark">
                              1 hr 30 min
                            </span>
                          </div>
                        </div>
                      </div>
                      <Link
                        to={"/course-details"}
                        className="text-xl font-semibold text-blackColor mb-10px font-hind dark:text-blackColor-dark hover:text-secondaryColor dark:hover:text-primaryColor"
                      >
                        Foundation course to under stand about softwere
                      </Link>
                      {/* price */}
                      <div className="text-lg font-semibold text-primaryColor font-inter mb-4">
                        $32.00
                        <del className="text-sm text-lightGrey4 font-semibold">
                          / $67.00
                        </del>
                        <span className="ml-6">
                          <del className="text-base font-semibold text-secondaryColor3">
                            Free
                          </del>
                        </span>
                      </div>
                      {/* author and rating*/}
                      <div className="grid grid-cols-1 md:grid-cols-2 pt-15px border-t border-borderColor">
                        <div>
                          <a
                            href="instructor-details.html"
                            className="text-base font-bold font-hind flex items-center hover:text-primaryColor dark:text-blackColor-dark dark:hover:text-primaryColor"
                          >
                            <img
                              className="w-[30px] h-[30px] rounded-full mr-15px"
                              src="./assets/images/grid/grid_small_1.jpg"
                              alt=""
                            />
                            <span className="flex">Micle john</span>
                          </a>
                        </div>
                        <div className="text-start md:text-end">
                          <i className="icofont-star text-size-15 text-yellow" />
                          <i className="icofont-star text-size-15 text-yellow" />
                          <i className="icofont-star text-size-15 text-yellow" />
                          <i className="icofont-star text-size-15 text-yellow" />
                          <i className="icofont-star text-size-15 text-yellow" />
                          <span className="text-xs text-lightGrey6">(44)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* card 2 */}
              <div className="w-full sm:w-1/2 lg:w-1/3 group grid-item filter2 filter3">
                <div className="tab-content-wrapper sm:px-15px mb-30px">
                  <div className="p-15px bg-whiteColor shadow-brand dark:bg-darkdeep3-dark dark:shadow-brand-dark">
                    {/* card image */}
                    <div className="relative mb-4">
                      <a
                        href="course-details.html"
                        className="w-full overflow-hidden rounded"
                      >
                        <img
                          src="./assets/images/grid/grid_2.png"
                          alt=""
                          className="w-full transition-all duration-300 group-hover:scale-110"
                        />
                      </a>
                      <div className="absolute left-0 top-1 flex justify-between w-full items-center px-2">
                        <div>
                          <p className="text-xs text-whiteColor px-4 py-[3px] bg-blue rounded font-semibold">
                            Mechanical
                          </p>
                        </div>
                        <a
                          className="text-white bg-black bg-opacity-15 rounded hover:bg-primaryColor"
                          href="/"
                        >
                          <i className="icofont-heart-alt text-base py-1 px-2" />
                        </a>
                      </div>
                    </div>
                    {/* card content */}
                    <div>
                      <div className="grid grid-cols-2 mb-15px">
                        <div className="flex items-center">
                          <div>
                            <i className="icofont-book-alt pr-5px text-primaryColor text-lg" />
                          </div>
                          <div>
                            <span className="text-sm text-black dark:text-blackColor-dark">
                              29 Lesson
                            </span>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div>
                            <i className="icofont-clock-time pr-5px text-primaryColor text-lg" />
                          </div>
                          <div>
                            <span className="text-sm text-black dark:text-blackColor-dark">
                              2 hr 10 min
                            </span>
                          </div>
                        </div>
                      </div>
                      <Link
                        to={"/course-details"}
                        className="text-xl font-semibold text-blackColor mb-10px font-hind dark:text-blackColor-dark hover:text-secondaryColor dark:hover:text-primaryColor"
                      >
                        Nidnies course to under stand about softwere
                      </Link>
                      {/* price */}
                      <div className="text-lg font-semibold text-primaryColor font-inter mb-4">
                        $32.00
                        <del className="text-sm text-lightGrey4 font-semibold">
                          / $67.00
                        </del>
                        <span className="ml-6">
                          <del className="text-base font-semibold text-greencolor">
                            Free
                          </del>
                        </span>
                      </div>
                      {/* author and rating*/}
                      <div className="grid grid-cols-1 md:grid-cols-2 pt-15px border-t border-borderColor">
                        <div>
                          <a
                            href="instructor-details.html"
                            className="text-base font-bold font-hind flex items-center hover:text-primaryColor dark:text-blackColor-dark dark:hover:text-primaryColor"
                          >
                            <img
                              className="w-[30px] h-[30px] rounded-full mr-15px"
                              src="./assets/images/grid/grid_small_2.jpg"
                              alt=""
                            />
                            Rinis Jhon
                          </a>
                        </div>
                        <div className="text-start md:text-end">
                          <i className="icofont-star text-size-15 text-yellow" />
                          <i className="icofont-star text-size-15 text-yellow" />
                          <i className="icofont-star text-size-15 text-yellow" />
                          <i className="icofont-star text-size-15 text-yellow" />
                          <i className="icofont-star text-size-15 text-yellow" />
                          <span className="text-xs text-lightGrey6">(44)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* card 3 */}
              <div className="w-full sm:w-1/2 lg:w-1/3 group grid-item filter4 filter5">
                <div className="tab-content-wrapper sm:px-15px mb-30px">
                  <div className="p-15px bg-whiteColor shadow-brand dark:bg-darkdeep3-dark dark:shadow-brand-dark">
                    {/* card image */}
                    <div className="relative mb-4">
                      <a
                        href="course-details.html"
                        className="w-full overflow-hidden rounded"
                      >
                        <img
                          src="./assets/images/grid/grid_3.png"
                          alt=""
                          className="w-full transition-all duration-300 group-hover:scale-110"
                        />
                      </a>
                      <div className="absolute left-0 top-1 flex justify-between w-full items-center px-2">
                        <div>
                          <p className="text-xs text-whiteColor px-4 py-[3px] bg-secondaryColor2 rounded font-semibold">
                            Development
                          </p>
                        </div>
                        <a
                          className="text-white bg-black bg-opacity-15 rounded hover:bg-primaryColor"
                          href="/"
                        >
                          <i className="icofont-heart-alt text-base py-1 px-2" />
                        </a>
                      </div>
                    </div>
                    {/* card content */}
                    <div>
                      <div className="grid grid-cols-2 mb-15px">
                        <div className="flex items-center">
                          <div>
                            <i className="icofont-book-alt pr-5px text-primaryColor text-lg" />
                          </div>
                          <div>
                            <span className="text-sm text-black dark:text-blackColor-dark">
                              25 Lesson
                            </span>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div>
                            <i className="icofont-clock-time pr-5px text-primaryColor text-lg" />
                          </div>
                          <div>
                            <span className="text-sm text-black dark:text-blackColor-dark">
                              1 hr 40 min
                            </span>
                          </div>
                        </div>
                      </div>
                      <Link
                        to={"/course-details"}
                        className="text-xl font-semibold text-blackColor mb-10px font-hind dark:text-blackColor-dark hover:text-secondaryColor dark:hover:text-primaryColor"
                      >
                        Minws course to under stand about solution
                      </Link>
                      {/* price */}
                      <div className="text-lg font-semibold text-primaryColor font-inter mb-4">
                        $40.00
                        <del className="text-sm text-lightGrey4 font-semibold">
                          / $67.00
                        </del>
                        <span className="ml-6">
                          <del className="text-base font-semibold text-secondaryColor3">
                            Free
                          </del>
                        </span>
                      </div>
                      {/* author and rating*/}
                      <div className="grid grid-cols-1 md:grid-cols-2 pt-15px border-t border-borderColor">
                        <div>
                          <a
                            href="instructor-details.html"
                            className="text-base font-bold font-hind flex items-center hover:text-primaryColor dark:text-blackColor-dark dark:hover:text-primaryColor"
                          >
                            <img
                              className="w-[30px] h-[30px] rounded-full mr-15px"
                              src="./assets/images/grid/grid_small_3.jpg"
                              alt=""
                            />
                            Micle John
                          </a>
                        </div>
                        <div className="text-start md:text-end">
                          <i className="icofont-star text-size-15 text-yellow" />
                          <i className="icofont-star text-size-15 text-yellow" />
                          <i className="icofont-star text-size-15 text-yellow" />
                          <i className="icofont-star text-size-15 text-yellow" />
                          <i className="icofont-star text-size-15 text-yellow" />
                          <span className="text-xs text-lightGrey6">(44)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* card 4 */}
              <div className="w-full sm:w-1/2 lg:w-1/3 group grid-item filter4">
                <div className="tab-content-wrapper sm:px-15px mb-30px">
                  <div className="p-15px bg-whiteColor shadow-brand dark:bg-darkdeep3-dark dark:shadow-brand-dark">
                    {/* card image */}
                    <div className="relative mb-4">
                      <a
                        href="course-details.html"
                        className="w-full overflow-hidden rounded"
                      >
                        <img
                          src="./assets/images/grid/grid_4.png"
                          alt=""
                          className="w-full transition-all duration-300 group-hover:scale-110"
                        />
                      </a>
                      <div className="absolute left-0 top-1 flex justify-between w-full items-center px-2">
                        <div>
                          <p className="text-xs text-whiteColor px-4 py-[3px] bg-greencolor2 rounded font-semibold">
                            Ui &amp; UX Design
                          </p>
                        </div>
                        <a
                          className="text-white bg-black bg-opacity-15 rounded hover:bg-primaryColor"
                          href="/"
                        >
                          <i className="icofont-heart-alt text-base py-1 px-2" />
                        </a>
                      </div>
                    </div>
                    {/* card content */}
                    <div>
                      <div className="grid grid-cols-2 mb-15px">
                        <div className="flex items-center">
                          <div>
                            <i className="icofont-book-alt pr-5px text-primaryColor text-lg" />
                          </div>
                          <div>
                            <span className="text-sm text-black dark:text-blackColor-dark">
                              36 Lesson
                            </span>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div>
                            <i className="icofont-clock-time pr-5px text-primaryColor text-lg" />
                          </div>
                          <div>
                            <span className="text-sm text-black dark:text-blackColor-dark">
                              3 hr 40 min
                            </span>
                          </div>
                        </div>
                      </div>
                      <Link
                        to={"/course-details"}
                        className="text-xl font-semibold text-blackColor mb-10px font-hind dark:text-blackColor-dark hover:text-secondaryColor dark:hover:text-primaryColor"
                      >
                        Design course to under stand about solution
                      </Link>
                      {/* price */}
                      <div className="text-lg font-semibold text-primaryColor font-inter mb-4">
                        $40.00
                        <del className="text-sm text-lightGrey4 font-semibold">
                          / $67.00
                        </del>
                        <span className="ml-6">
                          <del className="text-base font-semibold text-secondaryColor3">
                            Free
                          </del>
                        </span>
                      </div>
                      {/* author and rating*/}
                      <div className="grid grid-cols-1 md:grid-cols-2 pt-15px border-t border-borderColor">
                        <div>
                          <a
                            href="instructor-details.html"
                            className="text-base font-bold font-hind flex items-center hover:text-primaryColor dark:text-blackColor-dark dark:hover:text-primaryColor"
                          >
                            <img
                              className="w-[30px] h-[30px] rounded-full mr-15px"
                              src="./assets/images/grid/grid_small_4.jpg"
                              alt=""
                            />
                            <span className="flex flex-shrink-0">
                              Micle Robin
                            </span>
                          </a>
                        </div>
                        <div className="text-start md:text-end">
                          <i className="icofont-star text-size-15 text-yellow" />
                          <i className="icofont-star text-size-15 text-yellow" />
                          <i className="icofont-star text-size-15 text-yellow" />
                          <i className="icofont-star text-size-15 text-yellow" />
                          <i className="icofont-star text-size-15 text-yellow" />
                          <span className="text-xs text-lightGrey6">(44)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* card 5 */}
              <div className="w-full sm:w-1/2 lg:w-1/3 group group grid-item filter1 filter3">
                <div className="tab-content-wrapper sm:px-15px mb-30px">
                  <div className="p-15px bg-whiteColor shadow-brand dark:bg-darkdeep3-dark dark:shadow-brand-dark">
                    {/* card image */}
                    <div className="relative mb-4">
                      <a
                        href="course-details.html"
                        className="w-full overflow-hidden rounded"
                      >
                        <img
                          src="./assets/images/grid/grid_5.png"
                          alt=""
                          className="w-full transition-all duration-300 group-hover:scale-110"
                        />
                      </a>
                      <div className="absolute left-0 top-1 flex justify-between w-full items-center px-2">
                        <div>
                          <p className="text-xs text-whiteColor px-4 py-[3px] bg-orange rounded font-semibold">
                            Data &amp; Tech
                          </p>
                        </div>
                        <a
                          className="text-white bg-black bg-opacity-15 rounded hover:bg-primaryColor"
                          href="/"
                        >
                          <i className="icofont-heart-alt text-base py-1 px-2" />
                        </a>
                      </div>
                    </div>
                    {/* card content */}
                    <div>
                      <div className="grid grid-cols-2 mb-15px">
                        <div className="flex items-center">
                          <div>
                            <i className="icofont-book-alt pr-5px text-primaryColor text-lg" />
                          </div>
                          <div>
                            <span className="text-sm text-black dark:text-blackColor-dark">
                              36 Lesson
                            </span>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div>
                            <i className="icofont-clock-time pr-5px text-primaryColor text-lg" />
                          </div>
                          <div>
                            <span className="text-sm text-black dark:text-blackColor-dark">
                              3 hr 40 min
                            </span>
                          </div>
                        </div>
                      </div>
                      <Link
                        to={"/course-details"}
                        className="text-xl font-semibold text-blackColor mb-10px font-hind dark:text-blackColor-dark hover:text-secondaryColor dark:hover:text-primaryColor"
                      >
                        Data course to under stand about solution
                      </Link>
                      {/* price */}
                      <div className="text-lg font-semibold text-primaryColor font-inter mb-4">
                        $40.00
                        <del className="text-sm text-lightGrey4 font-semibold">
                          / $67.00
                        </del>
                        <span className="ml-6">
                          <del className="text-base font-semibold text-secondaryColor3">
                            Free
                          </del>
                        </span>
                      </div>
                      {/* author and rating*/}
                      <div className="grid grid-cols-1 md:grid-cols-2 pt-15px border-t border-borderColor">
                        <div>
                          <a
                            href="instructor-details.html"
                            className="text-base font-bold font-hind flex items-center hover:text-primaryColor dark:text-blackColor-dark dark:hover:text-primaryColor"
                          >
                            <img
                              className="w-[30px] h-[30px] rounded-full mr-15px"
                              src="./assets/images/grid/grid_small_1.jpg"
                              alt=""
                            />
                            <span className="flex flex-shrink-0">
                              Micle Robin
                            </span>
                          </a>
                        </div>
                        <div className="text-start md:text-end">
                          <i className="icofont-star text-size-15 text-yellow" />
                          <i className="icofont-star text-size-15 text-yellow" />
                          <i className="icofont-star text-size-15 text-yellow" />
                          <i className="icofont-star text-size-15 text-yellow" />
                          <i className="icofont-star text-size-15 text-yellow" />
                          <span className="text-xs text-lightGrey6">(44)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* card 6 */}
              <div className="w-full sm:w-1/2 lg:w-1/3 group grid-item filter2 filter5">
                <div className="tab-content-wrapper sm:px-15px mb-30px">
                  <div className="p-15px bg-whiteColor shadow-brand dark:bg-darkdeep3-dark dark:shadow-brand-dark">
                    {/* card image */}
                    <div className="relative mb-4">
                      <a
                        href="course-details.html"
                        className="w-full overflow-hidden rounded"
                      >
                        <img
                          src="./assets/images/grid/grid_6.png"
                          alt=""
                          className="w-full transition-all duration-300 group-hover:scale-110"
                        />
                      </a>
                      <div className="absolute left-0 top-1 flex justify-between w-full items-center px-2">
                        <div>
                          <p className="text-xs text-whiteColor px-4 py-[3px] bg-yellow rounded font-semibold">
                            Big Data
                          </p>
                        </div>
                        <a
                          className="text-white bg-black bg-opacity-15 rounded hover:bg-primaryColor"
                          href="/"
                        >
                          <i className="icofont-heart-alt text-base py-1 px-2" />
                        </a>
                      </div>
                    </div>
                    {/* card content */}
                    <div>
                      <div className="grid grid-cols-2 mb-15px">
                        <div className="flex items-center">
                          <div>
                            <i className="icofont-book-alt pr-5px text-primaryColor text-lg" />
                          </div>
                          <div>
                            <span className="text-sm text-black dark:text-blackColor-dark">
                              30 Lesson
                            </span>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div>
                            <i className="icofont-clock-time pr-5px text-primaryColor text-lg" />
                          </div>
                          <div>
                            <span className="text-sm text-black dark:text-blackColor-dark">
                              3 hr 40 min
                            </span>
                          </div>
                        </div>
                      </div>
                      <Link
                        to={"/course-details"}
                        className="text-xl font-semibold text-blackColor mb-10px font-hind dark:text-blackColor-dark hover:text-secondaryColor dark:hover:text-primaryColor"
                      >
                        Big data to under stand about solution pacage
                      </Link>
                      {/* price */}
                      <div className="text-lg font-semibold text-primaryColor font-inter mb-4">
                        $40.00
                        <del className="text-sm text-lightGrey4 font-semibold">
                          / $67.00
                        </del>
                        <span className="ml-6">
                          <del className="text-base font-semibold text-secondaryColor3">
                            Free
                          </del>
                        </span>
                      </div>
                      {/* author and rating*/}
                      <div className="grid grid-cols-1 md:grid-cols-2 pt-15px border-t border-borderColor">
                        <div>
                          <a
                            href="instructor-details.html"
                            className="text-base font-bold font-hind flex items-center hover:text-primaryColor dark:text-blackColor-dark dark:hover:text-primaryColor"
                          >
                            <img
                              className="w-[30px] h-[30px] rounded-full mr-15px"
                              src="./assets/images/grid/grid_small_1.jpg"
                              alt=""
                            />
                            <span className="flex flex-shrink-0">
                              Micle Robin
                            </span>
                          </a>
                        </div>
                        <div className="text-start md:text-end">
                          <i className="icofont-star text-size-15 text-yellow" />
                          <i className="icofont-star text-size-15 text-yellow" />
                          <i className="icofont-star text-size-15 text-yellow" />
                          <i className="icofont-star text-size-15 text-yellow" />
                          <i className="icofont-star text-size-15 text-yellow" />
                          <span className="text-xs text-lightGrey6">(44)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Popular Subject Sectoion*/}
      <section className="bg-lightGrey10 dark:bg-lightGrey10-dark pt-50px pb-110px">
        <div className="container">
          {/* about section  */}
          <div className="grid grid-cols-1 lg:grid-cols-2 pt-30px gap-x-30px">
            {/* about left */}
            <div
              className="mb-30px lg:mb-0 pb-0 md:pb-30px xl:pb-0"
              data-aos="fade-up"
            >
              <div className="relative">
                <div>
                  <img
                    className="absolute bottom-9 lg:bottom-[-50px] right-[50px] animate-move-hor"
                    src="./assets/images/service/service__shape__1.png"
                    alt=""
                  />
                </div>
                <span className="text-sm font-semibold text-primaryColor bg-whitegrey3 px-6 py-5px mb-5 rounded-full inline-block">
                  LMS AI Tools
                </span>
                <h3 className="text-3xl md:text-size-35 2xl:text-size-38 3xl:text-size-42 leading-10 md:leading-45px 2xl:leading-50px 3xl:leading-2xl font-bold text-blackColor dark:text-blackColor-dark pb-25px">
                  AI-Powered LMS Tools for Enhanced Learning
                </h3>
                {/* <p className="text-sm md:text-base text-contentColor dark:text-contentColor-dark mb-10px 2xl:mb-50px">
              Construction is a general term meaning the art and science to form
              systems organizations, and comes from Latin Construction is
            </p> */}
                <p className="text-sm md:text-base leading-7 text-contentColor dark:text-contentColor-dark mb-10 pl-3 border-l-[3px] border-secondaryColor">
                  Enhance student engagement and critical thinking skills with
                  Atlearn LMS communication features, fostering interactive
                  learning and retention on one of the best online course
                  platforms.
                </p>
                <div>
                  <a
                    className="text-size-15 text-whiteColor bg-secondaryColor px-25px py-10px border border-secondaryColor hover:text-secondaryColor hover:bg-whiteColor inline-block rounded dark:hover:bg-whiteColor-dark dark:hover:text-whiteColor"
                    href="https://lms.atlearn.in/"
                  >
                    Explore More <i className="icofont-long-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
            {/* sbject right */}
            <div className="relative z-0 overflow-visible">
              {/* animted area */}
              <div data-aos="fade-up">
                <img
                  className="absolute sm:block xl:left-1/4 z-[-1] top-6 animate-move-var"
                  src="./assets/images/service/service__shape__bg__1.png"
                  alt=""
                />
              </div>
              {/* subject card */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-30px">
                {/* card 1 */}
                <div data-aos="fade-up">
                  <div className="p-35px group bg-whiteColor rounded-xl transition-all duration-300 shadow-dropdown-secodary hover:bg-primaryColor hover:-translate-y-5px hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor">
                    {/* card svg */}
                    <div className="-translate-y-2 flex justify-between overflow-hidden mb-10px">
                      <div className="relative ">
                        <img
                          loading="lazy"
                          src={personalized_learning_img}
                          alt=""
                          className="w-15 h-15"
                        />
                      </div>
                      <div className="service__small__img w-7 h-[60px]">
                        <svg
                          className="icon__hover__img w-7 h-[60px] opacity-0 group-hover:opacity-100"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z"
                            stroke="#FFB31F"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    {/* card content */}
                    <div>
                      <a
                        href="https://lms.atlearn.in/"
                        className="text-2xl lg:text-lg 2xl:text-2xl font-semibold mb-15px md:mb-0 2xl:mb-15px hover:text-secondaryColor font-hind  dark:text-whiteColor dark:hover:text-secondaryColor dark:transition-all dark:duration-300"
                      >
                        Personalized Learning Paths
                      </a>
                      {/* <p className="text-sm lg:text-xs 2xl:text-sm text-contentColor group-hover:text-whiteColor mb-15px lg:mb-2 2xl:mb-15px transition-all duration-300">
                    Construction is a general term the art and science to form
                  </p> */}
                      <div>
                        <div className="text-sm text-black dark:text-blackColor-darkColor group-hover:text-whiteColor dark:text-blackColor-dark dark:group-hover:text-whiteColor-dark">
                          <a
                            href="https://lms.atlearn.in/"
                            className="text-sm font-medium hover:text-secondaryColor transition-none"
                          >
                            Start Learning
                            <i className="icofont-long-arrow-right" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* card 2 */}
                <div data-aos="fade-up">
                  <div className="md:translate-y-[30px]">
                    <div className="p-35px group bg-whiteColor rounded-xl transition-all duration-300 shadow-dropdown-secodary hover:bg-primaryColor hover:-translate-y-5px hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor ">
                      {/* card svg */}
                      <div className="-translate-y-2 flex justify-between overflow-hidden mb-10px">
                        <div className="relative ">
                          <img
                            loading="lazy"
                            src={automatedIcon}
                            alt=""
                            className="w-15 h-15"
                          />
                        </div>
                        <div className="service__small__img w-7 h-[60px]">
                          <svg
                            className="icon__hover__img w-7 h-[60px] opacity-0 group-hover:opacity-100"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z"
                              stroke="#FFB31F"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                      {/* card content */}
                      <div>
                        <a
                          href="https://lms.atlearn.in/"
                          className="text-2xl lg:text-lg 2xl:text-2xl font-semibold mb-15px md:mb-0 2xl:mb-15px hover:text-secondaryColor font-hind  dark:text-whiteColor dark:hover:text-secondaryColor dark:transition-all dark:duration-300"
                        >
                          Automated Grading and Feedback
                        </a>
                        {/* <p className="text-sm lg:text-xs 2xl:text-sm text-contentColor group-hover:text-whiteColor mb-15px lg:mb-2 2xl:mb-15px transition-all duration-300">
                      Construction is a general term the art and science to form
                    </p> */}
                        <div>
                          <div className="text-sm text-black dark:text-blackColor-darkColor group-hover:text-whiteColor dark:text-blackColor-dark dark:group-hover:text-whiteColor-dark">
                            <a
                              href="https://lms.atlearn.in/"
                              className="text-sm font-medium hover:text-secondaryColor transition-all duration-300"
                            >
                              VAutomate Grading
                              <i className="icofont-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* card 3 */}
                <div data-aos="fade-up">
                  <div className="p-35px group bg-whiteColor rounded-xl transition-all duration-300 shadow-dropdown-secodary hover:bg-primaryColor hover:-translate-y-5px hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor">
                    {/* card svg */}
                    <div className="-translate-y-2 flex justify-between overflow-hidden mb-10px">
                      <div className="relative ">
                        <img
                          loading="lazy"
                          src={tutor_icon}
                          alt=""
                          className="w-15 h-15"
                        />
                      </div>
                      <div className="service__small__img w-7 h-[60px]">
                        <svg
                          className="icon__hover__img w-7 h-[60px] opacity-0 group-hover:opacity-100"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z"
                            stroke="#FFB31F"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    {/* card content */}
                    <div>
                      <a
                        href="https://lms.atlearn.in/"
                        className="text-2xl lg:text-lg 2xl:text-2xl font-semibold mb-15px md:mb-0 2xl:mb-15px hover:text-secondaryColor font-hind  dark:text-whiteColor dark:hover:text-secondaryColor dark:transition-all dark:duration-300"
                      >
                        Intelligent Tutor Systems
                      </a>
                      {/* <p className="text-sm lg:text-xs 2xl:text-sm text-contentColor group-hover:text-whiteColor mb-15px lg:mb-2 2xl:mb-15px transition-all duration-300">
                    Construction is a general term the art and science to form
                  </p> */}
                      <div>
                        <div className="text-sm text-black dark:text-blackColor-darkColor group-hover:text-whiteColor dark:text-blackColor-dark dark:group-hover:text-whiteColor-dark">
                          <a
                            href="https://lms.atlearn.in/"
                            className="text-sm font-medium hover:text-secondaryColor transition-none"
                          >
                            Begin Tutoring
                            <i className="icofont-long-arrow-right" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* card 4 */}
                <div data-aos="fade-up">
                  <div className="md:translate-y-[30px]">
                    <div className="p-35px group bg-whiteColor rounded-xl transition-all  duration-300 shadow-dropdown-secodary hover:bg-primaryColor hover:-translate-y-5px hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor ">
                      {/* card svg */}
                      <div className="-translate-y-2 flex justify-between overflow-hidden mb-10px">
                        <div className="relative ">
                          <img
                            loading="lazy"
                            src={administrator_img}
                            alt=""
                            className="w-15 h-15"
                          />
                        </div>
                        <div className="service__small__img w-7 h-[60px]">
                          <svg
                            className="icon__hover__img w-7 h-[60px] opacity-0 group-hover:opacity-100"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z"
                              stroke="#FFB31F"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                      {/* card content */}
                      <div>
                        <a
                          href="https://lms.atlearn.in/"
                          className="text-2xl lg:text-lg 2xl:text-2xl font-semibold mb-15px md:mb-0 2xl:mb-15px hover:text-secondaryColor font-hind  dark:text-whiteColor dark:hover:text-secondaryColor dark:transition-all dark:duration-300"
                        >
                          Efficient Administrative Tools
                        </a>
                        {/* <p className="text-sm lg:text-xs 2xl:text-sm text-contentColor group-hover:text-whiteColor mb-15px lg:mb-2 2xl:mb-15px transition-all duration-300">
                      Construction is a general term the art and science to form
                    </p> */}
                        <div>
                          <div className="text-sm text-black dark:text-blackColor-darkColor group-hover:text-whiteColor dark:text-blackColor-dark dark:group-hover:text-whiteColor-dark">
                            <a
                              href="https://lms.atlearn.in/"
                              className="text-sm font-medium hover:text-secondaryColor transition-all duration-300"
                            >
                              Get Started
                              <i className="icofont-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="mt-4">
        <SplitSection data={E_Learning_Solution} />
      </div>
      <div>
        <SplitSection data={Unlock_Smart_Learning} />
      </div>
      <div className="mb-4">
        <SplitSection data={Elevate_Your_Learning} />
      </div>

      <RegisterSection />
    </div>
  );
}

export default LMS;
