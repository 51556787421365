export const atlearnFeatures = {
  heading: "Atlearn Features",
};

export const atlearnFeaturesCards = [
  {
    title: "Dashboard with All Analytical Information",
    description:
      "Stay on top of your learning journey with our intuitive dashboard, providing real-time insights and analytics at a glance. Monitor progress, track achievements, and make data-driven decisions effortlessly",
  },
  {
    title: "Effortless Assessment with AI-Powered Tools",
    description:
      "Revolutionize your grading process with instant AI-generated question papers, quizzes, and lesson plans. Spend less time on prep and more time inspiring your students",
  },
  {
    title: "Integrated Video Lessons",
    description:
      "Dive into engaging, integrated video lessons that bring concepts to life right at your fingertips. Learn at your own pace with high-quality content designed for maximum retention",
  },
];

export const TeachingBenefits = {
  heading: "Benefits that Elevate Your Teaching Experience",
};

export const TeachingBenefitsFeatures = [
  {
    title: "Enhanced Engagement",
    description:
      "Utilize interactive tools and resources to capture student interest and promote active participation.",
  },
  {
    title: "Time-Saving Automation",
    description:
      "Reduce prep time with instant AI-generated assessments, allowing you to focus on teaching.",
  },
  {
    title: "Personalized Learning",
    description:
      "Tailor quizzes and lesson plans to meet the unique needs of each student for a more effective learning experience.",
  },
  {
    title: "Data-Driven Insights",
    description:
      "Access analytics to track student performance and adjust teaching strategies accordingly.",
  },
  {
    title: "Collaborative Tools",
    description:
      "Foster a collaborative learning environment with integrated features for group projects and discussions.",
  },
  {
    title: "Seamless Integration",
    description:
      "Easily incorporate various multimedia resources into your lessons to enrich the curriculum.",
  },
  {
    title: "Flexibility and Accessibility",
    description:
      "Teach from anywhere with online resources that support diverse learning styles and schedules.",
  }
];
