import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Logo from "src/components/Logo";
import { setUser } from "src/Redux/userSlice";
import { UseAuth } from "src/utils/UseAuth/UseAuth";
function Navbar() {
  const menuData = [
    //------------sample Data -------------------
    // {
    //   title: "Home",
    //   link: "index.html",
    //   subItems: [
    //     {
    //       title: "Home Light",
    //       link: "index.html",
    //       subItems: [
    //         { title: "Home (Default)", link: "index.html" },
    //         { title: "Elegant", link: "home-2.html" },
    //       ],
    //     },
    //     {
    //       title: "Home Dark",
    //       link: "index-dark.html",
    //       subItems: [
    //         { title: "Home Default (Dark)", link: "index-dark.html" },
    //         { title: "Elegant (Dark)", link: "home-2-dark.html" },
    //       ],
    //     },
    //   ],
    // },
    {
      title: "Home",
      link: "/",
      subItems: [
        {
          title: "LMS AI",
          link: "/lms-ai",
        },
        {
          title: "Online Classes",
          link: "/online_classes",
        },
        {
          title: "Kids Platform",
          link: "/kids-platforms",
        },
        {
          title: "Integration",
          link: "/integrationPage",
        },
        // {
        //   title: "Marketplace",
        //   link: "/marketplace",
        // },
        {
          title: "pricing",
          link: "/price",
        },
      ],
    },
    {
      title: "LMS AI",
      link: "/lms-ai",
    },
    {
      title: "Online Classes",
      link: "/online_classes",
    },
    {
      title: "Kids Platform",
      link: "/kids-platforms",
    },
    {
      title: "Integration",
      link: "/integrationPage",
    },
    // {
    //   title: "Marketplace",
    //   link: "/marketplace",
    // },
    {
      title: "pricing",
      link: "/price",
    },
  ];
  const { user } = useSelector((state) => state.user);
  const navRef = useRef(null);
  const [active, setActive] = useState(false);
  const auth = UseAuth(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const controllerStyle = (accordionController, isActive) => {
    const rotateAbleLine = accordionController.querySelectorAll("span")[1];

    if (rotateAbleLine) {
      rotateAbleLine.style.transform = !isActive
        ? "rotate(0deg)"
        : "rotate(90deg)";
    }
  };

  const toggleAccordion = (accordion, isActive) => {
    const parentContent = accordion.closest(".accordion-content");
    const content = accordion.querySelector(".accordion-content");
    const contentWrapper = accordion.querySelector(".content-wrapper");
    const contentHeight = contentWrapper ? contentWrapper.offsetHeight : 0;

    if (content) {
      content.style.height = !isActive ? `${contentHeight}px` : "0";

      if (!isActive) {
        setTimeout(() => {
          if (!parentContent) {
            content.style.height = "auto";
          }
        }, 500);
      }
    }
  };

  const accordionController = (accordionContainer) => {
    const groupOfAccordion = Array.from(accordionContainer.children);

    groupOfAccordion.forEach((accordion, idx) => {
      const accordionController = accordion.querySelector(
        ".accordion-controller"
      );
      const isInitiallyActive = accordion.classList.contains("active");

      if (isInitiallyActive) {
        const contents = accordion.querySelector(".accordion-content");
        const contentHeight = contents ? contents.children[0].offsetHeight : 0;
        if (contents && contentHeight) {
          contents.style.height = `${contentHeight}px`;
        }
      }

      if (accordionController) {
        accordionController.addEventListener("click", function () {
          const currentAccordion = this.closest(".accordion");
          const isActive = currentAccordion.classList.contains("active");

          let waitForDblClick = setTimeout(() => {
            groupOfAccordion.forEach((accordion) => {
              const isAccordionController = accordion.querySelector(
                ".accordion-controller"
              );
              if (isAccordionController) {
                accordion.classList.remove("active");
                controllerStyle(isAccordionController, true);
                toggleAccordion(accordion, true);
              }
            });

            if (!isActive) {
              currentAccordion.classList.add("active");
              controllerStyle(accordionController, false);
              toggleAccordion(currentAccordion, false);
            }
          }, 10);

          accordionController.addEventListener("dblclick", function () {
            clearTimeout(waitForDblClick);
          });
        });
      }
    });
  };

  useEffect(() => {
    if (user?.user) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [user]);

  useEffect(() => {
    if (auth.user) {
      dispatch(setUser(auth.user));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    const navItems = Array.from(navRef.current.querySelectorAll(".nav-item"));

    const showDropdown = (currentIndex) => {
      navItems.forEach((navItem, idx) => {
        const dropdown = navItem.querySelector(".dropdown");
        if (idx === currentIndex && dropdown) {
          dropdown.style.display = "block";
          setTimeout(() => {
            dropdown.style.opacity = "1";
            dropdown.style.transform = "translate(0)";
          }, 150);
        }
      });
    };

    const hideDropdown = (currentIndex) => {
      navItems.forEach((navItem, idx) => {
        const dropdown = navItem.querySelector(".dropdown");
        if (idx === currentIndex && dropdown) {
          dropdown.style.opacity = "0";
          dropdown.style.transform = "translateY(20px)";
          setTimeout(() => {
            dropdown.style.display = "none";
          }, 150);
        }
      });
    };

    const handleMouseEnter = () => {
      navItems.forEach((navItem, idx) => {
        navItem.addEventListener("mouseenter", () => showDropdown(idx));
      });
    };

    const handleMouseLeave = () => {
      navItems.forEach((navItem, idx) => {
        navItem.addEventListener("mouseleave", () => hideDropdown(idx));
      });
    };

    handleMouseEnter();
    handleMouseLeave();

    // Cleanup function
    return () => {
      navItems.forEach((navItem) => {
        navItem.removeEventListener("mouseenter", showDropdown);
        navItem.removeEventListener("mouseleave", hideDropdown);
      });
    };
  }, []);

  useEffect(() => {
    // Select the menu and close button
    const mobileMenu = document.querySelector(".mobile-menu");
    const openMobileMenu = document.querySelector(".open-mobile-menu");
    const closeMobileMenu = document.querySelector(".close-mobile-menu");

    // Define handleOpen and handleClose functions
    const handleOpen = () => {
      closeMobileMenu.style.display = "block";
      mobileMenu.style.right = "0";
    };

    const handleClose = () => {
      const currentScreenSize = window.innerWidth;
      mobileMenu.style.right = currentScreenSize >= 768 ? "-330px" : "-280px";

      setTimeout(() => {
        closeMobileMenu.style.display = "none";
      }, 1000); // Delay to match CSS transition
    };

    // Add event listeners for opening and closing
    openMobileMenu.addEventListener("click", handleOpen);
    closeMobileMenu.addEventListener("click", handleClose);

    // Clean up event listeners on component unmount
    return () => {
      openMobileMenu.removeEventListener("click", handleOpen);
      closeMobileMenu.removeEventListener("click", handleClose);
    };
  }, []);
  useEffect(() => {
    const accordionContainers = document.querySelectorAll(
      ".accordion-container"
    );

    accordionContainers.forEach((accordionContainer) => {
      accordionController(accordionContainer);
    });

    return () => {
      // Cleanup function to remove event listeners if needed
      accordionContainers.forEach((accordionContainer) => {
        const groupOfAccordion = Array.from(accordionContainer.children);
        groupOfAccordion.forEach((accordion) => {
          const accordionController = accordion.querySelector(
            ".accordion-controller"
          );
          if (accordionController) {
            accordionController.removeEventListener(
              "click",
              accordionController.handleClick
            );
          }
        });
      });
    };
  }, []);

  const handleLogout = async () => {
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");
    dispatch(setUser(""));
    // await keycloak.logout();

    navigate("/login", { replace: true });
  };
  return (
    <div>
      {/* Header top start */}
      <div className="bg-blackColor2 dark:bg-lightGrey10-dark hidden lg:block">
        <div className="container 3xl:container-secondary-lg 4xl:container mx-auto text-whiteColor text-size-12 xl:text-sm py-5px xl:py-9px">
          <div className="flex justify-between items-center">
            <div>
              <p>Call Us: +91 8015801639 - Mail Us: support@atlearn.in</p>
            </div>
            <div className="flex gap-37px items-center">
              {/* <div>
                <p>
                  <i className="icofont-location-pin text-primaryColor text-size-15 mr-5px"></i>
                  <span>684 West College St. Sun City, USA</span>
                </p>
              </div> */}
              <div>
                {/* Header social list */}
                <ul className="flex gap-13px text-size-15">
                  <li>
                    <a className="hover:text-primaryColor" href="/">
                      <i className="icofont-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a className="hover:text-primaryColor" href="/">
                      <i className="icofont-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a className="hover:text-primaryColor" href="/">
                      <i className="icofont-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a className="hover:text-primaryColor" href="/">
                      <i className="icofont-youtube-play"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="transition-all duration-500 sticky-header z-medium dark:bg-whiteColor-dark">
        <nav>
          <div className="py-15px lg:py-0 px-15px lg:container 3xl:container-secondary-lg 4xl:container mx-auto relative">
            <div className="grid grid-cols-2 lg:grid-cols-12 items-center gap-15px">
              {/* navbar left */}
              <div className="lg:col-start-1 lg:col-span-2">
                <a href="/" className="block mr-2">
                  <Logo />
                </a>
              </div>
              {/* Main menu */}
              <div
                ref={navRef}
                className="hidden lg:block lg:col-start-3 lg:col-span-7"
              >
                <ul className="nav-list flex justify-center ml-4">
                  <li className="nav-item group relative">
                    <Link
                      to="/"
                      className="px-5 lg:px-10px 2xl:px-15px 3xl:px-5 py-10 lg:py-5 2xl:py-30px 3xl:py-10 leading-sm 2xl:leading-lg text-base lg:text-sm 2xl:text-base font-semibold block group-hover:text-secondaryColor dark:text-whiteColor"
                    >
                      Home
                      <i className="icofont-rounded-down" />
                    </Link>
                    {/* dropdown menu */}
                    <div
                      className="dropdown absolute left-0 translate-y-10 z-medium hidden opacity-0"
                      style={{ transition: "0.3s" }}
                    >
                      <div className="shadow-dropdown max-w-dropdown2 w-2000 py-14px rounded-standard bg-white dark:bg-whiteColor-dark">
                        <ul>
                          <li>
                            <Link
                              to={"/lms-ai"}
                              className="text-sm 2xl:text-base font-semibold text-contentColor border-l-2 border-transparent transition duration-300 hover:border-secondaryColor px-25px py-10px hover:bg-whitegrey1 block hover:text-primaryColor leading-sm lg:leading-lg 2xl:leading-lg dark:text-contentColor-dark dark:hover:text-primaryColor dark:hover:bg-whitegrey1-dark"
                            >
                              LMA AI
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/online_classes"
                              className="text-sm 2xl:text-base font-semibold text-contentColor border-l-2 border-transparent transition duration-300 hover:border-secondaryColor px-25px py-10px hover:bg-whitegrey1 block hover:text-primaryColor leading-sm lg:leading-lg 2xl:leading-lg dark:text-contentColor-dark dark:hover:text-primaryColor dark:hover:bg-whitegrey1-dark"
                            >
                              Online Classes
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/kids-platforms"
                              className="text-sm 2xl:text-base font-semibold text-contentColor border-l-2 border-transparent transition duration-300 hover:border-secondaryColor px-25px py-10px hover:bg-whitegrey1 block hover:text-primaryColor leading-sm lg:leading-lg 2xl:leading-lg dark:text-contentColor-dark dark:hover:text-primaryColor dark:hover:bg-whitegrey1-dark"
                            >
                              Kids Platforms
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/integrationPage"
                              className="text-sm 2xl:text-base font-semibold text-contentColor border-l-2 border-transparent transition duration-300 hover:border-secondaryColor px-25px py-10px hover:bg-whitegrey1 block hover:text-primaryColor leading-sm lg:leading-lg 2xl:leading-lg dark:text-contentColor-dark dark:hover:text-primaryColor dark:hover:bg-whitegrey1-dark"
                            >
                              Integration
                            </Link>
                          </li>
                          {/* <li>
                            <Link
                              to="/marketplace"
                              className="text-sm 2xl:text-base font-semibold text-contentColor border-l-2 border-transparent transition duration-300 hover:border-secondaryColor px-25px py-10px hover:bg-whitegrey1 block hover:text-primaryColor leading-sm lg:leading-lg 2xl:leading-lg dark:text-contentColor-dark dark:hover:text-primaryColor dark:hover:bg-whitegrey1-dark"
                            >
                              Marketplace
                            </Link>
                          </li> */}
                          <li>
                            <Link
                              to="/price"
                              className="text-sm 2xl:text-base font-semibold text-contentColor border-l-2 border-transparent transition duration-300 hover:border-secondaryColor px-25px py-10px hover:bg-whitegrey1 block hover:text-primaryColor leading-sm lg:leading-lg 2xl:leading-lg dark:text-contentColor-dark dark:hover:text-primaryColor dark:hover:bg-whitegrey1-dark"
                            >
                              Pricing
                            </Link>
                          </li>
                             <li>
                            <Link
                              to="/marketplace"
                              className="text-sm 2xl:text-base font-semibold text-contentColor border-l-2 border-transparent transition duration-300 hover:border-secondaryColor px-25px py-10px hover:bg-whitegrey1 block hover:text-primaryColor leading-sm lg:leading-lg 2xl:leading-lg dark:text-contentColor-dark dark:hover:text-primaryColor dark:hover:bg-whitegrey1-dark"
                            >
                              Practical Test
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>

                  <li className="nav-item group relative">
                    <Link
                      to="/lms-ai"
                      className="px-5 lg:px-10px 2xl:px-15px 3xl:px-5 py-10 lg:py-5 2xl:py-30px 3xl:py-10 leading-sm 2xl:leading-lg text-base lg:text-sm 2xl:text-base font-semibold block group-hover:text-secondaryColor dark:text-whiteColor whitespace-nowrap"
                    >
                      LMS AI
                    </Link>
                  </li>
                  <li className="nav-item group relative">
                    <Link
                      to="/online_classes"
                      className=" whitespace-nowrap  px-5 lg:px-10px 2xl:px-15px 3xl:px-5 py-10 lg:py-5 2xl:py-30px 3xl:py-10 leading-sm 2xl:leading-lg text-base lg:text-sm 2xl:text-base font-semibold block group-hover:text-secondaryColor dark:text-whiteColor"
                    >
                      Online Classes
                    </Link>
                  </li>
                  <li className="nav-item group relative">
                    <Link
                      to="/kids-platforms"
                      className=" whitespace-nowrap px-5 lg:px-10px 2xl:px-15px 3xl:px-5 py-10 lg:py-5 2xl:py-30px 3xl:py-10 leading-sm 2xl:leading-lg text-base lg:text-sm 2xl:text-base font-semibold block group-hover:text-secondaryColor dark:text-whiteColor"
                    >
                      Kids Platform
                    </Link>
                  </li>
                  <li className="nav-item group relative">
                    <Link
                      to="/integrationPage"
                      className="px-5 lg:px-10px 2xl:px-15px 3xl:px-5 py-10 lg:py-5 2xl:py-30px 3xl:py-10 leading-sm 2xl:leading-lg text-base lg:text-sm 2xl:text-base font-semibold block group-hover:text-secondaryColor dark:text-whiteColor"
                    >
                      Integration
                    </Link>
                  </li>
                  {/* <li className="nav-item group relative">
                    <Link
                      to="/marketplace"
                      className="px-5 lg:px-10px 2xl:px-15px 3xl:px-5 py-10 lg:py-5 2xl:py-30px 3xl:py-10 leading-sm 2xl:leading-lg text-base lg:text-sm 2xl:text-base font-semibold block group-hover:text-secondaryColor dark:text-whiteColor"
                    >
                      Marketplace
                    </Link>
                  </li> */}
                  <li className="nav-item group relative">
                    <Link
                      to="/price"
                      className="px-5 lg:px-10px 2xl:px-15px 3xl:px-5 py-10 lg:py-5 2xl:py-30px 3xl:py-10 leading-sm 2xl:leading-lg text-base lg:text-sm 2xl:text-base font-semibold block group-hover:text-secondaryColor dark:text-whiteColor"
                    >
                      Pricing
                    </Link>
                  </li>
                  <li className="nav-item group relative">
                    <Link
                      to="/practical-test"
                      className=" whitespace-nowrap px-5 lg:px-10px 2xl:px-15px 3xl:px-5 py-10 lg:py-5 2xl:py-30px 3xl:py-10 leading-sm 2xl:leading-lg text-base lg:text-sm 2xl:text-base font-semibold block group-hover:text-secondaryColor dark:text-whiteColor"
                    >
                      Practical Test
                    </Link>
                  </li>
                </ul>
              </div>
              {/* navbar right */}
              <div className="lg:col-start-10 lg:col-span-3">
                <ul className="relative nav-list flex justify-end items-center">
                  {/* <li className="hidden lg:block">
                    <a
                      href="login.html"
                      className="text-size-12 2xl:text-size-15 px-15px py-2 text-blackColor hover:text-whiteColor bg-whiteColor block hover:bg-primaryColor border border-borderColor1 rounded-standard font-semibold mr-[7px] 2xl:mr-15px dark:text-blackColor-dark dark:bg-whiteColor-dark dark:hover:bg-primaryColor dark:hover:text-whiteColor dark:hover:border-primaryColor"
                    >
                      <i className="icofont-user-alt-5" />
                    </a>
                  </li> */}
                  <li className="hidden lg:block">
                      <Link
                        to={"/become-an-instructor"}
                        className=" mr-2 text-size-12 2xl:text-size-15 text-whiteColor bg-primaryColor block border-primaryColor border hover:text-primaryColor hover:bg-white px-15px py-2 rounded-standard dark:hover:bg-whiteColor-dark dark: dark:hover:text-whiteColor"
                      >
                        Apply As Instructor
                      </Link>
                    </li>
                  {active ? (
                    <li className="hidden lg:block relative group">
                      <span
                        
                        className=" cursor-pointer text-size-12 2xl:text-size-15 px-15px py-2 text-blackColor hover:text-whiteColor bg-whiteColor block hover:bg-primaryColor border border-borderColor1 rounded-standard font-semibold mr-[7px] 2xl:mr-15px dark:text-blackColor-dark dark:bg-whiteColor-dark dark:hover:bg-primaryColor dark:hover:text-whiteColor dark:hover:border-primaryColor"
                      >
                        {/* Profile Image */}
                        {/* <img
                        src="/path/to/profile-image.jpg"
                        alt="Profile"
                        className="w-8 h-8 rounded-full"
                      /> */}
                        <i className="icofont-user-alt-5" />
                      </span>

                      {/* Submenu */}
                      <ul className=" shadow-dropdown absolute right-0 mt-2 max-w-dropdown2 w-2000  bg-white dark:bg-whiteColor-dark rounded-md shadow-lg z-50 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity duration-200 dark:bg-whiteColor-dark">
                        <li>
                          <Link
                            to={"/settings/profile"}
                            className="block px-4 py-2 text-blackColor hover:bg-primaryColor hover:text-whiteColor  dark:text-contentColor-dark dark:hover:bg-primaryColor dark:hover:text-whiteColor"
                          >
                            My Profile
                          </Link>
                        </li>
                        <li>
                          <Link
                           to={"/room"}
                            className="block px-4 py-2 text-blackColor hover:bg-primaryColor hover:text-whiteColor  dark:text-contentColor-dark dark:hover:bg-primaryColor dark:hover:text-whiteColor"
                          >
                            Rooms
                          </Link>
                        </li>
                        {user?.user?.role?.name ==="Administrator" && (
                          <li>
                          <Link
                           to={"/organization/dashboard"}
                            className="block px-4 py-2 text-blackColor hover:bg-primaryColor hover:text-whiteColor  dark:text-contentColor-dark dark:hover:bg-primaryColor dark:hover:text-whiteColor"
                          >
                              Administrator Panel
                          </Link>
                        </li>
                        )}
                        
                        <li>
                          <span
                            onClick={handleLogout}
                            className=" cursor-pointer block px-4 py-2 text-blackColor hover:bg-primaryColor  dark:text-contentColor-dark hover:text-whiteColor dark:hover:bg-primaryColor dark:hover:text-whiteColor"
                          >
                            Logout
                          </span>
                        </li>
                      </ul>
                    </li>
                  ) : (
                    <li className="hidden lg:block">
                      <Link
                        to={"/login"}
                        className="text-size-12 2xl:text-size-15 text-whiteColor bg-primaryColor block border-primaryColor border hover:text-primaryColor hover:bg-white px-15px py-2 rounded-standard dark:hover:bg-whiteColor-dark dark: dark:hover:text-whiteColor"
                      >
                        Login
                      </Link>
                    </li>
                  )}
                   

                  <li className="block lg:hidden">
                    <button className="open-mobile-menu text-3xl text-darkdeep1 hover:text-secondaryColor dark:text-whiteColor dark:hover:text-secondaryColor">
                      <i className="icofont-navigation-menu" />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <>
        {/* mobile menu */}
        <div className="mobile-menu w-mobile-menu-sm md:w-mobile-menu-lg fixed top-0 -right-[280px] md:-right-[330px] transition-all duration-500 w-mobile-menu h-full shadow-dropdown-secodary bg-whiteColor dark:bg-whiteColor-dark z-high block lg:hidden">
          <button className="close-mobile-menu text-lg bg-darkdeep1 hover:bg-secondaryColor text-white px-[11px] py-[6px] absolute top-0 right-full hidden">
            <i className="icofont icofont-close-line" />
          </button>
          {/* mobile menu wrapper */}
          <div className="px-5 md:px-30px pt-5 md:pt-10 pb-50px h-full overflow-y-auto">
            {/* mobile menu accordions */}

            <div className="pt-8 pb-6 border-b border-borderColor dark:border-borderColor-dark">
              <ul className="accordion-container">
                {menuData?.map((item, index) => (
                  <li key={index} className="accordion">
                    {/* accordion header */}
                    <div className="flex items-center justify-between">
                      <Link
                        className="leading-1 py-11px text-darkdeep1 font-medium hover:text-secondaryColor dark:text-whiteColor dark:hover:text-secondaryColor"
                        to={item?.link}
                      >
                        {item?.title}
                      </Link>
                      {item?.subItems && (
                        <button className="accordion-controller px-3 py-4">
                          <span className="w-[10px] h-[1px] bg-darkdeep1 block dark:bg-whiteColor" />
                          <span className="w-[10px] h-[1px] bg-darkdeep1 block dark:bg-whiteColor rotate-90 -mt-[1px] transition-all duration-500" />
                        </button>
                      )}
                    </div>
                    {/* accordion content */}
                    {item?.subItems && (
                      <div className="accordion-content h-0 overflow-hidden transition-all duration-500">
                        <div className="content-wrapper">
                          <ul className="accordion-container">
                            {item?.subItems?.map((submenu, subindex) => (
                              <li key={subindex} className="accordion">
                                {/* accordion header */}
                                <div className="flex items-center justify-between">
                                  <Link
                                    to={submenu?.link}
                                    className="leading-1 text-darkdeep1 text-sm pl-15px pt-3 pb-7px font-medium hover:text-secondaryColor dark:text-whiteColor dark:hover:text-secondaryColor"
                                  >
                                    {submenu?.title}
                                  </Link>
                                  {submenu?.subItems && (
                                    <button className="accordion-controller px-3 py-4">
                                      <span className="w-[10px] h-[1px] bg-darkdeep1 block dark:bg-whiteColor" />
                                      <span className="w-[10px] h-[1px] bg-darkdeep1 block dark:bg-whiteColor rotate-90 -mt-[1px] transition-all duration-500" />
                                    </button>
                                  )}
                                </div>
                                {/* accordion content */}
                                <div className="accordion-content h-0 overflow-hidden transition-all duration-500">
                                  <div className="content-wrapper">
                                    {/* Dropdown */}
                                    <ul>
                                      {submenu?.subItems?.map(
                                        (subitemMenu, subItemIndex) => (
                                          <li key={subItemIndex}>
                                            <Link
                                              to={subitemMenu?.link}
                                              className="leading-1 text-darkdeep1 text-sm pl-30px pt-3 pb-7px font-light hover:text-secondaryColor dark:text-whiteColor dark:hover:text-secondaryColor"
                                            >
                                              {subitemMenu?.title}
                                            </Link>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>

            {/* my account accordion */}
            <div>
              <ul className="accordion-container mt-9 mb-30px pb-9 border-b border-borderColor dark:border-borderColor-dark">
                <li className="accordion group">
                  {/* accordion header */}
                  <div className="accordion-controller flex items-center justify-between">
                    <a
                      className="leading-1 text-darkdeep1 font-medium group-hover:text-secondaryColor dark:text-whiteColor dark:hover:text-secondaryColor"
                      href="/"
                    >
                      My Account
                    </a>
                    <button className="px-3 py-1">
                      <i className="icofont-thin-down text-size-15 text-darkdeep1 group-hover:text-secondaryColor dark:text-whiteColor dark:hover:text-secondaryColor" />
                    </button>
                  </div>
                  {/* accordion content */}
                  <div className="accordion-content h-0 overflow-hidden transition-all duration-500 shadow-standard">
                    <div className="content-wrapper">
                      <ul>
                        <li>
                          {/* accordion header */}
                          <div className="flex items-center gap-1">
                            <a
                              href="login.html"
                              className="leading-1 text-darkdeep1 text-sm pl-30px pt-7 pb-3 font-medium hover:text-secondaryColor dark:text-whiteColor dark:hover:text-secondaryColor"
                            >
                              Login
                            </a>
                            <a
                              href="login.html"
                              className="leading-1 text-darkdeep1 text-sm pr-30px pt-7 pb-4 font-medium hover:text-secondaryColor dark:text-whiteColor dark:hover:text-secondaryColor"
                            >
                              <span>/</span> Create Account
                            </a>
                          </div>
                        </li>
                        <li>
                          {/* accordion header */}
                          <div className="flex items-center justify-between">
                            <a
                              href="login.html"
                              className="leading-1 text-darkdeep1 text-sm pl-30px pt-3 pb-7 font-medium hover:text-secondaryColor dark:text-whiteColor dark:hover:text-secondaryColor"
                            >
                              My Account
                            </a>
                          </div>
                          {/* accordion content */}
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            {/* Mobile menu social area */}
            <div>
              <ul className="flex gap-6 items-center mb-5">
                <li>
                  <a className="facebook" href="/">
                    <i className="icofont icofont-facebook text-fb-color dark:text-whiteColor dark:hover:text-secondaryColor" />
                  </a>
                </li>
                <li>
                  <a className="twitter" href="/">
                    <i className="icofont icofont-twitter text-twiter-color dark:text-whiteColor dark:hover:text-secondaryColor" />
                  </a>
                </li>
                <li>
                  <a className="pinterest" href="/">
                    <i className="icofont icofont-pinterest dark:text-whiteColor dark:hover:text-secondaryColor" />
                  </a>
                </li>
                <li>
                  <a className="instagram" href="/">
                    <i className="icofont icofont-instagram dark:text-whiteColor dark:hover:text-secondaryColor" />
                  </a>
                </li>
                <li>
                  <a className="google" href="/">
                    <i className="icofont icofont-youtube-play dark:text-whiteColor dark:hover:text-secondaryColor" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Navbar;
