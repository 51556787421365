import React, { useEffect } from "react";
// import "./RegisterSection.css"; // Include any custom styles here

const RegisterSection = () => {
  useEffect(() => {
    videoModal();

    // Cleanup on component unmount
    return () => {
      const wrapper = document.getElementById("lvideo-wrap");
      if (wrapper) wrapper.remove();
    };
  }, []);

  const videoModal = () => {
    document
      .querySelectorAll(".lvideo")
      .forEach((d) => d.addEventListener("click", playVideos));
    const body = document.body;

    function playVideos(e) {
      lvideo(e.currentTarget.dataset.url);
      body.classList.add("lvideo-active");

      const lvideoWrap = document.createElement("DIV");
      lvideoWrap.setAttribute("id", "lvideo-wrap");
      document.body.appendChild(lvideoWrap);

      const wrapper = document.getElementById("lvideo-wrap");
      wrapper.classList.add("active");

      const url = e.currentTarget.dataset.url;
      const startModal = `<span class="lvideo-overlay"></span> <div class="lvideo-container">`;
      const finishModal = `<button class="lvideo-close"><i class="icofont-close-line"></i></button></div>`;

      if (url.includes("youtube") || url.includes("youtu")) {
        const ytUrl = [url];
        const regExp =
          /^.*(?:youtu\.be\/|v\/|vi\/|embed\/|watch\?v=)([^#\&\?]*).*/;
        const r = ytUrl[0].match(regExp);

        wrapper.innerHTML = `${startModal} <iframe width="560" height="315" title="YouTube Video" src='https://www.youtube.com/embed/${r[1]}?rel=0&autoplay=1&mute=1&loop=1&playlist=${r[1]}' frameborder="0" allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>${finishModal}`;
      } else if (url.includes("vimeo")) {
        const regExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
        const match = url.match(regExp);

        if (match) {
          wrapper.innerHTML = `${startModal}<iframe title="Vimeo" src="https://player.vimeo.com/video/${match[2]}?autoplay=1&loop=1" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>${finishModal}`;
        } else {
          alert(
            "Not a valid Vimeo URL! Example:\n https://vimeo.com/120206922"
          );
        }
      } else if (url.includes("mp4") || url.includes("m4v")) {
        wrapper.innerHTML = `${startModal}<video controls loop playsinline autoplay><source src='${url}' type="video/mp4"></video>${finishModal}`;
      } else {
        alert("No valid video link found.");
      }

      const modalOverlay = wrapper.querySelector(".lvideo-overlay");
      const modalClose = wrapper.querySelector(".lvideo-close");

      modalOverlay.addEventListener("click", lvideoClose);
      modalClose.addEventListener("click", lvideoClose);
    }

    function lvideo() {}

    const lvideoClose = () => {
      body.classList.remove("lvideo-active");
      const wrapper = document.getElementById("lvideo-wrap");
      if (wrapper) wrapper.remove();
    };
  };

  return (
    <>
      <section
        className="bg-cover bg-center bg-no-repeat  register-section"
        style={{
          backgroundImage: "url('assets/images/register/register__bg.png')",
        }}
      >
        <div className=" bg-blueDark bg-opacity-90   lg:pb-0 relative z-0 ">
          <div className="container">
            <div className="grid grid-cols-1 lg:grid-cols-12 pt-30px gap-x-30px">
              <div
                className="mb-30px lg:mb-0 pb-0 md:pb-30px xl:pb-0 lg:col-start-1 lg:col-span-12"
                data-aos="fade-up"
              >
                <div className="relative">
                  <span className="text-sm font-semibold text-primaryColor bg-whitegrey3 px-6 py-5px mb-5 rounded-full inline-block">
                    Registration
                  </span>
                  <h3 className="text-3xl md:text-[35px] 2xl:text-size-42 leading-[45px] 2xl:leading-2xl font-bold text-whiteColor pb-25px">
                    Streamline your LMS journey with Atlearn
                  </h3>
                  <div className="flex gap-x-5 items-center">
                    {/* <div>
                      <button
                        data-url="https://www.youtube.com/watch?v=vHdclsdkp28"
                        class="lvideo relative w-15 h-15 md:h-20 md:w-20 lg:w-15 lg:h-15 2xl:h-70px 2xl:w-70px 3xl:h-20 3xl:w-20 bg-secondaryColor rounded-full flex items-center justify-center"
                      >
                        <span class="animate-buble absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 block w-[180px] h-[180px] border-secondaryColor rounded-full"></span>
                        <span class="animate-buble2 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 block w-[180px] h-[180px] border-secondaryColor rounded-full"></span>
                        <img src="./assets/images/icon/video.png" alt="" />
                      </button>
                    </div> */}
                    <div>
                      <p className="text-size-15 md:text-[22px] lg:text-lg 2xl:text-[22px] leading-6 md:leading-9 lg:leading-8 2xl:leading-9 font-semibold text-white">
                        Optimize teaching and learning with a platform designed
                        to adapt to your educational goals.
                      </p>
                      <a
                        href="https://lms.atlearn.in/"
                        className=" mt-5 text-sm md:text-size-15 font-semibold text-darkdeep2 bg-whiteColor border border-whiteColor px-5 md:px-30px py-3 md:py-4 hover:text-whiteColor hover:bg-darkblack rounded inline-block mr-6px md:mr-30px shadow-hero-action dark:bg-whiteColor-dark dark:hover:bg-whiteColor dark:text-whiteColor dark:hover:text-whiteColor-dark dark:border-none mb-8"
                      >
                        Start Registration
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="overflow-visible lg:col-start-8 lg:col-span-5 relative z-1 lg:-mb-150px">
                <form
                  className="p-35px pt-10 bg-lightGrey10 dark:bg-lightGrey10-dark rounded shadow-experience"
                  data-aos="fade-up"
                >
                  <h3 className="text-xl text-blackColor dark:text-blackColor-dark font-semibold mb-5 font-inter">
                    Fill Your Registration
                  </h3>
                  <input
                    type="text"
                    placeholder="Your Name"
                    className="w-full px-15px py-3 bg-lightGrey8 text-base mb-25px focus:outline-none"
                  />
                  <div className="grid grid-cols-1 xl:grid-cols-2 xl:gap-x-30px">
                    <input
                      type="email"
                      placeholder="Email Address"
                      className="w-full px-15px py-3 bg-lightGrey8 text-base mb-25px focus:outline-none"
                    />
                    <input
                      type="text"
                      placeholder="Phone"
                      className="w-full px-15px py-3 bg-lightGrey8 text-base mb-25px focus:outline-none"
                    />
                  </div>
                  <input
                    type="text"
                    placeholder="Address"
                    className="w-full px-15px py-3 bg-lightGrey8 text-base mb-25px focus:outline-none"
                  />
                  <textarea
                    placeholder="Comment"
                    className="w-full px-15px pb-3 pt-5 bg-lightGrey8 text-base mb-25px h-[155px] placeholder:text-blackColor"
                    cols={30}
                    rows={10}
                    defaultValue={""}
                  />
                  <div>
                    <button
                      type="submit"
                      className="text-size-15 text-whiteColor bg-primaryColor px-25px py-10px border border-primaryColor hover:text-primaryColor hover:bg-whiteColor inline-block rounded group dark:hover:text-whiteColor dark:hover:bg-whiteColor-dark"
                    >
                      Sign Up
                      <i className="icofont-long-arrow-right group-hover:text-primaryColor" />
                    </button>
                  </div>
                </form>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RegisterSection;
