import React, { useState } from "react";
import FeaturesCard from "src/components/New components/FeaturesCard";
// import FeatureSlider from "src/components/New components/FeatureSlider";
import {
  onlineClaasesFeatures,
  BestOnlineClassroom,
  OnlineClassroomPlatform,
  Benefits,
  SetUpanOnlineClassroom,
  SetUpanOnlineClassroomCards,
  SeamlessVideo,
} from "src/Page_Content/OnlineClasses";
import ScheduleRoomSection from "./ScheduleRoomSection";
import BannerImage from "src/assets/images/online-classes/New/Banner Image - Online Classes.gif";
import { Link } from "react-router-dom";
import SplitSection from "src/components/New components/SplitSection";
import SectionHeading from "src/components/New components/SectionHeading";

function OnlineClasses() {
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  return (
    <div>
      {/* banner section */}
      <section>
        {/* banner section */}
        <div className="hero bg-lightGrey11 dark:bg-lightGrey11-dark relative z-0 overflow-hidden py-50px ">
          {/* animated icons */}
          <div>
            <img
              className="absolute left-10 bottom-[233px] md:left-[248px] md:bottom-[143px] lg:left-10 lg:bottom-[112px] 3xl:bottom-[233px] animate-move-var opacity-35 z-10"
              src="./assets/images/herobanner/herobanner__1.png"
              alt=""
            />
            <img
              className="absolute left-0 top-0 md:left-[50px] md:top-[110px] lg:left-[30px] lg:top-[75px] 2xl:left-[50px] 2xl:top-16 3xl:top-[110px] animate-spin-slow"
              src="./assets/images/herobanner/herobanner__2.png"
              alt=""
            />
            <img
              className="absolute md:left-[210px] md:top-[50px] animate-move-var2 hidden md:block"
              src="./assets/images/herobanner/herobanner__3.png"
              alt=""
            />
            <img
              className="absolute top-20 left-[872px] md:left-[872px] lg:left-[595px] 2xl:left-[872px] hidden md:block animate-move-hor"
              src="./assets/images/herobanner/herobanner__4.png"
              alt=""
            />
            <img
              className="absolute top-0 right-0 md:right-[110px] md:top-[100px] lg:right-[13px] lg:top[90px] 2xl:right-[82px] 2xl:top-[100px] 3xl:right-[110px] animate-move-hor"
              src="./assets/images/herobanner/herobanner__5.png"
              alt=""
            />
          </div>
          <div className="container 2xl:container-secondary-md relative">
            <div className="grid grid-cols-1 lg:grid-cols-12 items-center gap-30px">
              {/* banner Left */}
              <div data-aos="fade-up" className="lg:col-start-1 lg:col-span-7">
                <div className="3xl:pr-135px">
                  <h3 className="uppercase text-secondaryColor text-size-15 mb-5px md:mb-15px font-inter tracking-[4px] font-semibold">
                    Online Classes
                  </h3>

                  <h1 className="text-size-35 md:text-size-65 lg:text-5xl 2xl:text-size-65 leading-42px md:leading-18 lg:leading-15 2xl:leading-18 text-blackColor dark:text-blackColor-dark md:tracking-half lg:tracking-normal 2xl:tracking-half font-bold mb-15px">
                    {/* Best Kindergarten Awesome
                          <span className="text-secondaryColor">Solution</span> */}
                    Enhance Your Video Meetings
                  </h1>
                  <p className="text-size-15md:text-lg text-blackColor dark:text-blackColor-dark font-medium">
                    {/* Lorem Ipsum is simply dummy text of the printing typesetting
                <br />
                industry. Lorem Ipsum has been */}
                    Learn anytime, anywhere—unlock your potential with Atlearn's
                    online classes
                  </p>
                  <div className="mt-30px">
                    <Link
                      to={"/room"}
                      className="text-sm md:text-size-15 text-whiteColor bg-secondaryColor border border-secondaryColor px-25px py-15px hover:text-secondaryColor hover:bg-whiteColor rounded inline-block dark:hover:bg-whiteColor-dark dark:hover:text-secondaryColor"
                    >
                      Explore More
                    </Link>
                    <Link
                      to={"/room"}
                      className=" ml-2 text-sm md:text-size-15 text-whiteColor bg-primaryColor border border-primaryColor px-25px py-15px hover:text-primaryColor hover:bg-whiteColor rounded inline-block dark:hover:bg-whiteColor-dark dark:hover:text-primaryColor"
                    >
                      Create Room
                    </Link>
                  </div>
                </div>
              </div>
              {/* banner right */}
              <div data-aos="fade-up" className="lg:col-start-8 lg:col-span-5">
                <div className="relative">
                  <img
                    src={BannerImage}
                    alt=""
                    className="w-full rounded-lg2"
                  />
                  <div className="absolute top-0 bottom-0 right-0 left-0 flex items-center justify-center">
                    <button
                      onClick={() => setIsVideoOpen(true)}
                      className="lvideo relative w-15 h-15 md:h-20 md:w-20 lg:w-15 lg:h-15 2xl:h-70px 2xl:w-70px 3xl:h-20 3xl:w-20 bg-secondaryColor rounded-full flex items-center justify-center"
                    >
                      <span className="animate-buble absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 block w-[180px] h-[180px] border-secondaryColor rounded-full" />
                      <span className="animate-buble2 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 block w-[180px] h-[180px] border-secondaryColor rounded-full" />
                      <img
                        src="./assets/images/icon/video.png"
                        alt=""
                        className=""
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="mt-4">
        <SplitSection data={OnlineClassroomPlatform} />
      </div>

      <div>
      <SectionHeading data={SeamlessVideo} center={true} />
      <FeaturesCard data={onlineClaasesFeatures} row={3} />

      </div>
      <div>
        <SectionHeading data={SetUpanOnlineClassroom} center={true} />
        <FeaturesCard data={SetUpanOnlineClassroomCards} row={3} />
      </div>

      <ScheduleRoomSection />

      <div>
        <SplitSection data={BestOnlineClassroom} />
      </div>
     
      <div>
        <SplitSection data={Benefits} />
      </div>




      {/* <FeatureSlider/> */}
     
      <section>
        <div className="container py-30px md:pt-10 md:pb-50px 2xl:pt-50px 2xl:pb-100px">
          {/* heading */}
          <div data-aos="fade-up" className="text-center mb-15px">
            <span className="text-size-15 font-semibold text-secondaryColor inline-block uppercase mb-[13px]">
              Our Tutors
            </span>
            <h3 className="text-3xl md:text-size-35 lg:text-size-45 leading-10 md:leading-2xl font-bold text-blackColor dark:text-blackColor-dark">
              Learn From Top Tutors
            </h3>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-30px">
            {/* teacher 1 */}
            <div data-aos="fade-up">
              <div className="mb-5 relative group">
                <img
                  src="./assets/images/team/team__1.png"
                  alt=""
                  className="w-full"
                />
                {/* social icons */}
                <div className="absolute transition-all duration-300 right-0 top-5 opacity-0 group-hover:opacity-100 group-hover:right-5">
                  <ul className="flex flex-col gap-10px">
                    <li>
                      <a
                        href="/"
                        className="w-41px h-41px leading-41px bg-whiteColor text-darkdeep4 hover:bg-primaryColor hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor dark:hover:text-whiteColor text-base rounded text-center"
                      >
                        <i className="icofont-facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="w-41px h-41px leading-41px bg-whiteColor text-darkdeep4 hover:bg-primaryColor hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor dark:hover:text-whiteColor text-base rounded text-center"
                      >
                        <i className="icofont-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="w-41px h-41px leading-41px bg-whiteColor text-darkdeep4 hover:bg-primaryColor hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor dark:hover:text-whiteColor text-base rounded text-center"
                      >
                        <i className="icofont-instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="w-41px h-41px leading-41px bg-whiteColor text-darkdeep4 hover:bg-primaryColor hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor dark:hover:text-whiteColor text-base rounded text-center"
                      >
                        <i className="icofont-youtube-play" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <p className="text-base text-contentColor dark:text-contentColor-dark uppercase mb-1">
                  INTERIOR DESIGNER
                </p>
                <h5>
                <Link
                   to={"/instructor-details"}
                    className="text-2xl font-semibold text-contentColor dark:text-contentColor-dark hover:text-primaryColor dark:hover:text-primaryColor mb-15px"
                  >
                    Mirnsdo Jons
                  </Link>
                </h5>
              </div>
            </div>
            {/* teacher 2 */}
            <div data-aos="fade-up">
              <div className="mb-5 relative group">
                <img
                  src="./assets/images/team/team__2.png"
                  alt=""
                  className="w-full"
                />
                {/* social icons */}
                <div className="absolute transition-all duration-300 right-0 top-5 opacity-0 group-hover:opacity-100 group-hover:right-5">
                  <ul className="flex flex-col gap-10px">
                    <li>
                      <a
                        href="/"
                        className="w-41px h-41px leading-41px bg-whiteColor text-darkdeep4 hover:bg-primaryColor hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor dark:hover:text-whiteColor text-base rounded text-center"
                      >
                        <i className="icofont-facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="w-41px h-41px leading-41px bg-whiteColor text-darkdeep4 hover:bg-primaryColor hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor dark:hover:text-whiteColor text-base rounded text-center"
                      >
                        <i className="icofont-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="w-41px h-41px leading-41px bg-whiteColor text-darkdeep4 hover:bg-primaryColor hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor dark:hover:text-whiteColor text-base rounded text-center"
                      >
                        <i className="icofont-instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="w-41px h-41px leading-41px bg-whiteColor text-darkdeep4 hover:bg-primaryColor hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor dark:hover:text-whiteColor text-base rounded text-center"
                      >
                        <i className="icofont-youtube-play" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <p className="text-base text-contentColor dark:text-contentColor-dark uppercase mb-1">
                  INTERIOR DESIGNER
                </p>
                <h5>
                <Link
                   to={"/instructor-details"}
                    className="text-2xl font-semibold text-contentColor dark:text-contentColor-dark hover:text-primaryColor dark:hover:text-primaryColor mb-15px"
                  >
                    Daniel Rock
                  </Link>
                </h5>
              </div>
            </div>
            {/* teacher 3 */}
            <div data-aos="fade-up">
              <div className="mb-5 relative group">
                <img
                  src="./assets/images/team/team__3.png"
                  alt=""
                  className="w-full"
                />
                {/* social icons */}
                <div className="absolute transition-all duration-300 right-0 top-5 opacity-0 group-hover:opacity-100 group-hover:right-5">
                  <ul className="flex flex-col gap-10px">
                    <li>
                      <a
                        href="/"
                        className="w-41px h-41px leading-41px bg-whiteColor text-darkdeep4 hover:bg-primaryColor hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor dark:hover:text-whiteColor text-base rounded text-center"
                      >
                        <i className="icofont-facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="w-41px h-41px leading-41px bg-whiteColor text-darkdeep4 hover:bg-primaryColor hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor dark:hover:text-whiteColor text-base rounded text-center"
                      >
                        <i className="icofont-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="w-41px h-41px leading-41px bg-whiteColor text-darkdeep4 hover:bg-primaryColor hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor dark:hover:text-whiteColor text-base rounded text-center"
                      >
                        <i className="icofont-instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="w-41px h-41px leading-41px bg-whiteColor text-darkdeep4 hover:bg-primaryColor hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor dark:hover:text-whiteColor text-base rounded text-center"
                      >
                        <i className="icofont-youtube-play" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <p className="text-base text-contentColor dark:text-contentColor-dark uppercase mb-1">
                  INTERIOR DESIGNER
                </p>
                <h5>
                  <Link
                   to={"/instructor-details"}
                    className="text-2xl font-semibold text-contentColor dark:text-contentColor-dark hover:text-primaryColor dark:hover:text-primaryColor mb-15px"
                  >
                    Sajid Mustahidul
                  </Link>
                </h5>
              </div>
            </div>
          </div>
          <div className="flex justify-center" data-aos="fade-up">
            <Link
              to={"/instructors"}
              className="text-size-15 px-47px py-15px bg-primaryColor text-whiteColor border border-primaryColor hover:text-primaryColor hover:bg-whiteColor dark:hover:bg-whiteColor-dark dark:hover:text-whiteColor mt-10 md:mt-50px rounded uppercase"
            >
              More Teachers
            </Link>
          </div>
        </div>
      </section>
      {isVideoOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50"
          onClick={() => setIsVideoOpen(false)}
        >
          <div
            className="relative bg-white rounded-lg overflow-hidden w-full max-w-2xl"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="absolute top-4 right-4 bg-gray-200 hover:bg-gray-300 text-gray-800 rounded-full w-8 h-8 flex items-center justify-center"
              onClick={() => setIsVideoOpen(false)}
            >
              ✕
            </button>
            <iframe
              width="100%"
              height="450"
              src="https://www.youtube.com/embed/S68HSlHiXEc"
              title="YouTube video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="rounded-lg"
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
}

export default OnlineClasses;
