import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import apiClients from "src/apiClients/apiClients";
import MainButton from "src/components/Button/MainButton/MainButton";

function Feedback() {
  const formdata = {
    name: "",
    email: "",
    message: "",
  };
  const [formData, setFormData] = useState();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setFormData(formdata);
    // return () => {
    //   setFormData({});
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = async () => {
    try {
      const newErrors = {};
      const requiredFields = ["name", "email", "message"];
      requiredFields.forEach((field) => {
        if (
          formData[field] === undefined ||
          formData[field] === null ||
          formData[field] === ""
        ) {
          newErrors[field] = `This ${field} field is required.`;
        }
      });
      if (formData.email && !validateEmail(formData.email)) {
        newErrors.email = "Please enter a valid email address.";
      }

      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
      } else {
        setErrors({});
        const data = formData;
        // eslint-disable-next-line no-unused-vars
        setLoading(true);
        const responnse = await apiClients.feedback(data);
        if (responnse.success === true) {
          toast.success(responnse.message);
        } else {
          toast.error("someting error");
        }
        setLoading(false);
        setFormData(formdata);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const validateEmail = (email) => {
    // You can use a regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const newErrors = { ...errors };

    if (name === "email" && !validateEmail(value)) {
      newErrors.email = "Please enter a valid email address";
    } else {
      delete newErrors.email;
    }

    setErrors(newErrors);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "400px",
          background: "#FFD600",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "36px",
            textAlign: "left",
            color: "#000000",
          }}
        >
          Feedback
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "20px",
            lineHeight: "30px",
            color: "#000000",
            textAlign: "center",
          }}
        >
          We'd love to here your thoughts
        </Typography>
      </Box>
      <Box>
        <Container>
          <Box sx={{ marginTop: "-90px" }}>
            <Grid container justifyContent="center">
              <Grid item xs={10}>
                <Card sx={{ mb: 6.25 }} elevation={4}>
                  <CardContent sx={{ p: 4 }}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          type="text"
                          label="Name"
                          name="name"
                          placeholder="Enter Your Name"
                          value={formData?.name || ""}
                          onChange={handleChange}
                          error={!!errors.name}
                          helperText={errors.name}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          type="text"
                          name="email"
                          label="Email Address"
                          placeholder="Enter Your Email Address"
                          value={formData?.email || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.email}
                          helperText={errors.email}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-multiline-static1"
                            placeholder="Message"
                            name="message"
                            multiline
                            fullWidth
                            rows={4}
                            value={formData?.message || ""}
                            onChange={handleChange}
                            error={!!errors.message}
                            helperText={errors.message}
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"end"}
                        gap={1}
                      >
                        {/* <SecondaryButton>Cancel</SecondaryButton> */}
                        <MainButton onClick={handleSubmit}>
                          {loading && (
                            <CircularProgress
                              size={"1.2rem"}
                              sx={{ color: "white", mr: 2 }}
                            />
                          )}
                          Submit
                        </MainButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default Feedback;
