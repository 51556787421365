import React, { useState, useEffect } from "react";
import "./Home.css";
import MainButton from "src/components/Button/MainButton/MainButton";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
// import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import NewRoom from "src/components/NewRoom/NewRoom";
import apiClients from "src/apiClients/apiClients";
import { toast } from "react-toastify";
// import meetingIcon from "src/images/room/Vector (3).svg";
import SecondaryButton from "src/components/Button/SecondaryButton/SecondaryButton";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import { useDispatch, useSelector } from "react-redux";
import CircleIcon from "@mui/icons-material/Circle";
import GroupIcon from "@mui/icons-material/Group";
import LoaderHome from "./LoaderHome";
import { useNavigate } from "react-router-dom";
// import classImage from "src/images/landingpages/Rectangle 347.svg";
import { setUser } from "src/Redux/userSlice";
import ExpiredCard from "src/components/Notification/ExpiredCard";
// import RoomVideoicon from "src/images/landingpages/videocallIcon.svg";
import DeleteConfirmation from "src/components/confirmationPopup/confirmationPopup";
import AddonModal from "src/components/Addonplan/AddonModal";
import { checkDuration, checkStorage } from "src/utils/addonCheck/addonUtils";
import NotificationPopup from "src/components/Notification/NotificationPopup";
import LimitExceededModal from "src/components/Notification/LimitExceededModal";
import { convertToDate } from "src/utils/FormateDateUtils";
import coverImage from "src/assets/images/online-classes/New/Classroom_Content_Online_Courses_Cover.webp"
function Home() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [rooms, setRooms] = useState();
  const [filteredRooms, setFilteredRooms] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadigPage] = useState(false);
  const [loadingRoom, setLoadingRoom] = useState(null);
  const [cancelIcon, setCancelIcon] = useState(false);
  const [notification, setNotfication] = useState(false);
  const [expired, setExpired] = useState(false);
  const [daydiff, setDayDiff] = useState();
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [roomId, setRoomId] = useState();
  const [addonplanopen, setAddonPlanOpen] = useState(false);
  const [addonDuration, setAddonDuration] = useState(false);
  const [addonStorage, setAddonStorage] = useState(false);
  const [limitModalOpen, setLimitModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
    fetchTotalDuration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.user) {
      if (user?.permission?.["CreateRoom"] !== "true") {
        navigate("/only_join");
      }

      if (
        user?.user?.subscription_start_date &&
        user?.user?.subscription_expiry_date &&
        user?.user?.role?.name !== "Super Admin" &&
        user?.user?.role?.name !== "Administrator"
      ) {
        const subscriptionStartDate = new Date(
          user?.user?.subscription_start_date
        );
        // const subscriptionStartDate = new Date(
        //   "2024-09-01"
        //  );
        const subscriptionEndDate = new Date(
          user?.user?.subscription_expiry_date
        );

        const timeDiff = subscriptionEndDate - subscriptionStartDate;
        const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

        const currentDate = new Date();
        // const currentDate = new Date( "2024-10-24");
        const daysDifference = Math.floor(
          (currentDate - subscriptionStartDate) / (1000 * 60 * 60 * 24)
        );

        const expirationThreshold = daysDiff;
        setDayDiff(expirationThreshold - daysDifference);

        if (daysDifference >= expirationThreshold) {
          if (
            user?.user?.subscription?.name === "Enterprise" &&
            user?.user?.trial === true
          ) {
            PlanChange("free");
          } else {
            if (user?.user?.subscription_pending === true) {
              PlanChange("next");
            }
            handleRoomSettings();
            handleCreateNotification(true);
            setExpired(true);
          }
        } else {
          setExpired(false);
          // setNotfication(expirationThreshold - daysDifference <= 14);
          if (expirationThreshold - daysDifference <= 14) {
            if (user?.user?.subscription_pending === true) {
              setNotfication(false);
            } else {
              handleCreateNotification();
              setNotfication(true);
            }
          } else {
            setNotfication(false);
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (user?.user) {
      const usedStorageKB = 10485750;
      const totalStorageGB = 10;
      const usedSeconds = user?.user?.duration_spent;
      const totalHours =
        user?.user?.subscription?.duration + user?.user?.addon_duration;
      // Use utility functions

      setAddonStorage(checkStorage(usedStorageKB, totalStorageGB));
      setAddonDuration(checkDuration(usedSeconds, totalHours));
      setLimitModalOpen(checkStorage(usedStorageKB, totalStorageGB));
      setLimitModalOpen(checkDuration(usedSeconds, totalHours));
    }
  }, [user]);

  const fetchData = async () => {
    setLoadigPage(true);
    try {
      const response = await apiClients.getRooms();
      if (response.data) {
        let responseData = response.data;
        // Sort the data in descending order
        responseData.sort((a, b) => {
          const dateA = convertToDate(a.last_session);
          const dateB = convertToDate(b.last_session);
          // Handle null dates (invalid date strings)
          if (dateA === null) return 1;
          if (dateB === null) return -1;

          return dateB - dateA;
        });

        setRooms(responseData);
        setFilteredRooms(responseData);
        setLoadigPage(false);
      } else {
        setRooms([]);
        setFilteredRooms([]);
        setLoadigPage(false);
      }
    } catch (error) {
      setLoadigPage(false);
      console.log(error);
    }
  };

  const session = async () => {
    try {
      const responseData = await apiClients.sessionData();
      if (responseData?.success === true) {
        if (responseData?.data) {
          dispatch(setUser(responseData.data));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTotalDuration = async () => {
    try {
      const response = await apiClients.getTotalduration();
      if (response) {
        session();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleRoomSettings = async () => {
    try {
      const response = await apiClients.expiredPlan();
      if (response.data) {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const PlanChange = async (plan) => {
    try {
      const data = {
        plan: plan,
      };
      const response = await apiClients.changePlan(data);
      if (response.success === true) {
        session();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateRoom = async (value, price, image) => {
    // const data = {
    //   name: value,
    //   price: price || null,
    // };
    const formData = new FormData();
    formData.append("name", value);
    formData.append("image", image);
    try {
      const response = await apiClients.createRooms(formData);
      if (response) {
        fetchData();
      }
      if (response.success === true) {
        toast.success(response.message);
      }
    } catch (error) {
      console.log();
    }
  };

  const handleStartMeeting = async (id) => {
    setLoadingRoom(id);
    setLoading(true);

    try {
      const { data, success, message, duration } =
        await apiClients.startMeeting(id);

      if (data?.joinModeratorUrl) {
        window.location.href = data.joinModeratorUrl;
      } else if (!success && message) {
        toast.error(message);
      }

      if (duration) {
        setAddonDuration(true);
        setLimitModalOpen(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setCancelIcon(true);
    const { value } = event.target;
    setSearchTerm(value);
    const filtered = rooms.filter((room) =>
      room.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredRooms(filtered);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };
  const handleAddonOpen = () => {
    setAddonPlanOpen(true);
  };
  const handleCloseAddonPlan = () => {
    setAddonPlanOpen(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  // const hadleLimitModalOpen = () => {
  //   setLimitModalOpen(true);
  // };

  const handleLimitModalClose = () => {
    setLimitModalOpen(false);
    if (user?.user?.trial !== true) {
      handleAddonOpen();
    }
  };

  const handleCopy = (id) => {
    navigator.clipboard.writeText(`${window.location}/${id}/join`);
    toast.success(
      "The meeting URL has been copied. The link can be used to join the meeting."
    );
  };

  const cancelSearch = () => {
    setSearchTerm("");
    const value = "";
    const filtered = rooms.filter((room) =>
      room.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredRooms(filtered);
    setCancelIcon(false);
  };

  const handleDeleteConirmationOpen = (id) => {
    setRoomId(id);
    setDeleteConfirm(true);
  };

  const handleDeleteConirmationClose = () => {
    setDeleteConfirm(false);
  };

  const handleDeleteRoom = async () => {
    try {
      const response = await apiClients.removeRoom(roomId);
      if (response.success === true) {
        fetchData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateNotification = async (expired) => {
    try {
      const data = {
        title: expired ? "Your Plan Expired" : "Your Plan Expiring Soon",
        message: expired
          ? `Your ${
              user?.user?.trial === true ||
              user?.user?.subscription?.name === "Free"
                ? user?.user?.subscription?.name + " trial "
                : user?.user?.subscription?.name + " plan "
            } period has expired.`
          : `Your ${
              user?.user?.trial === true ||
              user?.user?.subscription?.name === "Free"
                ? user?.user?.subscription?.name + " trial "
                : user?.user?.subscription?.name + " plan "
            }  period has only ${daydiff}  days remaining.`,
        type: "Plan Expiry",
      };
      await apiClients.createNotification(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        {expired ? (
          <ExpiredCard />
        ) : (
          <>
            <Container maxWidth={"xl"} sx={{ marginTop: 12 }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                    alignItems: "end",
                    gap: 2,
                    width: "100%",
                  }}
                >
                  {notification && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "space-between",
                        justifyContent: "center",

                        backgroundColor: "#F5F6F7",
                        padding: "20px",
                        border: "1px solid #6D207B",
                        borderRadius: "20px",
                        flexDirection: { xs: "column", sm: "row" },
                        width: { xs: "100%", sm: "auto" },
                        gap: 2,
                      }}
                    >
                      <div
                        style={{
                          flex: 3,
                          textAlign: "center",
                          marginLeft: "20px",
                        }}
                      >
                        {`Your ${
                          user?.user?.trial === true ||
                          user?.user?.subscription?.name === "Free"
                            ? user?.user?.subscription?.name + " trial "
                            : user?.user?.subscription?.name + " plan "
                        }  period has only`}
                        <br />
                        <span
                          style={{ color: "red" }}
                        >{`${daydiff}  days remaining.`}</span>
                      </div>
                      <div style={{ flex: 1, textAlign: "center" }}>
                        <SecondaryButton
                          onClick={() => navigate("/pricing")}
                          style={{
                            fontSize: "0.8rem",
                            padding: "10px",
                            width: "120px",
                            marginLeft: "30px",
                          }}
                        >
                          {user?.user?.subscription?.name === "Free"
                            ? "Upgrade Now "
                            : "Renew Now"}
                        </SecondaryButton>
                      </div>
                    </Box>
                  )}
                  {(addonDuration || addonStorage) &&
                    user?.user?.trial === false && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          // justifyContent: "space-between",
                          justifyContent: "center",

                          backgroundColor: "#F5F6F7",
                          padding: "20px",
                          border: "1px solid #6D207B",
                          borderRadius: "20px",
                          flexDirection: { xs: "column", sm: "row" },
                          width: { xs: "100%", sm: "auto" },
                          gap: 2,
                        }}
                      >
                        <div
                          style={{
                            flex: 3,
                            textAlign: "center",
                            marginLeft: "20px",
                          }}
                        >
                          {addonDuration && !addonStorage && (
                            <p>
                              Your duration limit is exceeded. <br />
                              Upgrading to an add-on plan for extended duration
                              limits.
                            </p>
                          )}
                          {addonStorage && !addonDuration && (
                            <p>
                              Your storage limit is exceeded. <br />
                              Upgrading to an add-on plan for increased storage.
                            </p>
                          )}
                          {addonDuration && addonStorage && (
                            <p>
                              Both duration and storage limits are exceeded.{" "}
                              <br />
                              Upgrading to an add-on plan for additional
                              benefits.
                            </p>
                          )}
                        </div>
                        <div style={{ flex: 1, textAlign: "center" }}>
                          <SecondaryButton
                            onClick={() => handleAddonOpen()}
                            style={{
                              fontSize: "0.8rem",
                              padding: "10px",
                              width: "120px",
                              marginLeft: "30px",
                            }}
                          >
                            Add-on Plan
                          </SecondaryButton>
                        </div>
                      </Box>
                    )}
                </Grid>
              </Grid>
            </Container>

            <Container maxWidth={"xl"} sx={{ marginTop: { xs: 3, sm: 10 } }}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={6} md={6}>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          justifyContent: {
                            xs: "center",
                            sm: "start",
                          },
                        }}
                      >
                        <MainButton>Rooms</MainButton>
                        <SecondaryButton
                          sx={{
                            color: "#6D207B",
                          }}
                          onClick={() => navigate("/recordings")}
                        >
                          Recordings
                        </SecondaryButton>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        gap: 2,
                        alignItems: "center",
                        justifyContent: {
                          xs: "center",
                          sm: "center",
                          md: "end",
                        },
                      }}
                    >
                      {rooms?.length > 0 && (
                        <div>
                          <TextField
                            className="homeSearch"
                            label="Search"
                            variant="outlined"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            size="small"
                            InputProps={{
                              endAdornment: cancelIcon ? (
                                <InputAdornment position="end">
                                  <IconButton onClick={cancelSearch}>
                                    <CloseIcon fontSize="small" />
                                  </IconButton>
                                </InputAdornment>
                              ) : (
                                <InputAdornment position="end"></InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      )}

                      <Box>
                        {rooms?.length > 0 && (
                          <Tooltip
                            title={
                              user?.permission?.["RoomLimit"] <= rooms?.length
                                ? "Room limit reached. Cannot create more rooms."
                                : ""
                            }
                            arrow
                          >
                            <Box>
                              <MainButton
                                disabled={
                                  user?.permission?.["RoomLimit"] <=
                                  rooms?.length
                                    ? true
                                    : false
                                }
                                onClick={handleClickOpen}
                              >
                                <AddIcon
                                  fontSize="small"
                                  sx={{ marginRight: "10px" }}
                                />
                                New Room
                              </MainButton>
                            </Box>
                          </Tooltip>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {loadingPage ? (
                <LoaderHome />
              ) : rooms?.length ? (
                <>
                  <Box sx={{ flexGrow: 1, mt: 2, mb: 2, height: "100%" }}>
                    <Grid
                      container
                      spacing={2}
                      sx={{ display: "flex", alignItems: "stretch" }}
                    >
                      {filteredRooms.map((item, index) => (
                        <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                          <Card
                            onClick={() => {
                              if (item?.user?.expired) {
                                setModalOpen(true);
                              } else {
                                navigate(`/room/${item.friendly_id}`);
                              }
                            }}
                            sx={{
                              height: "100%",
                              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                              transition: "transform 0.2s, box-shadow 0.2s",
                              "&:hover": {
                                transform: "scale(1.03)",
                                boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.2)",
                                border: "1px solid #018ABE",
                              },
                              display: "flex",
                              flexDirection: "column",
                              cursor: "pointer",
                            }}
                          >
                            <div
                              style={{
                                // marginTop:"10px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                height: "150px",
                                overflow: "hidden",
                                
                              }}
                            >
                              <img
                                // src="http://localhost:7000/api/images/image-1731304195084.png"
                                src={item?.cover_image_url 
                                  ? `${process.env.REACT_APP_OVERRIDE_HOST}/api/images/${item.cover_image_url}` 
                                  : coverImage}
                                // src={item?.cover_image_url}
                                alt={"alt"}
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            </div>

                            <CardContent sx={{ flexGrow: 1 }}>
                              <Stack spacing={1}>
                                {item?.online && (
                                  <Box sx={{ display: "flex", gap: 2 }}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                      }}
                                    >
                                      <GroupIcon sx={{ fontSize: "1rem" }} />
                                      <Typography variant="body2">
                                        {item.participants}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                      }}
                                    >
                                      <CircleIcon
                                        sx={{
                                          color: "green",
                                          fontSize: "0.8rem",
                                        }}
                                      />
                                      <Typography variant="body2" color="green">
                                        online
                                      </Typography>
                                    </Box>
                                  </Box>
                                )}

                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="div"
                                  mt={2}
                                >
                                  {item.name}
                                </Typography>

                                {item?.shared_owner && (
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    shared by {item.user?.name}
                                  </Typography>
                                )}

                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                  mt={1}
                                >
                                  {item.last_session ||
                                    "No previous session created"}
                                </Typography>
                              </Stack>
                            </CardContent>

                            <CardActions
                              sx={{ justifyContent: "space-around", pb: 2 }}
                            >
                              <Tooltip
                                title={
                                  item?.user
                                    ? "Permission Denied"
                                    : "Delete Room"
                                }
                              >
                                <Button
                                  disabled={!!item.user}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteConirmationOpen(item.id);
                                  }}
                                  sx={{ padding: "10px 20px" }}
                                >
                                  <DeleteOutlineIcon
                                    color={item.user ? "disabled" : "error"}
                                  />
                                </Button>
                              </Tooltip>

                              <Tooltip title="Copy Room">
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleCopy(item.friendly_id);
                                  }}
                                  sx={{ padding: "10px 20px" }}
                                >
                                  <ContentCopyIcon color="primary" />
                                </Button>
                              </Tooltip>

                              <Tooltip
                                title={
                                  addonDuration ? "Duration Limit Exceeded" : ""
                                }
                              >
                                <span>
                                  <MainButton
                                    disabled={
                                      addonDuration || item.user?.expired
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleStartMeeting(item.friendly_id);
                                    }}
                                    sx={{ padding: "10px 50px" }}
                                  >
                                    {loading &&
                                      loadingRoom === item.friendly_id && (
                                        <CircularProgress
                                          size="1.2rem"
                                          sx={{ color: "white" }}
                                        />
                                      )}
                                    <Box ml={loading ? 2 : 0}>
                                      {item.online ? "Join" : "Start"}
                                    </Box>
                                  </MainButton>
                                </span>
                              </Tooltip>
                            </CardActions>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </>
              ) : (
                <Box
                  sx={{
                    marginTop: "20px",
                    background: "rgb(255, 255, 255)",
                    minHeight: "40vh",
                    borderRadius: "12px",
                    boxShadow:
                      "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                    transition:
                      "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  }}
                >
                  <div
                    style={{
                      height: "30vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: 1,
                      }}
                    >
                      <Avatar sx={{ width: 80, height: 80 }}>
                        <PersonalVideoIcon
                          sx={{ color: "primary.main", fontSize: 40 }}
                        />
                      </Avatar>

                      <h3
                        style={{ color: "primary.main", textAlign: "center" }}
                      >
                        You don't have any rooms yet!
                      </h3>

                      <p style={{ textAlign: "center" }}>
                        Create your first room by clicking on the button below
                        and entering a room name.
                      </p>
                      <MainButton onClick={handleClickOpen}>
                        <AddIcon
                          fontSize="small"
                          sx={{ marginRight: "10px" }}
                        />
                        New Room
                      </MainButton>
                    </Box>
                  </div>
                </Box>
              )}
            </Container>
          </>
        )}

        <NewRoom
          open={open}
          handleClose={handleClickClose}
          handleCreateRoom={handleCreateRoom}
        />
        <DeleteConfirmation
          open={deleteConfirm}
          handleClose={handleDeleteConirmationClose}
          handleConfirm={handleDeleteRoom}
        />
        <AddonModal
          open={addonplanopen}
          handleClose={handleCloseAddonPlan}
          addonDurationactive={addonDuration}
          addonStorageActive={addonStorage}
          session={session}
        />
        <LimitExceededModal
          open={limitModalOpen}
          handleClose={handleLimitModalClose}
          Duration={addonDuration}
          Storage={addonStorage}
        />
        <NotificationPopup
          open={modalOpen}
          handleClose={handleModalClose}
          heading={"Room Unavailable"}
          message={
            "The room is currently unavailable because the owner's plan has expired. Please contact the owner to renew their plan or upgrade to regain access."
          }
        />
      </div>
    </>
  );
}

export default Home;
