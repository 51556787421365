import React, { useEffect, useState } from "react";
// import Tilt from "src/components/JS/VanillaTilt";
// import CountUp from "src/components/JS/CountUp";
import Swiper from "swiper"; // Ensure you import Swiper styles and Swiper package
import "swiper/swiper-bundle.css";
// import CardSlider from "src/components/New components/CardSlider";
// import MainHeaderText from "src/components/New components/MainHeaderText";
import TagLabel from "src/components/New components/TagLabel";
import HeaderText from "src/components/New components/HeaderText";
import ParagraphText from "src/components/New components/ParagraphText";
import SplitSection from "src/components/New components/SplitSection";
import { aboutSection } from "src/Page_Content/Home";
// import FeatureSlider from "src/components/New components/FeatureSlider";
import ScheduleRoomSection from "../Online_Classes/ScheduleRoomSection";
//image
import StudentManagementImg from "src/assets/images/home/new/student-management.svg";
import Rubrics_generatorImg from "src/assets/images/home/new/rubrics-generator-with-ai.svg";
import automated_course_creation_img from "src/assets/images/home/new/automated-course-creation.svg";
import smart_quiz_creation_img from "src/assets/images/home/new/smart-quiz-creation.svg";
import HomeBannerImage from "src/assets/images/home/new/banner_home.webp";
import { blogContent } from "src/Page_Content/blogData";
import { Link, useNavigate } from "react-router-dom";
import apiClients from "src/apiClients/apiClients";
import { useDispatch } from "react-redux";
import { UseAuth } from "src/utils/UseAuth/UseAuth";
import { setUser } from "src/Redux/userSlice";
import { toast } from "react-toastify";
import RoleModal from "src/components/userProfile/modal/RoleModal";
function Home() {
  useEffect(() => {
    const swiperElement = document.querySelector(".swiper");
    if (swiperElement) {
      // Initialize main ecommerce slider
      const swiper = new Swiper(".ecommerce-slider", {
        slidesPerView: 1,
        grabCursor: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });

      // Initialize product details sliders
      const productDetailsSliders =
        document.querySelectorAll(".product-details");
      const productDetailsSliderThumbs = document.querySelectorAll(
        ".product-details-thumb"
      );

      if (productDetailsSliders.length) {
        productDetailsSliders.forEach((productDetailsSlider, idx) => {
          productDetailsSlider.classList.add(`product-details-${idx}`);
          productDetailsSliderThumbs[idx].classList.add(
            `product-details-thumb-${idx}`
          );

          const swiperThumbs = new Swiper(`.product-details-thumb-${idx}`, {
            spaceBetween: 10,
            slidesPerView: 5,
            freeMode: true,
            watchSlidesProgress: true,
          });

          const swiper2 = new Swiper(`.product-details-${idx}`, {
            spaceBetween: 10,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            thumbs: {
              swiper: swiperThumbs,
            },
          });
        });
      }

      // Initialize other sliders
      const swiperThumbs = new Swiper(".university__slider__thumb", {
        spaceBetween: 10,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true,
      });

      const swiperEcommerce2 = new Swiper(".ecommerce-slider2", {
        slidesPerView: 1,
        grabCursor: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        thumbs: {
          swiper: swiperThumbs,
        },
      });

      new Swiper(".card-slider", {
        effect: "cards",
        grabCursor: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });

      new Swiper(".featured-courses", {
        slidesPerView: 1,
        grabCursor: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
          1500: {
            slidesPerView: 4,
          },
        },
      });

      new Swiper(".other-courses", {
        slidesPerView: 1,
        spaceBetween: 30,
        grabCursor: true,
        loop: true,
        breakpoints: {
          500: {
            slidesPerView: 2,
          },
          576: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
        },
      });

      new Swiper(".featured-courses1", {
        slidesPerView: 1,
        grabCursor: true,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
        },
      });

      new Swiper(".mySwiper", {
        slidesPerView: 1,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
        },
      });

      new Swiper(".testimonial-2", {
        slidesPerView: 1,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    }

    // Cleanup function to destroy Swipers on unmount
    return () => {
      if (swiperElement) {
        swiperElement.swiper.destroy(true, true);
      }
    };
  }, []);

  // const { user } = useSelector((state) => state.user);
  const auth = UseAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const handleNavigate = useHandleNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const authValue = searchParams.get("auth");
  const message = searchParams.get("message");
  const [roleModalOpen, setRoleModalOpen] = useState(false);
  const [userId, setUserId] = useState("");
  useEffect(() => {
    if (auth.user) {
      dispatch(setUser(auth.user));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    if (authValue) {
      handleAuth(authValue);
    } else if (message) {
      toast.success(message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authValue, message]);

  const handleAuth = async (token) => {
    try {
      const response = await apiClients.verifyToken(token);
      if (response?.token) {
        if (response.data) {
          if (!response?.data?.role) {
            setUserId(response?.data?.id);
            setRoleModalOpen(true);
          } else {
            // dispatch(setUser(response.data));
            setRoleModalOpen(false);
            localStorage.setItem("user", JSON.stringify(response.data));
            localStorage.setItem(
              "access_token",
              JSON.stringify(response.token)
            );
            if (response?.data?.role?.name === "Administrator") {
              navigate("/organization/dashboard", { replace: true });
            } else {
              navigate("/room", { replace: true });
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="bg-transparent">
      {/* banner section */}
      <section>
        <div
          style={{
            backgroundImage: `url(${HomeBannerImage})`,
          }}
          className="hero machine-learning  bg-cover relative z-0 overflow-hidden py-50px "
        >
          {/* animated icons */}
          {/* <div>
            <img
              className="absolute left-10 bottom-[10%] animate-move-var opacity-35 z-10"
              src="./assets/images/herobanner/herobanner__ai.png"
              alt=""
            />
            <img
              className="absolute left-0 top-0 md:left-[50px] md:top-[110px] lg:left-[30px] lg:top-[75px] 2xl:left-[50px] 2xl:top-16 3xl:top-[110px] animate-spin-slow"
              src="./assets/images/herobanner/herobanner__2.png"
              alt=""
            />
          </div> */}
          {/* Swiper */}
          <div className="swiper ecommerce-slider">
            <div className="swiper-wrapper">
              {/* Hero  1 */}
              <div className="swiper-slide px-15px">
                <div className="container lg:max-w-770px xl:max-w-998px relative overflow-hidden">
                  <div data-aos="fade-up" className="text-center">
                    <div className="">
                      <h3 className="uppercase text-secondaryColor text-size-15 mb-5px md:mb-15px font-inter tracking-[4px] font-semibold">
                        Empowering Learning with Smart AI Solutions
                      </h3>
                      <h1 className="text-size-35 md:text-size-65 lg:text-5xl 2xl:text-size-65 leading-42px md:leading-18 lg:leading-15 2xl:leading-18 text-blackColor-dark md:tracking-half lg:tracking-normal 2xl:tracking-half font-bold mb-15px">
                        The Ultimate Platform for Engaging, Interactive
                        Learning.
                        {/* <span className="text-secondaryColor">AI</span> Systems. */}
                      </h1>
                      <p className="text-size-15 md:text-lg text-blackColor-dark font-medium">
                        Atlearn Is A Nurturing Holistic Approach To Learning
                        {/* <br />
                  industry. Lorem Ipsum has been */}
                      </p>
                      <div className="mt-30px">
                        <a
                          href="https://www.atlearn.in/lms-ai"
                          className="text-sm md:text-size-15 text-whiteColor bg-secondaryColor border border-secondaryColor px-25px py-15px hover:text-secondaryColor hover:bg-whiteColor rounded inline-block dark:hover:bg-whiteColor-dark dark:hover:text-secondaryColor"
                        >
                          Explore Courses
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Hero  2 */}
              <div className="swiper-slide px-15px">
                <div className="container lg:max-w-770px xl:max-w-998px relative overflow-hidden">
                  <div data-aos="fade-up" className="text-center">
                    <div className="">
                      <h3 className="uppercase text-secondaryColor text-size-15 mb-5px md:mb-15px font-inter tracking-[4px] font-semibold">
                        Join Our Online Classes
                      </h3>
                      <h1 className="text-size-35 md:text-size-65 lg:text-5xl 2xl:text-size-65 leading-42px md:leading-18 lg:leading-15 2xl:leading-18 text-blackColor-dark md:tracking-half lg:tracking-normal 2xl:tracking-half font-bold mb-15px">
                        Accessible Education at Your Fingertips
                      </h1>
                      <p className="text-size-15 md:text-lg text-blackColor-dark font-medium">
                        Transform Your Education with Engaging Online Classes
                      </p>
                      <div className="mt-30px">
                        <a
                          href="/room"
                          className="text-sm md:text-size-15 text-whiteColor bg-secondaryColor border border-secondaryColor px-25px py-15px hover:text-secondaryColor hover:bg-whiteColor rounded inline-block dark:hover:bg-whiteColor-dark dark:hover:text-secondaryColor"
                        >
                          Discover Online Classes
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Hero  1 */}
              {/* <div className="swiper-slide px-15px">
                <div className="container lg:max-w-770px xl:max-w-998px relative overflow-hidden">
                  <div data-aos="fade-up" className="text-center">
                    <div className="">
                      <h3 className="uppercase text-secondaryColor text-size-15 mb-5px md:mb-15px font-inter tracking-[4px] font-semibold">
                        Artificial Intelligence
                      </h3>
                      <h1 className="text-size-35 md:text-size-65 lg:text-5xl 2xl:text-size-65 leading-42px md:leading-18 lg:leading-15 2xl:leading-18 text-blackColor-dark md:tracking-half lg:tracking-normal 2xl:tracking-half font-bold mb-15px">
                        Awesome Machine Learning for Smart
                        <span className="text-secondaryColor">AI</span> Systems.
                      </h1>
                      <p className="text-size-15 md:text-lg text-blackColor-dark font-medium">
                        Lorem Ipsum is simply dummy text of the printing
                        typesetting <br />
                        industry. Lorem Ipsum has been
                      </p>
                      <div className="mt-30px">
                        <a
                          href="/"
                          className="text-sm md:text-size-15 text-whiteColor bg-primaryColor border border-primaryColor px-25px py-15px hover:text-primaryColor hover:bg-whiteColor rounded inline-block mr-6px md:mr-30px dark:hover:bg-whiteColor-dark dark:hover:text-whiteColor"
                        >
                          Buy Now
                        </a>
                        <a
                          href="/"
                          className="text-sm md:text-size-15 text-whiteColor bg-secondaryColor border border-secondaryColor px-25px py-15px hover:text-secondaryColor hover:bg-whiteColor rounded inline-block dark:hover:bg-whiteColor-dark dark:hover:text-secondaryColor"
                        >
                          Explore More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
            <span
              className="swiper-pagination-bullet"
              tabIndex={0}
              role="button"
              aria-label="Go to slide 1"
            />
            <span
              className="swiper-pagination-bullet swiper-pagination-bullet-active"
              tabIndex={0}
              role="button"
              aria-label="Go to slide 2"
              aria-current="true"
            />
            <span
              className="swiper-pagination-bullet"
              tabIndex={0}
              role="button"
              aria-label="Go to slide 3"
            />
          </div> */}
        </div>
      </section>

      {/* about  section */}

      <SplitSection data={aboutSection} />

      {/* Popular Subject Sectoion*/}
      <section className="bg-lightGrey10 dark:bg-lightGrey10-dark pt-50px pb-110px">
        <div className="container">
          {/* about section  */}
          <div className="grid grid-cols-1 lg:grid-cols-2 pt-30px gap-x-30px">
            {/* about left */}
            <div
              className="mb-30px lg:mb-0 pb-0 md:pb-30px xl:pb-0"
              data-aos="fade-up"
            >
              <div className="relative">
                <div>
                  <img
                    className="absolute bottom-9 lg:bottom-[-50px] right-[50px] animate-move-hor"
                    src="./assets/images/service/service__shape__1.png"
                    alt=""
                  />
                </div>
                <TagLabel text={"LMS AI FEATURES"} />
                <HeaderText>AI-Driven Learning Solutions</HeaderText>
                <ParagraphText
                  mainText={
                    "At Atlearn, we've integrated AI into our LMS to provide personalized learning experiences. This innovative approach tailors the learning journey to individual needs, offering smart recommendations and real-time insights for a more engaging and effective educational experience."
                  }
                  highlightedText={
                    "With AI at its core, our e learning platform is designed to optimize both teaching and learning outcomes, making education more accessible and tailored to each learner's unique path."
                  }
                />

                <div>
                  <a
                    className="text-size-15 text-whiteColor bg-secondaryColor px-25px py-10px border border-secondaryColor hover:text-secondaryColor hover:bg-whiteColor inline-block rounded dark:hover:bg-whiteColor-dark dark:hover:text-whiteColor"
                    href="https://lms.atlearn.in"
                  >
                    Explore More <i className="icofont-long-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
            {/* sbject right */}
            <div className="relative z-0 overflow-visible">
              {/* animted area */}
              <div data-aos="fade-up">
                <img
                  className="absolute sm:block xl:left-1/4 z-[-1] top-6 animate-move-var"
                  src="./assets/images/service/service__shape__bg__1.png"
                  alt=""
                />
              </div>
              {/* subject card */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-30px">
                {/* card 1 */}
                <div data-aos="fade-up">
                  <div className="p-35px group bg-whiteColor rounded-xl transition-all duration-300 shadow-dropdown-secodary hover:bg-primaryColor hover:-translate-y-5px hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor">
                    {/* card svg */}
                    <div className="-translate-y-2 flex justify-between overflow-hidden mb-10px">
                      <div className="relative">
                        <img
                          loading="lazy"
                          src={StudentManagementImg}
                          alt=""
                          // className="w-15 h-15"
                        />
                      </div>
                      <div className="service__small__img w-7 h-[60px]">
                        <svg
                          className="icon__hover__img w-7 h-[60px] opacity-0 group-hover:opacity-100"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z"
                            stroke="#FFB31F"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    {/* card content */}
                    <div>
                      <a
                        href="https://lms.atlearn.in/"
                        className="text-2xl lg:text-lg 2xl:text-2xl font-semibold mb-15px md:mb-0 2xl:mb-15px hover:text-secondaryColor font-hind  dark:text-whiteColor dark:hover:text-secondaryColor dark:transition-all dark:duration-300"
                      >
                        Student Management
                      </a>
                      <p className="text-sm lg:text-xs 2xl:text-sm text-contentColor group-hover:text-whiteColor mb-15px lg:mb-2 2xl:mb-15px transition-all duration-300">
                        Teachers, administrators, or students can enroll in
                        courses, with options for grouping based on class
                        divisions or differentiated tasks.
                      </p>
                      <div>
                        <div className="text-sm text-black dark:text-blackColor-darkColor group-hover:text-whiteColor dark:text-blackColor-dark dark:group-hover:text-whiteColor-dark">
                          <a
                            href="https://lms.atlearn.in/"
                            className="text-sm font-medium hover:text-secondaryColor transition-none"
                          >
                            Add Students
                            <i className="icofont-long-arrow-right" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* card 2 */}
                <div data-aos="fade-up">
                  <div className="md:translate-y-[30px]">
                    <div className="p-35px group bg-whiteColor rounded-xl transition-all duration-300 shadow-dropdown-secodary hover:bg-primaryColor hover:-translate-y-5px hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor ">
                      {/* card svg */}
                      <div className="-translate-y-2 flex justify-between overflow-hidden mb-10px">
                        <div className="relative">
                          <img
                            loading="lazy"
                            src={Rubrics_generatorImg}
                            alt=""
                            className="w-15 h-15"
                          />
                        </div>
                        <div className="service__small__img w-7 h-[60px]">
                          <svg
                            className="icon__hover__img w-7 h-[60px] opacity-0 group-hover:opacity-100"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z"
                              stroke="#FFB31F"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                      {/* card content */}
                      <div>
                        <a
                          href="https://lms.atlearn.in/"
                          className="text-2xl lg:text-lg 2xl:text-2xl font-semibold mb-15px md:mb-0 2xl:mb-15px hover:text-secondaryColor font-hind  dark:text-whiteColor dark:hover:text-secondaryColor dark:transition-all dark:duration-300"
                        >
                          AI-Powered Rubric Generator
                        </a>
                        <p className="text-sm lg:text-xs 2xl:text-sm text-contentColor group-hover:text-whiteColor mb-15px lg:mb-2 2xl:mb-15px transition-all duration-300">
                          Create detailed rubrics to streamline grading and
                          provide clear expectations for student assignments.
                        </p>
                        <div>
                          <div className="text-sm text-black dark:text-blackColor-darkColor group-hover:text-whiteColor dark:text-blackColor-dark dark:group-hover:text-whiteColor-dark">
                            <a
                              href="https://lms.atlearn.in/"
                              className="text-sm font-medium hover:text-secondaryColor transition-all duration-300"
                            >
                              Create Rubrics
                              <i className="icofont-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* card 3 */}
                <div data-aos="fade-up">
                  <div className="p-35px group bg-whiteColor rounded-xl transition-all duration-300 shadow-dropdown-secodary hover:bg-primaryColor hover:-translate-y-5px hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor">
                    {/* card svg */}
                    <div className="-translate-y-2 flex justify-between overflow-hidden mb-10px">
                      <div className="relative">
                        <img
                          loading="lazy"
                          src={automated_course_creation_img}
                          alt=""
                          className="w-15 h-15"
                        />
                      </div>
                      <div className="service__small__img w-7 h-[60px]">
                        <svg
                          className="icon__hover__img w-7 h-[60px] opacity-0 group-hover:opacity-100"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z"
                            stroke="#FFB31F"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    {/* card content */}
                    <div>
                      <a
                        href="https://lms.atlearn.in/"
                        className="text-2xl lg:text-lg 2xl:text-2xl font-semibold mb-15px md:mb-0 2xl:mb-15px hover:text-secondaryColor font-hind  dark:text-whiteColor dark:hover:text-secondaryColor dark:transition-all dark:duration-300"
                      >
                        Automated Course Creation
                      </a>
                      <p className="text-sm lg:text-xs 2xl:text-sm text-contentColor group-hover:text-whiteColor mb-15px lg:mb-2 2xl:mb-15px transition-all duration-300">
                        Quickly generate customizable quizzes to assess student
                        knowledge and comprehension.
                      </p>
                      <div>
                        <div className="text-sm text-black dark:text-blackColor-darkColor group-hover:text-whiteColor dark:text-blackColor-dark dark:group-hover:text-whiteColor-dark">
                          <a
                            href="https://lms.atlearn.in/"
                            className="text-sm font-medium hover:text-secondaryColor transition-none"
                          >
                            Create Course
                            <i className="icofont-long-arrow-right" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* card 4 */}
                <div data-aos="fade-up">
                  <div className="md:translate-y-[30px]">
                    <div className="p-35px group bg-whiteColor rounded-xl transition-all duration-300 shadow-dropdown-secodary hover:bg-primaryColor hover:-translate-y-5px hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor ">
                      {/* card svg */}
                      <div className="-translate-y-2 flex justify-between overflow-hidden mb-10px">
                        <div className="relative">
                          <img
                            loading="lazy"
                            src={smart_quiz_creation_img}
                            alt=""
                            className="w-15 h-15"
                          />
                        </div>
                        <div className="service__small__img w-7 h-[60px]">
                          <svg
                            className="icon__hover__img w-7 h-[60px] opacity-0 group-hover:opacity-100"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z"
                              stroke="#FFB31F"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                      {/* card content */}
                      <div>
                        <a
                          href="https://lms.atlearn.in/"
                          className="text-2xl lg:text-lg 2xl:text-2xl font-semibold mb-15px md:mb-0 2xl:mb-15px hover:text-secondaryColor font-hind  dark:text-whiteColor dark:hover:text-secondaryColor dark:transition-all dark:duration-300"
                        >
                          Smart Quiz Creation
                        </a>
                        <p className="text-sm lg:text-xs 2xl:text-sm text-contentColor group-hover:text-whiteColor mb-15px lg:mb-2 2xl:mb-15px transition-all duration-300">
                          Simplify the process of developing comprehensive
                          courses tailored to specific learning objectives.
                        </p>
                        <div>
                          <div className="text-sm text-black dark:text-blackColor-darkColor group-hover:text-whiteColor dark:text-blackColor-dark dark:group-hover:text-whiteColor-dark">
                            <a
                              href="https://lms.atlearn.in/"
                              className="text-sm font-medium hover:text-secondaryColor transition-all duration-300"
                            >
                              Start Quiz
                              <i className="icofont-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  counter section */}
      {/* <div>
          <div className="container-fluid-2">
           
            <div className="counter flex flex-wrap pb-50px pt-20 md:pb-70px lg:pb-20 2xl:pb-100px gap-y-30px lg:gap-y-0">
              <div
                className="basis-full sm:basis-1/2 lg:basis-1/4"
                data-aos="fade-up"
              >
                <div className="flex gap-4">
                  <div>
                    <img src="./assets/images/counter/counter__1.png" alt="" />
                  </div>
                  <div>
                    <p className="text-size-34 leading-[1.1] text-blackColor font-bold font-hind dark:text-blackColor-dark">
                      <span data-countup-number={27}> 27</span>
                      <span>+</span>
                    </p>
                    <p className="uppercase text-blackColor font-medium leading-[18px] dark:text-blackColor-dark">
                      Total Acheivment
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="basis-full sm:basis-1/2 lg:basis-1/4"
                data-aos="fade-up"
              >
                <div className="flex gap-4">
                  <div>
                    <img src="./assets/images/counter/counter__2.png" alt="" />
                  </div>
                  <div>
                    <p className="text-size-34 leading-[1.1] text-blackColor font-bold font-hind dark:text-blackColor-dark">
                      <CountUp endValue={145} />

                      <span>+</span>
                    </p>
                    <p className="uppercase text-blackColor font-medium leading-[18px] dark:text-blackColor-dark">
                      TOTAL STUDENTS
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="basis-full sm:basis-1/2 lg:basis-1/4"
                data-aos="fade-up"
              >
                <div className="flex gap-4">
                  <div>
                    <img src="./assets/images/counter/counter__3.png" alt="" />
                  </div>
                  <div>
                    <p className="text-size-34 leading-[1.1] text-blackColor font-bold font-hind dark:text-blackColor-dark">
                      <span data-countup-number={10}>10</span>
                      <span>k</span>
                    </p>
                    <p className="uppercase text-blackColor font-medium leading-[18px] dark:text-blackColor-dark">
                      tOTAL INSTRUCTOR
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="basis-full sm:basis-1/2 lg:basis-1/4"
                data-aos="fade-up"
              >
                <div className="flex gap-4">
                  <div>
                    <img src="./assets/images/counter/counter__4.png" alt="" />
                  </div>
                  <div>
                    <p className="text-size-34 leading-[1.1] text-blackColor font-bold font-hind dark:text-blackColor-dark">
                      <span data-countup-number={214}>214</span>
                      <span>+</span>
                    </p>
                    <p className="uppercase text-blackColor font-medium leading-[18px] dark:text-blackColor-dark">
                      OVER THE WORLD
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

      {/* program  section */}
      <section>
        <div className="container-fluid-2 pb-100px">
          {/* program Heading */}
          <div className="mb-5 md:mb-10 mt-5" data-aos="fade-up">
            <h3 className="text-3xl md:text-[35px] lg:text-size-38 3xl:text-size-42 leading-10 mf:leading-45px 2xl:leading-50px 3xl:leading-2xl font-bold text-blackColor dark:text-blackColor-dark text-center">
              Explore Our Courses
            </h3>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-30px text-center">
            <div className="shadow-dropdown-secodary" data-aos="fade-up">
              <div className="p-10px">
                <img
                  src="./assets/images/program/ai_1.jpg"
                  alt=""
                  className="w-full"
                />
              </div>
              <div className="pt-30px pr-5 pb-10 pl-30px md:py-5 md:px-10px lg:pt-30px lg:pr-10px lg:pb-10 lg:pl-5 2xl:pt-30px 2xl:pr-5 2xl:pb-10 2xl:pl-30px">
                <p className="text-primaryColor font-bold uppercase mb-15px">
                  TECHNOLOGY
                </p>
                <h4 className="text-2xl md:text-size-34 lg:text-2xl 2xl:text-xl 3xl:text-size-34 font-bold">
                  <Link
                    to="/course-details"
                    className="leading-1.2 text-blackColor hover:text-primaryColor dark:text-blackColor-dark dark:hover:text-primaryColor mb-5"
                  >
                    Army Defence Intelligence &amp; Ml
                  </Link>
                </h4>
                <Link
                  to="/course-details"
                  className="font-medium text-blackColor hover:text-primaryColor dark:text-blackColor-dark dark:hover:text-primaryColor"
                >
                  Read More
                </Link>
              </div>
            </div>
            <div className="shadow-dropdown-secodary" data-aos="fade-up">
              <div className="p-10px">
                <img
                  src="./assets/images/program/ai_2.jpg"
                  alt=""
                  className="w-full"
                />
              </div>
              <div className="pt-30px pr-5 pb-10 pl-30px md:py-5 md:px-10px lg:pt-30px lg:pr-10px lg:pb-10 lg:pl-5 2xl:pt-30px 2xl:pr-5 2xl:pb-10 2xl:pl-30px">
                <p className="text-primaryColor font-bold uppercase mb-15px">
                  MACHINE
                </p>
                <h4 className="text-2xl md:text-size-34 lg:text-2xl 2xl:text-xl 3xl:text-size-34 font-bold">
                  <Link
                    to="/course-details"
                    className="leading-1.2 text-blackColor hover:text-primaryColor dark:text-blackColor-dark dark:hover:text-primaryColor mb-5"
                  >
                    Classifying Best Intelligence Photos
                  </Link>
                </h4>
                <Link
                  to="/course-details"
                  className="font-medium text-blackColor hover:text-primaryColor dark:text-blackColor-dark dark:hover:text-primaryColor"
                >
                  Read More
                </Link>
              </div>
            </div>
            <div className="shadow-dropdown-secodary" data-aos="fade-up">
              <div className="p-10px">
                <img
                  src="./assets/images/program/ai_3.jpg"
                  alt=""
                  className="w-full"
                />
              </div>
              <div className="pt-30px pr-5 pb-10 pl-30px md:py-5 md:px-10px lg:pt-30px lg:pr-10px lg:pb-10 lg:pl-5 2xl:pt-30px 2xl:pr-5 2xl:pb-10 2xl:pl-30px">
                <p className="text-primaryColor font-bold uppercase mb-15px">
                  ROBOT
                </p>
                <h4 className="text-2xl md:text-size-34 lg:text-2xl 2xl:text-xl 3xl:text-size-34 font-bold">
                  <Link
                    to="/course-details"
                    className="leading-1.2 text-blackColor hover:text-primaryColor dark:text-blackColor-dark dark:hover:text-primaryColor mb-5"
                  >
                    Industry Labor Machine
                  </Link>
                </h4>
                <Link
                  to="/course-details"
                  className="font-medium text-blackColor hover:text-primaryColor dark:text-blackColor-dark dark:hover:text-primaryColor"
                >
                  Read More
                </Link>
              </div>
            </div>
            <div className="shadow-dropdown-secodary" data-aos="fade-up">
              <div className="p-10px">
                <img
                  src="./assets/images/program/ai_4.jpg"
                  alt=""
                  className="w-full"
                />
              </div>
              <div className="pt-30px pr-5 pb-10 pl-30px md:py-5 md:px-10px lg:pt-30px lg:pr-10px lg:pb-10 lg:pl-5 2xl:pt-30px 2xl:pr-5 2xl:pb-10 2xl:pl-30px">
                <p className="text-primaryColor font-bold uppercase mb-15px">
                  AUTOMATION
                </p>
                <h4 className="text-2xl md:text-size-34 lg:text-2xl 2xl:text-xl 3xl:text-size-34 font-bold">
                  <Link
                    to="/course-details"
                    className="leading-1.2 text-blackColor hover:text-primaryColor dark:text-blackColor-dark dark:hover:text-primaryColor mb-5"
                  >
                    Education Super Al Studies
                  </Link>
                </h4>
                <Link
                  to="/course-details"
                  className="font-medium text-blackColor hover:text-primaryColor dark:text-blackColor-dark dark:hover:text-primaryColor"
                >
                  Read More
                </Link>
              </div>
            </div>
          </div>
          <div className="flex justify-center" data-aos="fade-up">
            <Link
              to={"/all-courses"}
              className="text-size-15 px-47px py-15px bg-primaryColor text-whiteColor border border-primaryColor hover:text-primaryColor hover:bg-whiteColor dark:hover:bg-whiteColor-dark dark:hover:text-whiteColor mt-10 md:mt-50px rounded uppercase"
            >
              More Courses
            </Link>
          </div>
        </div>
      </section>

      {/* program  section */}

      <ScheduleRoomSection />

      <section>
        <div className="bg-lightGrey10 dark:bg-lightGrey10-dark relative z-0 overflow-hidden">
          <div className="container py-50px md:py-70px lg:py-20 2xl:pt-145px 2xl:pb-154px">
            <div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-30px lg:gap-0">
              {/* testimonial Left */}
              <div data-aos="fade-up">
                <h3 className="uppercase text-secondaryColor text-size-15 mb-5px md:mb-15px font-inter tracking-[4px] font-semibold">
                  EDUCATION SOLUTION
                </h3>
                <h1 className="text-3xl text-blackColor md:text-size-35 lg:text-size-42 2xl:text-size-47 leading-10 md:leading-45px lg:leading-12 2xl:leading-50px dark:text-blackColor-dark font-bold mb-15px">
                  What Our Clients Say About Our LMS Solutions
                </h1>
                {/* Swiper */}
                <div className="swiper testimonial-2">
                  <div className="swiper-wrapper">
                    {/* testimonial 1 */}
                    <div className="swiper-slide">
                      <div>
                        <p className="md:text-xl md:leading-10 text-lightGrey dark:text-whiteColor">
                          <i className="icofont-quote-left quote__left text-primaryColor text-xl" />
                          The other hand we denounce with righteou indg ation
                          and dislike men who are so beguiled and demorali ed by
                          the of pleasure of the moment.Dislike men who are so
                          beguiled and demoraliz worlds ed by the charms of
                          pleasure of the moment. Lorem ipsum dolor sit amet.
                          <i className="icofont-quote-right quote__right text-primaryColor text-xl" />
                        </p>
                        <div className="flex items-center pt-10">
                          <div className="pr-18px">
                            <img
                              src="./assets/images/testimonial/testi_2.png"
                              alt=""
                              className="w-18 h-78 rounded-full"
                            />
                          </div>
                          <div>
                            <h4>
                              <a
                                href="/"
                                className="text-size-22 font-semibold text-contentColor hover:text-primaryColor dark:hover:text-primaryColor dark:text-contentColor-dark"
                              >
                                Mirnsdo Jons
                              </a>
                            </h4>
                            <a href="/" className="text-primaryColor">
                              Ui/Ux Designer
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* testimonial 2 */}
                    <div className="swiper-slide">
                      <div>
                        <p className="md:text-xl md:leading-10 text-lightGrey dark:text-whiteColor">
                          <i className="icofont-quote-left quote__left text-primaryColor text-xl" />
                          The other hand we denounce with righteou indg ation
                          and dislike men who are so beguiled and demorali ed by
                          the of pleasure of the moment.Dislike men who are so
                          beguiled and demoraliz worlds ed by the charms of
                          pleasure of the moment. Lorem ipsum dolor sit amet.
                          <i className="icofont-quote-right quote__right text-primaryColor text-xl" />
                        </p>
                        <div className="flex items-center pt-10">
                          <div className="pr-18px">
                            <img
                              src="./assets/images/testimonial/testi_2.png"
                              alt=""
                              className="w-18 h-78 rounded-full"
                            />
                          </div>
                          <div>
                            <h4>
                              <a
                                href="/"
                                className="text-size-22 font-semibold text-contentColor hover:text-primaryColor dark:hover:text-primaryColor dark:text-contentColor-dark"
                              >
                                Mirnsdo Jons
                              </a>
                            </h4>
                            <a href="/" className="text-primaryColor">
                              Ui/Ux Designer
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* testimonial 3 */}
                    <div className="swiper-slide">
                      <div>
                        <p className="md:text-xl md:leading-10 text-lightGrey dark:text-whiteColor">
                          <i className="icofont-quote-left quote__left text-primaryColor text-xl" />
                          The other hand we denounce with righteou indg ation
                          and dislike men who are so beguiled and demorali ed by
                          the of pleasure of the moment.Dislike men who are so
                          beguiled and demoraliz worlds ed by the charms of
                          pleasure of the moment. Lorem ipsum dolor sit amet.
                          <i className="icofont-quote-right quote__right text-primaryColor text-xl" />
                        </p>
                        <div className="flex items-center pt-10">
                          <div className="pr-18px">
                            <img
                              src="./assets/images/testimonial/testi_2.png"
                              alt=""
                              className="w-18 h-78 rounded-full"
                            />
                          </div>
                          <div>
                            <h4>
                              <a
                                href="/"
                                className="text-size-22 font-semibold text-contentColor hover:text-primaryColor dark:hover:text-primaryColor dark:text-contentColor-dark"
                              >
                                Mirnsdo Jons
                              </a>
                            </h4>
                            <a href="/" className="text-primaryColor">
                              Ui/Ux Designer
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* slide controller */}
                  {/* <div className="absolute bottom-0 right-0 flex flex-row-reverse gap-0.5">
                    <div className="static swiper-button-next w-15 h-15 bg-whiteColor text-blackColor transition-all duration-300 text-3xl hover:bg-primaryColor hover:text-whiteColor dark:hover:bg-secondaryColor after:hidden before:hidden">
                      <i className="icofont-long-arrow-right active" />
                    </div>
                    <div className="static swiper-button-prev w-15 h-15 transition-all duration-300 bg-whiteColor text-blackColor text-3xl hover:bg-primaryColor hover:text-whiteColor dark:hover:bg-secondaryColor after:hidden before:hidden">
                      <i className="icofont-long-arrow-left" />
                    </div>
                  </div> */}
                </div>
              </div>
              {/* testimonial right */}
              <div data-aos="fade-up">
                <div className="tilt pl-0 md:pl-70px">
                  <img
                    className="w-full"
                    src="./assets/images/testimonial/testi__group__1.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* news and blogs section */}
      <section>
        <div className="container py-10 md:py-50px lg:py-60px 2xl:py-100px">
          {/* Heading */}
          <div data-aos="fade-up" className="text-center mb-15px">
            <span className="text-size-15 font-semibold text-secondaryColor inline-block uppercase mb-[13px]">
              Educational BLOG
            </span>
            <h3 className="text-3xl md:text-size-35 lg:text-size-45 leading-10 md:leading-2xl font-bold text-blackColor dark:text-blackColor-dark">
              Latest Blogs
            </h3>
          </div>

          {/* Blog Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-30px">
            {blogContent
              ?.slice(-3)
              ?.reverse()
              ?.map((section, index) => (
                <div
                  key={index}
                  data-aos="fade-up"
                  className="shadow-dropdown-secodary bg-transparent flex flex-col justify-between h-full"
                >
                  <div className="relative group p-10px">
                    <img src={section?.image} alt="" className="w-full" />
                  </div>

                  <div className="flex flex-col justify-between h-full pt-30px pr-5 pb-10 pl-30px md:py-5 md:px-10 lg:pt-30px lg:pr-10px lg:pb-10 lg:pl-5 2xl:pt-30px 2xl:pr-5 2xl:pb-10 2xl:pl-30px">
                    <p className="text-base text-primaryColor uppercase font-bold mb-15px">
                      {section?.date}
                    </p>
                    <h5 className="mb-auto">
                      <Link
                        to={`/blog-details?name=${section?.heading}`}
                        className="text-2xl md:text-xl 2xl:text-2xl leading-8 md:leading-7 2xl:leading-8 font-bold text-blackColor dark:text-blackColor-dark hover:text-secondaryColor dark:hover:text-primaryColor"
                      >
                        {section?.heading}
                      </Link>
                    </h5>

                    {/* Read More Link at the Bottom */}
                    <Link
                      to={`/blog-details?name=${section?.heading}`}
                      className="text-blackColor hover:text-secondaryColor dark:text-blackColor-dark dark:hover:text-primaryColor mt-5"
                    >
                      Read More <i className="icofont-long-arrow-right" />
                    </Link>
                  </div>
                </div>
              ))}
          </div>

          {/* More Blog Button */}
          <div className="flex justify-center" data-aos="fade-up">
            <Link
              to={"/blog"}
              className="text-size-15 px-47px py-15px bg-primaryColor text-whiteColor border border-primaryColor hover:text-primaryColor hover:bg-whiteColor dark:hover:bg-whiteColor-dark dark:hover:text-whiteColor mt-10 md:mt-50px rounded uppercase"
            >
              Read More
            </Link>
          </div>
        </div>
      </section>
      <RoleModal
        open={roleModalOpen}
        handleClose={() => setRoleModalOpen(false)}
        userId={userId}
      />
    </div>
  );
}

export default Home;
