import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import PrimaryButton from "src/components/New components/PrimaryButton";
import { blogContent } from "src/Page_Content/blogData";
import { setSearchQuery } from "src/Redux/searchBlogSlice";

function BlogLayout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { searchQuery, searchResults, loading, error } = useSelector(
    (state) => state.searchBlog
  );
  return (
    <div>
      {/* banner section */}
      <section>
        {/* banner section */}
        <div className="bg-lightGrey10 dark:bg-lightGrey10-dark relative z-0 overflow-y-visible py-50px md:py-20 lg:py-100px 2xl:pb-150px 2xl:pt-40.5">
          {/* animated icons */}
          <div>
            <img
              className="absolute left-0 bottom-0 md:left-[14px] lg:left-[50px] lg:bottom-[21px] 2xl:left-[165px] 2xl:bottom-[60px] animate-move-var z-10"
              src="/assets/images/herobanner/herobanner__1.png"
              alt=""
            />
            <img
              className="absolute left-0 top-0 lg:left-[50px] lg:top-[100px] animate-spin-slow"
              src="/assets/images/herobanner/herobanner__2.png"
              alt=""
            />
            <img
              className="absolute right-[30px] top-0 md:right-10 lg:right-[575px] 2xl:top-20 animate-move-var2 opacity-50 hidden md:block"
              src="/assets/images/herobanner/herobanner__3.png"
              alt=""
            />
            <img
              className="absolute right-[30px] top-[212px] md:right-10 md:top-[157px] lg:right-[45px] lg:top-[100px] animate-move-hor"
              src="/assets/images/herobanner/herobanner__5.png"
              alt=""
            />
          </div>
          <div className="container">
            <div className="text-center">
              <h1 className="text-3xl md:text-size-40 2xl:text-size-55 font-bold text-blackColor dark:text-blackColor-dark mb-7 md:mb-6 pt-3">
                Blog
              </h1>
              <p className="text-size-15md:text-lg text-blackColor dark:text-blackColor-dark font-medium mb-10px">
                Discover Insights and Trends Shaping the Future of Learning and
                Education
              </p>
              <PrimaryButton onClick={() => navigate("/blog")} primary={true}>
                Explore Now
              </PrimaryButton>
            </div>
          </div>
        </div>
      </section>

      {/* News and blog section */}
      <section>
        <div className="container py-10 md:py-50px lg:py-60px 2xl:py-100px">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-30px">
            {/* blogs */}
            <div className="lg:col-start-1 lg:col-span-8 space-y-[35px]">
              {/* blog 1 */}
              <Outlet />
            </div>
            {/* blog sidebar */}
            <div className="lg:col-start-9 lg:col-span-4">
              <div className="flex flex-col">
                {/* search input */}
                <div
                  className="p-5 md:p-30px lg:p-5 2xl:p-30px mb-30px border border-borderColor2 dark:border-borderColor2-dark"
                  data-aos="fade-up"
                >
                  <h4 className="text-size-22 text-blackColor dark:text-blackColor-dark font-bold pl-2 before:w-0.5 relative before:h-[21px] before:bg-primaryColor before:absolute before:bottom-[5px] before:left-0 leading-30px mb-25px">
                    Search here
                  </h4>
                  <form className="w-full px-4 py-15px text-sm text-contentColor bg-lightGrey10 dark:bg-lightGrey10-dark dark:text-contentColor-dark flex justify-center items-center leading-26px">
                    <input
                      type="text"
                      placeholder="Search Blog"
                      value={searchQuery}
                      onChange={(e) => dispatch(setSearchQuery(e.target.value))}
                      className="placeholder:text-placeholder bg-transparent focus:outline-none placeholder:opacity-80 w-full"
                    />
                    <button type="submit">
                      <i className="icofont-search-1 text-base" />
                    </button>
                  </form>
                </div>
                {/*  Recent Post */}
                <div
                  className="p-5 md:p-30px lg:p-5 2xl:p-30px mb-30px border border-borderColor2 dark:border-borderColor2-dark"
                  data-aos="fade-up"
                >
                  <h4 className="text-size-22 text-blackColor dark:text-blackColor-dark font-bold pl-2 before:w-0.5 relative before:h-[21px] before:bg-primaryColor before:absolute before:bottom-[5px] before:left-0 leading-30px mb-25px">
                    Recent Blogs
                  </h4>
                  <ul className="flex flex-col gap-y-25px">
                    {blogContent
                      ?.slice()
                      .reverse()
                      .map((item, index) => (
                        <li className="flex items-center">
                          <div
                            onClick={() =>
                              navigate(`/blog-details?name=${item?.heading}`)
                            }
                            className="w-full cursor-pointer"
                          >
                            <h3 className="font-bold leading-22px mb-15px">
                              <span className="text-blackColor dark:text-blackColor-dark hover:text-primaryColor dark:hover:text-primaryColor">
                                {item?.heading}
                              </span>
                            </h3>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>

                {/* social area */}
                <div
                  className="p-5 md:p-30px lg:p-5 2xl:p-30px mb-30px border border-borderColor2 dark:border-borderColor2-dark"
                  data-aos="fade-up"
                >
                  <h4 className="text-size-22 text-blackColor dark:text-blackColor-dark font-bold pl-2 before:w-0.5 relative before:h-[21px] before:bg-primaryColor before:absolute before:bottom-[5px] before:left-0 leading-30px mb-25px">
                    Follow Us
                  </h4>
                  <div>
                    <ul className="flex gap-10px items-center">
                      <li>
                        <a
                          href="https://www.facebook.com/Atlearn.in"
                          className="w-38px h-38px leading-38px text-center text-blackColor2 bg-whitegrey2 hover:text-whiteColor hover:bg-primaryColor dark:bg-whitegrey2-dark dark:text-blackColor2-dark dark:hover:text-whiteColor dark:hover:bg-primaryColor rounded"
                        >
                          <i className="icofont-facebook" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/@Atlearn_In/featured"
                          className="w-38px h-38px leading-38px text-center text-blackColor2 bg-whitegrey2 hover:text-whiteColor hover:bg-primaryColor dark:bg-whitegrey2-dark dark:text-blackColor2-dark dark:hover:text-whiteColor dark:hover:bg-primaryColor rounded"
                        >
                          <i className="icofont-youtube-play" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.facebook.com/Atlearn.in"
                          className="w-38px h-38px leading-38px text-center text-blackColor2 bg-whitegrey2 hover:text-whiteColor hover:bg-primaryColor dark:bg-whitegrey2-dark dark:text-blackColor2-dark dark:hover:text-whiteColor dark:hover:bg-primaryColor rounded"
                        >
                          <i className="icofont-linkedin" />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.instagram.com"
                          className="w-38px h-38px leading-38px text-center text-blackColor2 bg-whitegrey2 hover:text-whiteColor hover:bg-primaryColor dark:bg-whitegrey2-dark dark:text-blackColor2-dark dark:hover:text-whiteColor dark:hover:bg-primaryColor rounded"
                        >
                          <i className="icofont-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BlogLayout;
