import React from 'react'

function ApplyAsInstructor() {
  return (
    <div>
     
  {/* banner section */}
  <section>
    {/* banner section */}
    <div className="bg-lightGrey10 dark:bg-lightGrey10-dark relative z-0 overflow-y-visible py-50px md:py-20 lg:py-100px 2xl:pb-150px 2xl:pt-40.5">
      {/* animated icons */}
      <div>
        <img
          className="absolute left-0 bottom-0 md:left-[14px] lg:left-[50px] lg:bottom-[21px] 2xl:left-[165px] 2xl:bottom-[60px] animate-move-var z-10"
          src="../../assets/images/herobanner/herobanner__1.png"
          alt=""
        />
        <img
          className="absolute left-0 top-0 lg:left-[50px] lg:top-[100px] animate-spin-slow"
          src="../../assets/images/herobanner/herobanner__2.png"
          alt=""
        />
        <img
          className="absolute right-[30px] top-0 md:right-10 lg:right-[575px] 2xl:top-20 animate-move-var2 opacity-50 hidden md:block"
          src="../../assets/images/herobanner/herobanner__3.png"
          alt=""
        />
        <img
          className="absolute right-[30px] top-[212px] md:right-10 md:top-[157px] lg:right-[45px] lg:top-[100px] animate-move-hor"
          src="../../assets/images/herobanner/herobanner__5.png"
          alt=""
        />
      </div>
      <div className="container">
        <div className="text-center">
          <h1 className="text-3xl md:text-size-40 2xl:text-size-55 font-bold text-blackColor dark:text-blackColor-dark mb-7 md:mb-6 pt-3">
            Become An Instructor
          </h1>
          <ul className="flex gap-1 justify-center">
            <li>
              <a
                href="../../index.html"
                className="text-lg text-blackColor2 dark:text-blackColor2-dark"
              >
                Home <i className="icofont-simple-right" />
              </a>
            </li>
            <li>
              <span className="text-lg text-blackColor2 dark:text-blackColor2-dark">
                Become An Instructor
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
 
  {/*apply  section */}
  <section>
    <div className="container pt-100px pb-100px" data-aos="fade-up">
      <h3 className="text-size-32 font-bold text-blackColor dark:text-blackColor-dark leading-1.2 pb-15px border-b border-borderColor dark:border-borderColor-dark mb-10">
        Apply As Instructor
      </h3>
      <p className="text-contentColor dark:text-contentColor-dark leading-1.8 mb-15px mt-5">
      Join Atlearn’s community of educators and make a difference in online learning. By applying as an instructor, you can bring your expertise to students worldwide, teaching courses tailored to your strengths and interests. Our application process is simple and designed to welcome skilled tutors across a wide range of subjects. Start sharing your knowledge, inspiring students, and becoming part of a dynamic educational platform.
          </p>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-30px">
        {/* apply left */}
        <div data-aos="fade-up">
          <h6 className="text-2xl font-semibold text-blackColor dark:text-blackColor-dark leading-1.8 mb-15px">
            Become an Instructor
          </h6>
          <p className="text-contentColor dark:text-contentColor-dark leading-1.8 mb-15px mt-5">
          Becoming an instructor with Atlearn means more than just teaching—it’s about fostering growth and engagement in a supportive environment. With our teaching tools for virtual classroom, you’ll create interactive lessons, engage with students in real-time, and track their progress. We make it easy for instructors to succeed by offering resources, training, and a seamless platform to deliver effective and impactful online education.
          </p>
          <h6 className="text-2xl font-semibold text-blackColor dark:text-blackColor-dark leading-1.8 mb-15px">
          Instructor Guidelines
          </h6>
          <p className="text-contentColor dark:text-contentColor-dark leading-1.8 mb-15px mt-5">
          To maintain a high-quality learning experience for all, our instructors adhere to a few core guidelines:
          </p>
          {/* rules list */}
          <ul className="mb-30px space">
            <li className="mt-5 flex items-center gap-5">
            <i className="icofont-check px-2 py-2 text-primaryColor bg-whitegrey3 bg-opacity-40 group-hover:bg-primaryColor group-hover:text-white group-hover:opacity-100 mr-15px dark:bg-whitegrey1-dark" />
              <p className="text-contentColor dark:text-contentColor-dark leading-1.8">
              Content Quality: Provide accurate, engaging, and comprehensive lessons.
              </p>
            </li>
            <li className="mt-5 flex items-center gap-5">
            <i className="icofont-check px-2 py-2 text-primaryColor bg-whitegrey3 bg-opacity-40 group-hover:bg-primaryColor group-hover:text-white group-hover:opacity-100 mr-15px dark:bg-whitegrey1-dark" />
              <p className="text-contentColor dark:text-contentColor-dark leading-1.8">
              dent Engagement: Actively engage with students, answer questions, and foster a collaborative environment.
              </p>
            </li>
            <li className="mt-5 flex items-center gap-5">
            <i className="icofont-check px-2 py-2 text-primaryColor bg-whitegrey3 bg-opacity-40 group-hover:bg-primaryColor group-hover:text-white group-hover:opacity-100 mr-15px dark:bg-whitegrey1-dark" />
              <p className="text-contentColor dark:text-contentColor-dark leading-1.8">
              Professionalism: Uphold respectful communication and a commitment to student success.
              </p>
            </li>
            <li className="mt-5 flex items-center gap-5">
            <i className="icofont-check px-2 py-2 text-primaryColor bg-whitegrey3 bg-opacity-40 group-hover:bg-primaryColor group-hover:text-white group-hover:opacity-100 mr-15px dark:bg-whitegrey1-dark" />
              <p className="text-contentColor dark:text-contentColor-dark leading-1.8">
              Platform Usage: Familiarize yourself with Atlearn's features, such as grading tools and feedback options, to enhance the learning experience. 
              </p>
            </li>
         
          </ul>
          <h6 className="text-2xl font-semibold text-blackColor dark:text-blackColor-dark leading-1.8 mb-15px">
            Start With courses
          </h6>
          <p className="text-contentColor dark:text-contentColor-dark leading-1.8 mb-15px mt-5">
          At Atlearn, creating and launching your courses is straightforward. From video lessons to quizzes and assignments, our platform supports a variety of content formats, enabling you to structure courses that cater to different learning styles. Once your course is ready, you can reach students worldwide, allowing them to learn at their own pace while staying connected with you through real-time interactions. Start your journey today and impact online education at the best online course platforms for instructors! 
          </p>
          
          
        </div>
        {/* apply right*/}
        <div data-aos="fade-up">
          <form
            className="p-10px md:p-10 lg:p-5 2xl:p-10 mb-50px bg-darkdeep3 dark:bg-darkdeep3-dark text-sm text-blackColor dark:text-blackColor-dark leading-1.8"
            data-aos="fade-up"
          >
            <div className="grid grid-cols-1 mb-15px gap-15px">
              <div>
                <label className="mb-3 block font-semibold">First Name</label>
                <input
                  type="text"
                  placeholder="John"
                  className="w-full py-10px px-5 text-sm focus:outline-none text-contentColor dark:text-contentColor-dark bg-whiteColor dark:bg-whiteColor-dark border-2 border-borderColor dark:border-borderColor-dark placeholder:text-placeholder placeholder:opacity-80 leading-23px rounded-md font-no"
                />
              </div>
              <div>
                <label className="mb-3 block font-semibold">Last Name</label>
                <input
                  type="text"
                  placeholder="Due"
                  className="w-full py-10px px-5 text-sm focus:outline-none text-contentColor dark:text-contentColor-dark bg-whiteColor dark:bg-whiteColor-dark border-2 border-borderColor dark:border-borderColor-dark placeholder:text-placeholder placeholder:opacity-80 leading-23px rounded-md font-no"
                />
              </div>
              <div>
                <label className="mb-3 block font-semibold">Email</label>
                <input
                  type="email"
                  placeholder="Email"
                  className="w-full py-10px px-5 text-sm focus:outline-none text-contentColor dark:text-contentColor-dark bg-whiteColor dark:bg-whiteColor-dark border-2 border-borderColor dark:border-borderColor-dark placeholder:text-placeholder placeholder:opacity-80 leading-23px rounded-md font-no"
                />
              </div>
              <div>
                <label className="mb-3 block font-semibold">Phone Number</label>
                <input
                  type="text"
                  placeholder="+8-333-555-6666"
                  className="w-full py-10px px-5 text-sm focus:outline-none text-contentColor dark:text-contentColor-dark bg-whiteColor dark:bg-whiteColor-dark border-2 border-borderColor dark:border-borderColor-dark placeholder:text-placeholder placeholder:opacity-80 leading-23px rounded-md font-no"
                />
              </div>
            </div>
            <div className="mb-15px">
              <label className="mb-3 block font-semibold">Bio</label>
              <textarea
                placeholder="Type you comments...."
                className="w-full py-10px px-5 text-sm text-contentColor dark:text-contentColor-dark bg-whiteColor dark:bg-whiteColor-dark border-2 border-borderColor dark:border-borderColor-dark placeholder:text-placeholder placeholder:opacity-80 leading-23px rounded-md"
                cols={30}
                rows={6}
                defaultValue={
                  "Lorem ipsum dolor sit, amet consectetur adipisicing elit."
                }
              />
            </div>
            <div>
              <input type="checkbox" />
              <span className="text-size-15 text-contentColor dark:text-contentColor-dark">
                You agree to our friendly
                <a
                  href="#"
                  className="text-base text-blackColor dark:text-blackColor-dark hover:text-primaryColor dark:hover:text-primaryColor font-bold leading-1 relative before:w-full before:h-1px before:bg-blackColor dark:before:bg-blackColor-dark before:absolute before:left-0 before:-bottom-0.5"
                >
                  Privacy policy
                </a>
                .
              </span>
            </div>
            <div className="mt-15px">
              <button
                type="submit"
                className="text-size-15 text-whiteColor bg-primaryColor px-25px py-10px border border-primaryColor hover:text-primaryColor hover:bg-whiteColor inline-block rounded group dark:hover:text-whiteColor dark:hover:bg-whiteColor-dark"
              >
                Update Info
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>



    </div>
  )
}

export default ApplyAsInstructor
