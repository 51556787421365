
import logo from "src/assets/images/Logo/Atlearn-Dark-Logo-WO-BG.svg"



const Logo = () => {
  return (
    <div>
    <img src={logo} className='w-60' alt="logo" />
    </div>
  )
}

export default Logo