import React from "react";

function ParagraphText({ mainText, highlightedText}) {
  return (
    <div>
<p className="text-sm md:text-base text-contentColor dark:text-contentColor-dark mb-10px 2xl:mb-50px">
      {mainText}
    </p>
    <p className="text-sm md:text-base leading-7 text-contentColor dark:text-contentColor-dark mb-10 pl-3 border-l-[3px] border-secondaryColor">
      {highlightedText}
    </p>
  </div>
  );
}

export default ParagraphText;
