import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import apiClients from "src/apiClients/apiClients";
import { formatDateRange } from "src/utils/formateDateRange";
import { calculateTimeDifference } from "src/utils/FormateDateUtils";
import coverImage from "src/assets/images/online-classes/New/Classroom_Content_Online_Courses_Cover.webp"
function ScheduleRoomSection() {
  const [scheduleRoom, setScheduleRoom] = useState([]);
  useEffect(() => {
    // fetchLiveRooms();
    fetchScheduleRoom();
  }, []);

  const fetchScheduleRoom = async () => {
    try {
      const response = await apiClients.sheduledMeetings();
      // const response = await apiClients.scheduleCount(true);
      if (response?.data) {
        setScheduleRoom(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      {scheduleRoom?.length > 0 && (
        <section>
          <div>
            <div className="container-fluid-2">
              {/* heading */}
              <div data-aos="fade-up">
                <h3
                  className="text-3xl md:text-[35px] lg:text-size-42 leading-[45px] 2xl:leading-[45px] md:leading-[50px] font-bold mb-5 md:mb-10 text-blackColor dark:text-blackColor-dark"
                  data-aos="fade-up"
                >
                  Join Our Online Classes
                </h3>
              </div>
              {/* featured cards */}
              {/*meeting cards section */}
              <section>
                <div
                  className=" grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-30px  "
                  data-aos="fade-up"
                >
                  {/* card 1 */}
                  {scheduleRoom?.slice(0, 4)?.map((item, index) => (
                    <div className="group" data-aos="fade-up">
                      <div>
                        <div className="p-15px bg-whiteColor shadow-brand dark:bg-darkdeep3-dark dark:shadow-brand-dark">
                          {/* card image */}
                          <div className="relative mb-4 overflow-hidden">
                            {/* <a href="course.html" className="w-full">
                              <img
                                // src="../../assets/images/zoom/1.jpg"
                                src={`${process.env.REACT_APP_OVERRIDE_HOST}/api/images/${item?.room?.cover_image_url}`}
                              
                                alt=""
                                className="w-full h-[200px] transition-all duration-300 group-hover:scale-110"
                              />
                            </a> */}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                height: "150px",
                                overflow: "hidden",
                              }}
                            >
                              <img
                                // src={`${process.env.REACT_APP_OVERRIDE_HOST}/api/images/${item?.room?.cover_image_url}`}
                                src={item?.room?.cover_image_url 
                                  ? `${process.env.REACT_APP_OVERRIDE_HOST}/api/images/${item?.room?.cover_image_url}` 
                                  : coverImage}
                                alt={"alt"}
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          </div>
                          {/* card content */}
                          <div>
                            <div className="flex flex-wrap justify-between items-center mb-15px">
                              <div className="flex items-center">
                                <div>
                                  <i className="icofont-calendar pr-5px text-primaryColor text-lg" />
                                </div>
                                <div>
                                  <span className="text-sm text-black dark:text-blackColor-dark">
                                    {
                                      formatDateRange(
                                        item?.startDate,
                                        item?.endDate
                                      ).formattedStartDate
                                    }
                                  </span>
                                </div>
                              </div>
                              <div className="flex items-center">
                                <div>
                                  <i className="icofont-clock-time pr-5px text-primaryColor text-lg" />
                                </div>
                                <div>
                                  <span className="text-sm text-black dark:text-blackColor-dark">
                                    {calculateTimeDifference(
                                      item.startDate,
                                      item.endDate
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <Link
                              to={`/Join-meeting?roomId=${item?.room?.friendly_id}&scheduleId=${item?.id}`}
                              className="text-lg md:text-size-22 font-semibold text-blackColor mb-10px font-hind dark:text-blackColor-dark hover:text-primaryColor dark:hover:text-primaryColor"
                            >
                              {item?.title}
                            </Link>
                            {/* time */}
                            <p className="text-sm text-contentColor dark:text-contentColor-dark flex items-center">
                              Starting Time:
                              <span className="text-xl md:text-size-26 leading-9 md:leading-12 font-bold text-primaryColor ml-10px">
                                {
                                  formatDateRange(item.startDate, item.endDate)
                                    .formattedStartTime
                                }
                              </span>
                            </p>
                            {/* time */}
                            {/* <p className="text-sm text-contentColor dark:text-contentColor-dark flex items-center mb-15px">
                              Meeting ID :
                              <span className="text-sm md:text-lg leading-9 md:leading-12 font-bold text-secondaryColor ml-10px">
                                952428993999
                              </span>
                            </p> */}
                            {/* author and rating*/}
                            <div className="pt-15px border-t border-borderColor">
                              <div>
                                <a
                                  href="instructor-details.html"
                                  className="text-xs flex items-center text-contentColor hover:text-primaryColor dark:text-contentColor-dark dark:hover:text-primaryColor"
                                >
                                  <img
                                    className="w-50px h-50px rounded-full mr-15px"
                                    src="../../assets/images/grid/grid_small_1.jpg"
                                    alt=""
                                  />
                                  <div>
                                    <span>Speaker:</span>
                                    <h3 className="text-lg font-bold text-blackColor dark:text-blackColor-dark">
                                      {item?.room?.user?.name}
                                    </h3>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {scheduleRoom?.length > 4 && (
                  <div className="flex justify-center" data-aos="fade-up">
                    <Link
                      to={"/join-classes"}
                      className="text-size-15 px-47px py-15px bg-primaryColor text-whiteColor border border-primaryColor hover:text-primaryColor hover:bg-whiteColor dark:hover:bg-whiteColor-dark dark:hover:text-whiteColor mt-10 rounded uppercase"
                    >
                      More Online Classes
                    </Link>
                  </div>
                )}
              </section>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default ScheduleRoomSection;
