import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  Box,
  Button,
  // Checkbox,
  Container,
  Divider,
  // FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import MainButton from "../Button/MainButton/MainButton";
import SecondaryButton from "../Button/SecondaryButton/SecondaryButton";
import BankDetailsPopup from "../BankDetails/BankDetailsPopup";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} timeout={500} />;
});

export default function NewRoom({ open, handleClose, handleCreateRoom }) {
  const { user } = useSelector((state) => state.user);

  const formdata = {
    name: "",
    price: null,
   
  };

  const [formData, setFormData] = useState(formdata);
  const [errors, setErrors] = useState({});
  const [paidRoom, setPaidRoom] = useState(false);
  const [openbank, setOpenBank] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);

  const handleClosebox = () => {
    setFormData(formdata);
    setErrors({});
    setPaidRoom(false);
    setUploadedImage(null)
    handleClose();
  };
  const handleCloseBankDetailsPopup = () => {
    setOpenBank(false);
    handleClosebox();
  };

  const CreateRoom = () => {
    const newErrors = {};
    const requiredFields = ["name"];
    requiredFields.forEach((field) => {
      if (
        formData[field] === undefined ||
        formData[field] === null ||
        formData[field] === ""
      ) {
        newErrors[field] = `This ${field} field is required.`;
      }
    });
    if (paidRoom) {
      if (!formData.price?.trim()) {
        newErrors.price = "Price is required.";
      } else if (!/^\d+$/.test(formData.price)) {
        newErrors.price = "Price must be a whole number without decimals.";
      }
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});

      if (paidRoom) {
        if (user?.user?.bankDetailsId) {
          handleCreateRoom(formData?.name, formData?.price,uploadedImage);
          handleClosebox();
        } else {
          setOpenBank(true);
        }
      } else {
        handleCreateRoom(formData?.name, formData?.price,uploadedImage);
        handleClosebox();
      }

      // handleClosebox();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const isFileTypeValid = (file) => {
    const validTypes = ["image/png", "image/jpeg", "image/svg+xml"];
    return validTypes.includes(file.type);
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file && isFileTypeValid(file)) {
      console.log(file, "fileeeeeeeeeeeeeeeeeeeeeeee");

      // setProfile(file);
      setUploadedImage(file);
      // uploadProfile(file);
    } else {
      // setSelectedFile(null);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleRemoveImage = () => {
    setUploadedImage(null); // Clear the image
  };

  return (
    <>
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClosebox}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle sx={{ textAlign: "center", backgroundColor: "#F5F7FB" }}>
            {"Create a new room"}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Box sx={{ mt: 6 }}>
              <Container>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography gutterBottom>
                      Room Name{" "}
                      <span className="text-red-500 text-sm"> * </span>
                    </Typography>
                    <TextField
                      type="text"
                      placeholder="Enter a room name..."
                      name="name"
                      fullWidth
                      value={formData?.name || ""}
                      onChange={handleChange}
                      error={!!errors.name}
                      helperText={errors.name}
                    />
                  </Grid>
                  {/* <Grid>
                  <Box {...getRootProps()}>
                        <input {...getInputProps()} />
                        <MainButton>
                      
                          Upload photo
                        </MainButton>
                      </Box>
                  </Grid> */}
                  <Grid item xs={12}>
                    <Box
                     {...getRootProps()}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px dashed grey",
                        padding: "16px",
                        borderRadius: "8px",
                        cursor: "pointer",
                        "&:hover": { borderColor: "blue" },
                      }}
                    >
                      <input {...getInputProps()} />
                      {uploadedImage ? (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={URL.createObjectURL(uploadedImage)}
                            alt="Uploaded"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "200px",
                              borderRadius: "8px",
                              marginBottom: "8px",
                            }}
                          />

                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleRemoveImage}
                            size="small"
                          >
                            Remove Image
                          </Button>
                        </Box>
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          Drag or Upload
                        </Typography>
                      //    <Box {...getRootProps()}>
                      //    <input {...getInputProps()} onChange={handleFileUpload} />
                      //    <MainButton variant="contained" color="primary">
                      //      Upload Photo
                      //    </MainButton>
                      //  </Box>
                      )}
                    </Box>
                  </Grid>
                  {/* <Grid item xs={11}>
                    <FormControlLabel
                      label="Add Price for this Room"
                      control={
                        <Checkbox
                          checked={paidRoom}
                          // indeterminate={checked[0] !== checked[1]}
                          onChange={
                            (e) => setPaidRoom(e.target.checked)
                            // handleChange("public_view", e.target.checked)
                          }
                        />
                      }
                    />
                  </Grid>
                  {paidRoom && (
                    <Grid item xs={12} sm={12}>
                      <Typography gutterBottom>
                        Price <span className="text-red-500 text-sm"> * </span>
                      </Typography>
                      <TextField
                        type="Number"
                        placeholder="Enter Price..."
                        name="price"
                        fullWidth
                        value={formData?.price || ""}
                        onChange={handleChange}
                        error={!!errors.price}
                        helperText={errors.price}
                      />
                    </Grid>
                  )} */}

                  <Grid item xs={12}>
                    <Box
                      display={{ xs: "flex", sm: "flex" }}
                      flexDirection={{ xs: "column", sm: "row" }}
                      justifyContent={{ xs: "center", sm: "flex-end" }}
                      gap={1}
                    >
                      <SecondaryButton onClick={handleClosebox}>
                        Cancel
                      </SecondaryButton>
                      <MainButton onClick={CreateRoom}>Create Room</MainButton>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
      <BankDetailsPopup
        open={openbank}
        handleClose={handleCloseBankDetailsPopup}
      />
    </>
  );
}
