import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import apiClients from 'src/apiClients/apiClients';
import coverImage from "src/assets/images/online-classes/New/Classroom_Content_Online_Courses_Cover.webp"
import { formatDateRange } from 'src/utils/formateDateRange';
import { calculateTimeDifference } from 'src/utils/FormateDateUtils';

function AllRooms() {
    const [scheduleRoom, setScheduleRoom] = useState([]);
    useEffect(() => {
        const tabs = document.querySelectorAll(".tab");
    
        tabs.forEach((tab) => {
          const tabLinks = Array.from(tab.querySelector(".tab-links").children);
          const tabContents = Array.from(tab.querySelector(".tab-contents").children);
    
          // Set up event listeners for each tab link
          tabLinks.forEach((tabLink, index) => {
            tabLink.addEventListener("click", () => {
              // Update tab link styles
              tabLinks.forEach((link, idx) => {
                link.classList.remove("bg-white", "shadow-bottom");
                link.classList.add("bg-lightGrey7", "dark:bg-lightGrey7-dark", "inActive");
    
                const span = link.querySelector("span");
                if (span) span.classList.replace("w-full", "w-0");
                link.disabled = false;
    
                if (idx === index) {
                  link.disabled = true;
                  link.classList.add("bg-white", "dark:bg-whiteColor-dark", "shadow-bottom");
                  if (span) span.classList.replace("w-0", "w-full");
                }
              });
    
              // Update tab content styles
              tabContents.forEach((tabContent, idx) => {
                tabContent.classList.add("hidden");
                tabContent.classList.remove("block", "opacity-100");
    
                if (idx === index) {
                  tabContent.classList.add("block", "opacity-0");
                  tabContent.classList.remove("hidden");
    
                  setTimeout(() => {
                    tabContent.classList.replace("opacity-0", "opacity-100");
                  }, 150);
                }
              });
    
              // Accordion content height adjustment (if any)
              const accordion = tab.querySelector(".accordion.active");
              if (accordion) {
                const contents = accordion.querySelector(".accordion-content");
                const contentHeight = contents.children[index]?.offsetHeight;
                if (contentHeight) {
                  contents.style.height = `${contentHeight}px`;
                }
              }
            });
          });
        });
      }, []);

      useEffect(() => {
        // fetchLiveRooms();
        fetchScheduleRoom();
      }, []);
    
      const fetchScheduleRoom = async () => {
        try {
          const response = await apiClients.sheduledMeetings();
          // const response = await apiClients.scheduleCount(true);
          if (response?.data) {
            setScheduleRoom(response.data);
          }
        } catch (error) {
          console.log(error);
        }
      };
  return (
    <div>
      <>
  {/* banner section */}
  <section>
    {/* banner section */}
    <div className="bg-lightGrey10 dark:bg-lightGrey10-dark relative z-0 overflow-y-visible py-50px md:py-20 lg:py-100px 2xl:pb-150px 2xl:pt-40.5">
      {/* animated icons */}
      <div>
        <img
          className="absolute left-0 bottom-0 md:left-[14px] lg:left-[50px] lg:bottom-[21px] 2xl:left-[165px] 2xl:bottom-[60px] animate-move-var z-10"
          src="./assets/images/herobanner/herobanner__1.png"
          alt=""
        />
        <img
          className="absolute left-0 top-0 lg:left-[50px] lg:top-[100px] animate-spin-slow"
          src="./assets/images/herobanner/herobanner__2.png"
          alt=""
        />
        <img
          className="absolute right-[30px] top-0 md:right-10 lg:right-[575px] 2xl:top-20 animate-move-var2 opacity-50 hidden md:block"
          src="./assets/images/herobanner/herobanner__3.png"
          alt=""
        />
        <img
          className="absolute right-[30px] top-[212px] md:right-10 md:top-[157px] lg:right-[45px] lg:top-[100px] animate-move-hor"
          src="./assets/images/herobanner/herobanner__5.png"
          alt=""
        />
      </div>
      <div className="container">
        <div className="text-center">
          <h1 className="text-3xl md:text-size-40 2xl:text-size-55 font-bold text-blackColor dark:text-blackColor-dark mb-7 md:mb-6 pt-3">
          Join Our Online Classes
          </h1>
      
        </div>
      </div>
    </div>
  </section>
</>
<>
  {/* courses section */}
  <div>
    <div className="container tab py-10 md:py-50px lg:py-60px 2xl:py-100px">
      {/* courses header */}
      <div
        className="courses-header flex justify-between items-center flex-wrap px-13px py-10px border border-borderColor dark:border-borderColor-dark mb-30px gap-y-5"
        data-aos="fade-up"
      >
        <div>
          {/* <p className="text-blackColor dark:text-blackColor-dark">
            Showing 1–12 of 54 Results
          </p> */}
        </div>
        <div className="flex items-center">
          <div className="tab-links transition-all duraton-300 text-contentColor dark:text-contentColor-dark flex gap-11px">
            <button className="inline-block hover:text-primaryColor active">
              <i className="icofont-layout" />
            </button>
            <button className="inline-block hover:text-primaryColor">
              <i className="icofont-listine-dots" />
            </button>
          </div>
          <div className="pl-50px sm:pl-20 pr-10px">
            <select className="text-blackColor bg-whiteColor py-3px pr-2 pl-3 rounded-md outline-none border-4 border-transparent focus:border-blue-light box-border">
              <option value="Short by New">Short by New</option>
              <option value="Short by New">Short by New</option>
              <option value="One">One</option>
              <option value="Tow">Tow</option>
              <option value="Three">Three</option>
            </select>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-30px">
        {/* courses sidebar */}
        <div className="md:col-start-1 md:col-span-4 lg:col-span-3">
          <div className="flex flex-col">
            {/* search input */}
            <div
              className="pt-30px pr-15px pl-10px pb-23px 2xl:pt-10 2xl:pr-25px 2xl:pl-5 2xl:pb-33px mb-30px border border-borderColor dark:border-borderColor-dark"
              data-aos="fade-up"
            >
              <h4 className="text-size-22 text-blackColor dark:text-blackColor-dark font-bold leading-30px mb-25px">
                Search here
              </h4>
              <form className="w-full px-4 py-15px text-sm text-contentColor bg-lightGrey10 dark:bg-lightGrey10-dark dark:text-contentColor-dark flex justify-center items-center leading-26px">
                <input
                  type="text"
                  placeholder="Search Produce"
                  className="placeholder:text-placeholder bg-transparent focus:outline-none placeholder:opacity-80 w-full"
                />
                <button type="submit">
                  <i className="icofont-search-1 text-base" />
                </button>
              </form>
            </div>
            {/* categories */}
            <div
              className="pt-30px pr-15px pl-10px pb-23px 2xl:pt-10 2xl:pr-25px 2xl:pl-5 2xl:pb-33px mb-30px border border-borderColor dark:border-borderColor-dark"
              data-aos="fade-up"
            >
              <h4 className="text-size-22 text-blackColor dark:text-blackColor-dark font-bold leading-30px mb-25px">
                categories
              </h4>
              <ul className="flex flex-col gap-y-4">
                <li className="text-contentColor hover:text-contentColor-dark hover:bg-primaryColor text-sm font-medium px-13px py-2 border border-borderColor dark:border-borderColor-dark flex justify-between leading-7 transition-all duration-300">
                  <a href="/">Mobile Set</a> <a href="/">03</a>
                </li>
                <li className="text-contentColor hover:text-contentColor-dark hover:bg-primaryColor text-sm font-medium px-13px py-2 border border-borderColor dark:border-borderColor-dark flex justify-between leading-7 transition-all duration-300">
                  <a href="/">Mobile Set</a> <a href="/">03</a>
                </li>
                <li className="text-contentColor hover:text-contentColor-dark hover:bg-primaryColor text-sm font-medium px-13px py-2 border border-borderColor dark:border-borderColor-dark flex justify-between leading-7 transition-all duration-300">
                  <a href="/">Raxila Dish nonyte</a> <a href="/">09</a>
                </li>
                <li className="text-contentColor hover:text-contentColor-dark hover:bg-primaryColor text-sm font-medium px-13px py-2 border border-borderColor dark:border-borderColor-dark flex justify-between leading-7 transition-all duration-300">
                  <a href="/">Fresh Vegetable</a> <a href="/">01</a>
                </li>
                <li className="text-contentColor hover:text-contentColor-dark hover:bg-primaryColor text-sm font-medium px-13px py-2 border border-borderColor dark:border-borderColor-dark flex justify-between leading-7 transition-all duration-300">
                  <a href="/">Fruites</a> <a href="/">00</a>
                </li>
                <li className="text-contentColor hover:text-contentColor-dark hover:bg-primaryColor text-sm font-medium px-13px py-2 border border-borderColor dark:border-borderColor-dark flex justify-between leading-7 transition-all duration-300">
                  <a href="/">Gesuriesey</a> <a href="/">26</a>
                </li>
              </ul>
            </div>
            {/* tags */}
            <div
              className="pt-30px pr-15px pl-10px pb-23px 2xl:pt-10 2xl:pr-25px 2xl:pl-5 2xl:pb-33px mb-30px border border-borderColor dark:border-borderColor-dark"
              data-aos="fade-up"
            >
              <h4 className="text-size-22 text-blackColor dark:text-blackColor-dark font-bold leading-30px mb-25px">
                Tag
              </h4>
              <ul className="flex flex-col gap-y-23px">
                <li className="text-primaryColor text-size-15 font-medium dark:text-contentColor-dark flex justify-between leading-26px group">
                  <a href="/" className="w-full flex items-center gap-11px">
                    <span className="w-14px h-15px border border-primaryColor bg-primaryColor group-hover:border-primaryColor group-hover:bg-primaryColor" />
                    <span>Mechanic</span>
                  </a>
                </li>
                <li className="text-contentColor text-size-15 font-medium hover:text-primaryColor dark:hover:text-primaryColor dark:text-contentColor-dark flex justify-between leading-26px group">
                  <a href="/" className="w-full flex items-center gap-11px">
                    <span className="w-14px h-15px border border-darkdeep6 group-hover:border-primaryColor group-hover:bg-primaryColor" />
                    <span>English</span>
                  </a>
                </li>
                <li className="text-contentColor text-size-15 font-medium hover:text-primaryColor dark:hover:text-primaryColor dark:text-contentColor-dark flex justify-between leading-26px group">
                  <a href="/" className="w-full flex items-center gap-11px">
                    <span className="w-14px h-15px border border-darkdeep6 group-hover:border-primaryColor group-hover:bg-primaryColor" />
                    <span>Computer Science</span>
                  </a>
                </li>
                <li className="text-contentColor text-size-15 font-medium hover:text-primaryColor dark:hover:text-primaryColor dark:text-contentColor-dark flex justify-between leading-26px group">
                  <a href="/" className="w-full flex items-center gap-11px">
                    <span className="w-14px h-15px border border-darkdeep6 group-hover:border-primaryColor group-hover:bg-primaryColor" />
                    <span>Data &amp; Tech</span>
                  </a>
                </li>
                <li className="text-contentColor text-size-15 font-medium hover:text-primaryColor dark:hover:text-primaryColor dark:text-contentColor-dark flex justify-between leading-26px group">
                  <a href="/" className="w-full flex items-center gap-11px">
                    <span className="w-14px h-15px border border-darkdeep6 group-hover:border-primaryColor group-hover:bg-primaryColor" />
                    <span>Ux Desgin</span>
                  </a>
                </li>
              </ul>
            </div>
            {/* skills */}
            <div
              className="pt-30px pr-15px pl-10px pb-23px 2xl:pt-10 2xl:pr-25px 2xl:pl-5 2xl:pb-33px mb-30px border border-borderColor dark:border-borderColor-dark"
              data-aos="fade-up"
            >
              <h4 className="text-size-22 text-blackColor dark:text-blackColor-dark font-bold leading-30px mb-25px">
                Skill Level
              </h4>
              <ul className="flex flex-col gap-y-10px">
                <li className="text-contentColor text-size-15 font-medium hover:text-primaryColor dark:text-contentColor-darkdark:hover:text-primaryColor flex justify-between leading-26px">
                  <a href="/" className="w-full">
                    {" "}
                    All{" "}
                  </a>
                </li>
                <li className="text-contentColor text-size-15 font-medium hover:text-primaryColor dark:text-contentColor-darkdark:hover:text-primaryColor flex justify-between leading-26px">
                  <a href="/" className="w-full">
                    {" "}
                    Fullstack{" "}
                  </a>
                </li>
                <li className="text-contentColor text-size-15 font-medium hover:text-primaryColor dark:text-contentColor-darkdark:hover:text-primaryColor flex justify-between leading-26px">
                  <a href="/" className="w-full">
                    {" "}
                    English Learn{" "}
                  </a>
                </li>
                <li className="text-contentColor text-size-15 font-medium hover:text-primaryColor dark:text-contentColor-darkdark:hover:text-primaryColor flex justify-between leading-26px">
                  <a href="/" className="w-full">
                    {" "}
                    Intermediate{" "}
                  </a>
                </li>
                <li className="text-contentColor text-size-15 font-medium hover:text-primaryColor dark:text-contentColor-darkdark:hover:text-primaryColor flex justify-between leading-26px">
                  <a href="/" className="w-full">
                    {" "}
                    Wordpress{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* courses main */}
        <div className="md:col-start-5 md:col-span-8 lg:col-start-4 lg:col-span-9 space-y-[30px]">
          <div className="tab-contents">
            {/* grid ordered cards */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-30px">
              {/* card 1 */}

                {scheduleRoom?.map((item, index) => (
                <div key={index} className="group" >
                  <div className="tab-content-wrapper" data-aos="fade-up">
                    <div className="p-15px bg-whiteColor shadow-brand dark:bg-darkdeep3-dark dark:shadow-brand-dark">
                      {/* card image */}
                      <div className="relative mb-4 overflow-hidden">
                     
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "150px",
                            overflow: "hidden",
                          }}
                        >
                          <img
                                 src={item?.room?.cover_image_url 
                                  ? `${process.env.REACT_APP_OVERRIDE_HOST}/api/images/${item?.room?.cover_image_url}` 
                                  : coverImage}
                            alt={"alt"}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      </div>
                      {/* card content */}
                      <div>
                        <div className="flex flex-wrap justify-between items-center mb-15px">
                          <div className="flex items-center">
                            <div>
                              <i className="icofont-calendar pr-5px text-primaryColor text-lg" />
                            </div>
                            <div>
                              <span className="text-sm text-black dark:text-blackColor-dark">
                                {
                                  formatDateRange(
                                    item?.startDate,
                                    item?.endDate
                                  ).formattedStartDate
                                }
                              </span>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div>
                              <i className="icofont-clock-time pr-5px text-primaryColor text-lg" />
                            </div>
                            <div>
                              <span className="text-sm text-black dark:text-blackColor-dark">
                                {calculateTimeDifference(
                                  item.startDate,
                                  item.endDate
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                        <Link
                          to={`/Join-meeting?roomId=${item?.room?.friendly_id}&scheduleId=${item?.id}`}
                          className="text-lg md:text-size-22 font-semibold text-blackColor mb-10px font-hind dark:text-blackColor-dark hover:text-primaryColor dark:hover:text-primaryColor"
                        >
                          {item?.title}
                        </Link>
                         {/* time */}
                         <p className="text-sm text-contentColor dark:text-contentColor-dark flex items-center">
                          Starting Time:
                          <span className="text-xl md:text-size-26 leading-9 md:leading-12 font-bold text-primaryColor ml-10px">
                            {
                              formatDateRange(item.startDate, item.endDate)
                                .formattedStartTime
                            }
                          </span>
                        </p>
                        <p className="text-sm text-contentColor dark:text-contentColor-dark flex items-center">
                             
                        {item?.price > 0 ? (
                        <div className="text-size-21 font-bold text-primaryColor font-inter leading-25px">
                          ₹ {item?.price}
                        </div>
                      ) : (
                        <span className="">
                          <span className="text-base font-semibold text-greencolor">
                            Free
                          </span>
                        </span>
                      )}
                        </p>
                        
                       
                        {/* time */}
                        {/* <p className="text-sm text-contentColor dark:text-contentColor-dark flex items-center mb-15px">
                              Meeting ID :
                              <span className="text-sm md:text-lg leading-9 md:leading-12 font-bold text-secondaryColor ml-10px">
                                952428993999
                              </span>
                            </p> */}
                        {/* author and rating*/}
                        <div className="pt-15px border-t border-borderColor">
                          <div>
                            <a
                              href="instructor-details.html"
                              className="text-xs flex items-center text-contentColor hover:text-primaryColor dark:text-contentColor-dark dark:hover:text-primaryColor"
                            >
                              <img
                                className="w-50px h-50px rounded-full mr-15px"
                                src="../../assets/images/grid/grid_small_1.jpg"
                                alt=""
                              />
                              <div>
                                <span>Speaker:</span>
                                <h3 className="text-lg font-bold text-blackColor dark:text-blackColor-dark">
                                  {item?.room?.user?.name}
                                </h3>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
                 
              
             
            </div>
            {/* list ordered cards */}
            <div className="hidden opacity-0 transition-all duration-300">
              <div className="flex flex-col gap-30px">
                {/* card 1 */}
                {scheduleRoom?.map((item, index) => (
                    <div className="w-full group grid-item rounded">
                  <div className="tab-content-wrapper" data-aos="fade-up">
                    <div className="p-15px lg:pr-30px bg-whiteColor shadow-brand dark:bg-darkdeep3-dark dark:shadow-brand-dark flex flex-wrap md:flex-nowrap rounded">
                      {/* card image */}
                      <div className="relative overflow-hidden w-full md:w-2/5">
                      <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "150px",
                            overflow: "hidden",
                          }}
                        >
                          <img
                                 src={item?.room?.cover_image_url 
                                  ? `${process.env.REACT_APP_OVERRIDE_HOST}/api/images/${item?.room?.cover_image_url}` 
                                  : coverImage}
                            alt={"alt"}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      
                      </div>
                      {/* card content */}
                      <div className="w-full md:w-3/5">
                        <div className="pl-0 lg:pl-30px">
                          <div className="grid grid-cols-2 mb-15px">
                            <div className="flex items-center">
                            <div>
                              <i className="icofont-calendar pr-5px text-primaryColor text-lg" />
                            </div>
                            <div>
                              <span className="text-sm text-black dark:text-blackColor-dark">
                                {
                                  formatDateRange(
                                    item?.startDate,
                                    item?.endDate
                                  ).formattedStartDate
                                }
                              </span>
                            </div>
                            </div>
                            <div className="flex items-center">
                              <div>
                                <i className="icofont-clock-time pr-5px text-primaryColor text-lg" />
                              </div>
                              <div>
                              <span className="text-sm text-black dark:text-blackColor-dark">
                                {calculateTimeDifference(
                                  item.startDate,
                                  item.endDate
                                )}
                              </span>
                              </div>
                            </div>
                          </div>
                          <Link
                            to={`/Join-meeting?roomId=${item?.room?.friendly_id}&scheduleId=${item?.id}`}
                            className="text-size-26 leading-30px font-semibold text-blackColor mb-10px font-hind dark:text-blackColor-dark hover:text-primaryColor dark:hover:text-primaryColor"
                          >
                           {item?.title}
                          </Link>
                          <div className="text-lg font-semibold text-black-brerry-light font-inter mb-4">
                          Starting Time:
                          <span className="text-xl md:text-size-26 leading-9 md:leading-12 font-bold text-primaryColor ml-10px">
                            {
                              formatDateRange(item.startDate, item.endDate)
                                .formattedStartTime
                            }
                          </span>
                          </div>
                          {/* price */}
                          <div className="text-lg font-semibold text-black-brerry-light font-inter mb-4">
                           
                          {item?.price > 0 ? (
                        <div className="text-size-21 font-bold text-primaryColor font-inter leading-25px">
                          ₹ {item?.price}
                        </div>
                      ) : (
                        <span className="">
                          <span className="text-base font-semibold text-greencolor">
                            Free
                          </span>
                        </span>
                      )}
                          </div>
                          {/* bottom */}
                          <div className="flex flex-wrap justify-between sm:flex-nowrap items-center gap-y-2 pt-15px border-t border-borderColor">
                            {/* author and rating*/}
                            <div className="flex items-center flex-wrap">
                              <div>
                                <a
                                  href="instructor-details.html"
                                  className="text-sm font-medium font-hind flex items-center hover:text-primaryColor dark:text-blackColor-dark dark:hover:text-primaryColor"
                                >
                                  <img
                                    className="w-[30px] h-[30px] rounded-full mr-15px"
                                    src="./assets/images/grid/grid_small_1.jpg"
                                    alt=""
                                  />
                                  <span className="flex">{item?.room?.user?.name}</span>
                                </a>
                              </div>
                          
                            </div>
                         
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
                
               
              </div>
            </div>
          </div>
          {/* pagination */}
          {/* <div>
            <ul className="flex items-center justify-center gap-15px mt-60px mb-30px">
              <li>
                <a
                  href="/"
                  className="w-10 h-10 leading-10 md:w-50px md:h-50px md:leading-50px text-center text-blackColor2 hover:text-whiteColor bg-whitegrey1 hover:bg-primaryColor dark:text-blackColor2-dark dark:hover:text-whiteColor dark:bg-whitegrey1-dark dark:hover:bg-primaryColor cursor-not-allowed"
                >
                  <i className="icofont-double-left" />
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="w-10 h-10 leading-10 md:w-50px md:h-50px md:leading-50px text-center text-whiteColor hover:text-whiteColor bg-primaryColor hover:bg-primaryColor dark:text-blackColor2-dark dark:hover:text-whiteColor dark:bg-primaryColor dark:hover:bg-primaryColor"
                >
                  1
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="w-10 h-10 leading-10 md:w-50px md:h-50px md:leading-50px text-center text-blackColor2 hover:text-whiteColor bg-whitegrey1 hover:bg-primaryColor dark:text-blackColor2-dark dark:hover:text-whiteColor dark:bg-whitegrey1-dark dark:hover:bg-primaryColor"
                >
                  2
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="w-10 h-10 leading-10 md:w-50px md:h-50px md:leading-50px text-center text-blackColor2 hover:text-whiteColor bg-whitegrey1 hover:bg-primaryColor dark:text-blackColor2-dark dark:hover:text-whiteColor dark:bg-whitegrey1-dark dark:hover:bg-primaryColor"
                >
                  3
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="w-10 h-10 leading-10 md:w-50px md:h-50px md:leading-50px text-center text-blackColor2 hover:text-whiteColor bg-whitegrey1 hover:bg-primaryColor dark:text-blackColor2-dark dark:hover:text-whiteColor dark:bg-whitegrey1-dark dark:hover:bg-primaryColor"
                >
                  <i className="icofont-double-right" />
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  </div>
</>

    </div>
  )
}

export default AllRooms
