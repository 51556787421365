//  import homeAboutimage from "./assets/images/about/about_ai.jpg"
import DrivenImage from "src/assets/images/home/new/driven by learning innovation img.png";

export const aboutSection = {
  imageSrc: DrivenImage,
  imageAlt: "",
  headerText: "Driven by Learning Innovation",
  description: [
    `At Atlearn, we're revolutionizing the way education is delivered with our cutting-edge elearning lms platform. Our innovative solution seamlessly integrates all vital educational elements into one user-friendly application, providing students and educators alike with an intuitive interface for a truly immersive and comprehensive learning journey. Whether you're a student eager to delve into new subjects or an educator striving to deliver top-notch instruction, Atlearn empowers you to unlock your full potential and achieve academic excellence like never before. Join us as we reshape the future of education, one seamless online class platform for students to experience.`,
  ],
  // linkHref: "/",
  // linkText: "About More",
};
