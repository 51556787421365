import RightIcon from "src/assets/icons/SuccessIcon";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "src/Redux/userSlice";
import apiClients from "src/apiClients/apiClients";
import { UseAuth } from "src/utils/UseAuth/UseAuth";
import Images1 from "src/assets/images/price/b03ca2d9-49be-4bf5-b115-42e93c1a8f2f.gif";
import { Box } from "@mui/material";
import PrimaryButton from "src/components/New components/PrimaryButton";
import SplitSection from "src/components/New components/SplitSection";

const Pricing = () => {
  const trialInfo = {
    imageSrc: Images1,
    imageAlt: "",
    headerText:
      "Ready to experience Atlearn? Sign up for our free 7-day trial today!",
    linkHref: "/room",
    linkText: "Start Free Trial",
  };
  const navigate = useNavigate();
  const auth = UseAuth(true);
  const dispatch = useDispatch();
  const [pricing, setPricing] = useState();
  const sectionRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (auth.user) {
      dispatch(setUser(auth.user));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const handleScrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const fetchData = async () => {
    try {
      const response = await await apiClients.pricing();
      if (response.data) {
        setPricing(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      {/* banner section */}
        {/* banner section */}
        <section>
        {/* banner section */}
        <div className="bg-lightGrey10 dark:bg-lightGrey10-dark relative z-0 overflow-y-visible py-50px md:py-20 lg:py-100px 2xl:pb-150px 2xl:pt-40.5">
          {/* animated icons */}
          <div>
            <img
              className="absolute left-0 bottom-0 md:left-[14px] lg:left-[50px] lg:bottom-[21px] 2xl:left-[165px] 2xl:bottom-[60px] animate-move-var z-10"
              src="./assets/images/herobanner/herobanner__1.png"
              alt=""
            />
            <img
              className="absolute left-0 top-0 lg:left-[50px] lg:top-[100px] animate-spin-slow"
              src="./assets/images/herobanner/herobanner__2.png"
              alt=""
            />
            <img
              className="absolute right-[30px] top-0 md:right-10 lg:right-[575px] 2xl:top-20 animate-move-var2 opacity-50 hidden md:block"
              src="./assets/images/herobanner/herobanner__3.png"
              alt=""
            />
            <img
              className="absolute right-[30px] top-[212px] md:right-10 md:top-[157px] lg:right-[45px] lg:top-[100px] animate-move-hor"
              src="./assets/images/herobanner/herobanner__5.png"
              alt=""
            />
          </div>
          <div className="container">
            <div className="text-center">
              <h1 className="text-3xl md:text-size-40 2xl:text-size-55 font-bold text-blackColor dark:text-blackColor-dark mb-7 md:mb-6 pt-3">
              Pricing
              </h1>
              <p className="text-size-15md:text-lg text-blackColor dark:text-blackColor-dark font-medium">
              Affordable plans tailored for every learner—find the perfect fit with Atlearn
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <Header
        backgroundImage={"/cover_images/pricing.svg"}
        pageTitle={"Pricing"}
        subTitle={
          "Affordable plans tailored for every learner—find the perfect fit with Atlearn"
        }
        cta={"Subscribe now"}
        handleScrollToSection={handleScrollToSection}
      /> */}
      {/* <div ref={sectionRef} className="mt-[50px]  flex flex-wrap"> */}
      <div
        ref={sectionRef}
        className="mt-[50px] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2  lg:grid-cols-4 gap-6"
      >
        {pricing?.map((item, index) => (
          <div
            data-aos="fade-up"
            key={index}
            onClick={() => {
              item?.name === "Free"
                ? navigate("/signup")
                : navigate(`/checkout?id=${item?.id}`);
            }}
            //       style={{ padding: "115px 40px 40px 40px", borderRadius: "30px" }}
            //       //       className=" relative box-border m-auto min-h-80 w-96
            //       // rounded-md  bg-[#F0F6FA] mt-20 "
            //       className=" relative box-border m-auto min-h-80
            // rounded-md  bg-[#F0F6FA] mt-20 transition-transform transform hover:scale-105 hover:cursor-pointer"
            className="  relative mt-20 box-border m-auto min-h-80 rounded-lg bg-white shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl hover:cursor-pointer"
            style={{ padding: "115px 72px 40px 40px", borderRadius: "20px" }}
          >
            <div>
              <ul className="list-none mb-8 text-[12px]">
                <li className="flex mb-4 gap-3 " id="li-1">
                  <RightIcon /> {item.participants} participants
                </li>
                <li className="flex mb-4 gap-3" id="li-1">
                  <RightIcon /> {item.duration} hours
                </li>

                {/* <li className="flex mb-4 gap-3 " id="li-1">
                  <RightIcon /> {item.Validity}{" "}
                  {item?.period === "day"
                    ? item.Validity > 1
                      ? "days"
                      : "day"
                    : item.Validity > 1
                    ? "months"
                    : "month"}
                </li> */}
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.chat === "true" ? `opacity-100` : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Public / Private Chat
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.sharedNotes === "true" ? `opacity-100` : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Shared Notes
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.screenshare === "true" ? `opacity-100` : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Screen Sharing
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.sharedRoomAccess === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Share Access
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.storage > 0 ? `opacity-100` : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> {item.storage} GB
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.recording === "true" ? `opacity-100` : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />{" "}
                  {item.recording === "true" ? "Recordings" : "No Recordings"}
                </li>

                <li
                  className={`flex mb-4 gap-3 ${
                    item?.breakout === "true" ? `opacity-100` : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Breakout Rooms
                </li>

                <li
                  className={`flex mb-4 gap-3 ${
                    item?.multiuserwhiteboard === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Multi User Whiteboard
                </li>
                <h2 className="text-center text-xl  font-medium mb-4 p-4 bg-blue-100 border border-blue-300 rounded-lg shadow-md">
                  LMS AI
                </h2>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.courseManagement === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Course Management
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.efficientDigitalBookManagement === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Efficient Digital Book Management
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.bulkEnrollment === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Bulk Enrollment
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.communicationTools === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Communication Tools
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.studentManagement === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Student Management
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.reportsAndAnalytics === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Reports And Analytics
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.customizationAndPersonalization === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Customization & Personalization
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.assessmentAndGrading === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Assessment & Grading
                </li>

                <li
                  className={`flex mb-4 gap-3 ${
                    item?.multipleChoiceQuestions === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Multiple Choice Questions
                </li>

                <li
                  className={`flex mb-4 gap-3 ${
                    item?.rubricGenerator === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Rubric Generator
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.studentWorkFeedback === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Student Work Feedback
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.professionalEmailCommunication === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Professional Email Communication
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.depthOfKnowledgeQuizGenerator === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Depth of Knowledge Quiz Generator
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.careerOrCollegeCounselor === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Career or college counselor
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.ideaGenerator === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Idea Generator
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.learnCoding === "true" ? `opacity-100` : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Learn coding
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.syllabus === "true" ? `opacity-100` : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Syllabus
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.assessmentOutline === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Assessment Outline
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.lessonPlan5Es === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Lesson Plan - 5 E's
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.claimEvidenceReasoning === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Claim, Evidence, Reasoning
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.debate === "true" ? `opacity-100` : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Debate
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.mockInterview === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Mock Interview
                </li>

                <li
                  className={`flex mb-4 gap-3 ${
                    item?.projectBasedLearning === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Project Based Learning
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.teamBasedActivity === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Team Based Activity
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.battleshipStyle === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Battleship Style
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.fillInTheBlankQuestions === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Fill In The Blank Questions
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.scenarioBasedQuestions === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Scenario-Based Questions
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.trueFalseQuestions === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  True/False Questions
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.timelyRelevantActionableFeedback === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Timely, relevant, and actionable feedback
                </li>
              </ul>
            </div>
            <div className="flex items-center justify-center w-full h-full">
              <PrimaryButton
                primary={true}
                disabled={auth?.user && item?.name === "Free"}
                onClick={() => {
                  item?.name === "Free"
                    ? navigate("/signup")
                    : navigate(`/checkout?id=${item?.id}`);
                }}
              >
                {item?.name === "Free" ? "Sign up for free" : "Subscribe"}
              </PrimaryButton>
            </div>

            <div
              style={{
                margin: "0 8px -50px 9px",
                padding: "25px 30px 22px 30px",
                width: "95%",
                border: "2px solid #490d59",
                borderRadius: "30PX",
                backgroundColor: "#ffffff",
              }}
              className="box-border   
        m4 absolute top-[-50px] left-0"
            >
              {item?.freelms === "true" && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "-15px",
                    right: "15px",
                    backgroundColor: "secondary.main",
                    color: "#ffffff",
                    padding: "5px 10px",
                    borderRadius: "50px",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  FREE LMS
                </Box>
              )}

              <h1
                className="text-2xl  font-semibold capitalize  "
                style={{ color: "#000", lineHeight: "1.2" }}
              >
                {item.name}
              </h1>
              <h1 className="text-[40px]  font-bold capitalize text-secondary  ">
                ₹ {item.price}
                <span
                  className="text-xl  "
                  style={{ color: "#000", marginTop: "1px" }}
                >
                  / {item.Validity}{" "}
                  {item?.period === "day"
                    ? item.Validity > 1
                      ? "days"
                      : "day"
                    : item.Validity > 1
                    ? "months"
                    : "month"}
                </span>
              </h1>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-10 mb-8">
        <SplitSection data={trialInfo} />
      </div>
    </div>
  );
};

export default Pricing;
