import React from "react";
import TagLabel from "./TagLabel";
import MainHeaderText from "./MainHeaderText";
import Tilt from "../JS/VanillaTilt";
import ParagraphText from "./ParagraphText";

function SplitSection({ data, rightImage }) {
  return (
    <section>
      {rightImage ? (
        <div className="container py-50px">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-30px">
            {/* about left */}
            <div className="flex flex-col justify-center items-center">
              <div data-aos="fade-up" className="2xl:ml-65px">
                {data?.tagLabel?.length && <TagLabel text={data?.tagLabel} />}

                <MainHeaderText>{data?.headerText}</MainHeaderText>
                {data?.description?.map((sentence, index) => (
                  <ParagraphText key={index} mainText={sentence} />
                ))}
                {data?.points?.length > 0 && (
                  <ul className="space-y-5">
                    {data?.points?.map((item, index) => (
                      <li className="flex items-center group">
                        <i className="icofont-check px-2 py-2 text-primaryColor bg-whitegrey3 bg-opacity-40 group-hover:bg-primaryColor group-hover:text-white group-hover:opacity-100 mr-15px dark:bg-whitegrey1-dark" />
                        <p className="text-sm md:text-base font-medium text-blackColor dark:text-blackColor-dark">
                          {item}
                        </p>
                      </li>
                    ))}
                  </ul>
                )}

                {/* <p className="flex items-center gap-x-4 text-lg text-blackColor dark:text-blackColor-dark mb-25px">
       <img
         loading="lazy"
         src="./assets/images/about/about_15.png"
         alt="about"
       />
       <span>
         <b>10+ Years ExperienceIn</b> this game, Means Product
         Designing
       </span>
     </p> */}
                {/* <p className="text-sm md:text-base leading-7 text-contentColor dark:text-contentColor-dark">
     Whether you're a student eager to delve into new subjects or an educator striving to deliver top-notch instruction, Atlearn empowers you to unlock your full potential and achieve academic excellence like never before. Join us as we reshape the future of education, one seamless online classroom experience at a time.
     </p> */}
                {data?.linkText?.length && (
                  <div className="mt-30px">
                    <a
                      href={data?.linkHref}
                      className="text-sm md:text-size-15 text-whiteColor bg-primaryColor border border-primaryColor px-25px py-15px hover:text-primaryColor hover:bg-whiteColor rounded inline-block mr-6px md:mr-30px dark:hover:bg-whiteColor-dark dark:hover:text-whiteColor"
                    >
                      {data.linkText} <i className="icofont-long-arrow-right" />
                    </a>
                  </div>
                )}
              </div>
            </div>

            {/* about right */}
            {data?.imageSrc?.length && (
              <div data-aos="fade-up">
                <div className="tilt">
                  <Tilt>
                    <img
                      className="w-full  rounded-lg2"
                      src={data.imageSrc}
                      alt={data.imageAlt}
                    />
                  </Tilt>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          className={`container ${
            !data?.imageSrc?.length ? " py-10px" : " py-50px"
          } `}
        >
          {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-30px"> */}
          <div
            className={`grid grid-cols-1 ${
              !data?.imageSrc?.length ? "lg:grid-cols-1" : "lg:grid-cols-2"
            } gap-30px`}
          >
            {/* about left */}
            {data?.imageSrc?.length && (
              <div data-aos="fade-up">
                <div className="tilt">
                  <Tilt>
                    <img
                      className="w-full  rounded-lg2"
                      src={data.imageSrc}
                      alt={data.imageAlt}
                    />
                  </Tilt>
                </div>
              </div>
            )}
            <div className="flex flex-col justify-center items-center">
              {/* about right */}
              <div data-aos="fade-up" className="2xl:ml-65px">
                {data?.tagLabel?.length && <TagLabel text={data?.tagLabel} />}

                <MainHeaderText>{data?.headerText}</MainHeaderText>
                {data?.description?.map((sentence, index) => (
                  <ParagraphText mainText={sentence} />
                ))}
                {data?.points?.length > 0 && (
                  <ul className="space-y-5">
                    {data?.points?.map((item, index) => (
                      <li className="flex items-center group">
                        <i className="icofont-check px-2 py-2 text-primaryColor bg-whitegrey3 bg-opacity-40 group-hover:bg-primaryColor group-hover:text-white group-hover:opacity-100 mr-15px dark:bg-whitegrey1-dark" />
                        <p className="text-sm md:text-base font-medium text-blackColor dark:text-blackColor-dark">
                          {item}
                        </p>
                      </li>
                    ))}
                  </ul>
                )}

                {/* <p className="flex items-center gap-x-4 text-lg text-blackColor dark:text-blackColor-dark mb-25px">
     <img
       loading="lazy"
       src="./assets/images/about/about_15.png"
       alt="about"
     />
     <span>
       <b>10+ Years ExperienceIn</b> this game, Means Product
       Designing
     </span>
   </p> */}
                {/* <p className="text-sm md:text-base leading-7 text-contentColor dark:text-contentColor-dark">
   Whether you're a student eager to delve into new subjects or an educator striving to deliver top-notch instruction, Atlearn empowers you to unlock your full potential and achieve academic excellence like never before. Join us as we reshape the future of education, one seamless online classroom experience at a time.
   </p> */}
                {data?.linkText?.length && (
                  <div className="mt-30px">
                    <a
                      href={data?.linkHref}
                      className="text-sm md:text-size-15 text-whiteColor bg-primaryColor border border-primaryColor px-25px py-15px hover:text-primaryColor hover:bg-whiteColor rounded inline-block mr-6px md:mr-30px dark:hover:bg-whiteColor-dark dark:hover:text-whiteColor"
                    >
                      {data.linkText} <i className="icofont-long-arrow-right" />
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default SplitSection;
