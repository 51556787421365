import Learning_managementt2 from "src/assets/images/LMS/Learning-managementt2.webp"
export const AI_Enhanced_Learning ={

    imageSrc: "./assets/images/about/about_2.png",
    imageAlt: "About AI",
    tagLabel: "About Us",
    headerText: "Introduction to AI-Enhanced Learning",
    highlight:true,
    description: [
      `25+Contrary to popular belief, Lorem Ipsum is not simply random text roots in a piece of classical Latin literature from 45 BC`,
    
    ],
    points:[
        "Lorem Ipsum is simply dummy",
        "Explore a variety of fresh educational teach",
        "Lorem Ipsum is simply dummy text of"
    ]


 
  
}


export const E_Learning_Solution={

  // tagLabel: "About Us",
  headerText: "End to End E-Learning Solution",
  description: [
    `Akratech specializes in developing custom learning management systems (LMS) tailored to the needs of small businesses and established enterprises. Our dedicated team of developers works diligently to create comprehensive eLearning solutions from the ground up, aligned with your organization’s goals and business requirements. Drawing on our extensive experience, we’ve developed numerous educational platforms that foster immersive learning environments. Our LMS empowers educational communities to enhance collaboration, research, and teaching, supporting continuous e-learning experiences that nurture talent, promote skill development, and drive more engaged learning. With our expertise, we’ll build a robust, scalable, and high-performance online training solution for your company, clients, students, or staff. Whether you’re a student eager to explore new subjects or an educator seeking to deliver exceptional instruction, Akratech’s LMS unlocks your full potential, enabling you to achieve academic excellence. Join us as we revolutionize education, one seamless online classroom experience at a time.`,
  ],
};

export const Unlock_Smart_Learning={
  imageSrc: Learning_managementt2,
  imageAlt: "",
  headerText: "Unlock Smart Learning with Akratech's AI-Integrated LMS",
  description: [
    `Organizations, trainers, and students worldwide value our online learning solutions for their efficiency, rich features, scalability, and high performance. Our cost-effective learning management systems are thoughtfully designed to meet the needs of teachers, students, and organizations alike. By integrating AI into our LMS, we deliver personalized, AI-driven learning experiences that adapt to each user's needs.`,
  ],
}

export const Elevate_Your_Learning={

  headerText: "Elevate Your Learning Experience with Our Robust LMS Software",
  description: [
    `Organizations, trainers, and students worldwide value our online learning solutions for their efficiency, rich features, scalability, and high performance. Our cost-effective learning management systems are thoughtfully designed to meet the needs of teachers, students, and organizations alike. By integrating AI into our LMS, we deliver personalized, AI-driven learning experiences that adapt to each user's needs. This innovative approach enhances the learning journey by offering smart recommendations and real-time insights, ensuring a more engaging and effective educational experience for students.`,
  ],
  points:[
    "Atlearn is a comprehensive e-learning solution designed to assist schools, colleges, training institutes, and organizations in creating a collaborative online learning environment. This solution includes essential features such as Student Data Management, Messaging, Notifications, Student Monitoring, Dashboards, Reports, and Analytics.",
    "As an effective smart class management tool, Atlearn caters to schools, colleges, and organizations. Additional key features encompass online whiteboards, assessments, certification, calendars, screen sharing, recording, and collaboration tools",
    "Our AI-powered LMS offers a variety of innovative features aimed at enhancing the educational experience for both students and educators, including a Rubric Generator, Professional Email Communication, Multiple Choice Questions, Course Creation, and more",
    "Experience seamless video clarity that allows for easy connection and engagement, providing an immersive visual experience."
]

}