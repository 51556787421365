import diverse_participants_icon from "src/assets/images/online-classes/New/diverse-participants.svg"
import storage_benefits_icon from "src/assets/images/online-classes/New/storage-benefits.svg"
import recordings_icon from "src/assets/images/online-classes/New/recordings.svg"
import duration_icon from "src/assets/images/online-classes/New/duration.svg"
import chat_feature_icon  from "src/assets/images/online-classes/New/chat-feature.svg"
import screen_sharing_icon from "src/assets/images/online-classes/New/screen-sharing.svg"
import subscription_benefits_icon from "src/assets/images/online-classes/New/subscription-benefits.svg"
import share_room_access_icon from 'src/assets/images/online-classes/New/share-room-access.svg'
import breakout_rooms_icon from 'src/assets/images/online-classes/New/breakout-rooms.svg'
import shared_notes_icon from 'src/assets/images/online-classes/New/shared-notes.svg'
import share_recordings_icon from 'src/assets/images/online-classes/New/share-recordings.svg'
import multi_user_dashboard_icon from "src/assets/images/online-classes/New/multi-user-dashboard.svg"
import online_clasroom_scaled from "src/assets/images/online-classes/New/online-clasroom-scaled.webp"
import create_an_account from "src/assets/images/online-classes/New/create-an-account.svg"
import setup_your_classroom from "src/assets/images/online-classes/New/setup-your-classroom.svg"
import teach_your_classes from "src/assets/images/online-classes/New/teach-your-classes.svg"

export const onlineClaasesFeatures = [
  {
    title: "Diverse Participants",
    description: "The meeting room can accommodate numerous participants, and the moderator who has subscribed to specific plans will reap the advantage of an extended meeting duration.",
    image: diverse_participants_icon,
  },
  {
    title: "Storage benefits",
    description: "Moderators will have the advantage of utilizing storage benefits, enabling them to securely store files, recordings, and other relevant data",
    image: storage_benefits_icon,
  },
  {
    title: "Recordings",
    description: "The user can record their meeting room and can conveniently access it at any given time.",
    image: recordings_icon,
  },
  {
    title: "Duration",
    description: "Users are allotted meeting durations based on their subscription, allowing them to schedule and record meetings accordingly.",
    image: duration_icon,
  },
  {
    title: "Chat feature",
    description: "The participants in the meeting can utilize the Public / Private Chat feature to communicate or engage in conversation.",
    image: chat_feature_icon,
  },
  {
    title: "Screen Sharing",
    description: "Screen sharing allows the moderator to display their screen content to participants in a virtual meeting.",
    image: screen_sharing_icon,
  },
  {
    title: "Multi-user dashboard",
    description: "The multi-user dashboard on the AtLearn website enables multiple users to collaboratively access and manage educational resources, track progress, and participate in courses.",
    image: multi_user_dashboard_icon,
  },
  {
    title: "Subscription benefits",
    description: "The user can avail the advantage as per their subscription plan, along with a trial period of 14 days, depending on the subscription chosen",
    image: subscription_benefits_icon,
  },
  {
    title: "Share room access",
    description: "Share room access enables users to grant access to their rooms to other moderators and users, facilitating the creation or scheduling of shared rooms.",
    image: share_room_access_icon,
  },
  {
    title: "Breakout Rooms",
    description: "The moderator can utilize the Screen Sharing Multi-User Whiteboard during their presentation, enabling them to grant access to any participant for collaborative use.",
    image: breakout_rooms_icon,
  },
  {
    title: "Shared Notes",
    description: "Moderators can utilize breakout rooms to establish smaller internal meetings or discussions before the main meeting.",
    image: shared_notes_icon,
  },
  {
    title: "Share Recordings ",
    description: "The feature allows users to easily post meeting recordings to social media platforms directly from the online meeting interface.",
    image: share_recordings_icon,
  },
  // Add more subject objects as needed
];

export const BestOnlineClassroom={

    imageSrc: online_clasroom_scaled,
    imageAlt: "About AI",
    headerText: "Best Online Classroom Software to Embrace Your Virtual Learning",
    description: [
      `Seeking for best online classroom? Unlock your learning and training potential with “Atlearn”. Our virtual classroom is a fully developed and streamlined product, ready to use. With a deep understanding of the technical components, user experience design, and educational objectives, our team of experts has created an effective system for remote learning with our interactive classroom software. This virtual class platform is designed to engage users and learn the desired learning outcomes efficiently and effectively.`,
    ],

}

export const OnlineClassroomPlatform={

  headerText: "Atlearn | Online Classroom Platform",
  description: [
    `Atlearn is an innovative online classroom platform tailored for virtual classes. The platform is designed to enhance applied learning by providing students with the tools to collaborate effectively and receive immediate feedback. Our online classroom platform provides tools for teaching, such as shared whiteboards, audio/video conferencing, online test/quiz assessments, file sharing, and collaboration tools. Our online classroom platforms are practically oriented, involving various Tools & Techniques in our Sessions, Discussions, Demos, Problem Solving, Simulations, and instructor-led training programs. With Atlearn, you can easily create your own virtual classrooms to host interactive sessions or join others through a short, convenient link. This flexibility ensures that learning is seamless, engaging, and accessible from anywhere.`,
  ],
}

export const Benefits={
  headerText: "The Benefits Of Using Our Virtual Classroom Platforms",
  description: [
    `Our online training platform offers many benefits including greater convenience, reduced cost, improved student and as well as employee engagement, advanced tracking capabilities, and the potential for scalability. It also provides a more interactive learning experience with quizzes, polls, and simulations to help keep learners engaged in the material. Additionally, with an online system, trainers can access their courses from any internet-enabled device at any time.`,
  ],
}

export const SeamlessVideo={
  heading: "Seamless Video Clarity",
  paragraph:"Explore cutting-edge features designed to enhance your virtual classroom  experience with Atlearn",
  
}


export const SetUpanOnlineClassroom = {
  heading: "Set Up an Online Classroom",
  paragraph:"Easily set up your online classroom with Atlearn's user-friendly platform.",
};




export const SetUpanOnlineClassroomCards=[
  {
    title: "Create an account.",
    description: "To start using the Virtual Classroom platform, you need to sign up for a free account. Once you do so, you will receive an email with instructions on how to log into the system.",
    image: create_an_account,
  },
  {
    title: "Set up your classroom.",
    description: "After logging into the system, you will see a dashboard that shows you what classes are available and how many people are enrolled in each class. You can add new courses by clicking “Add New Course” at the top right corner of the screen.",
    image: setup_your_classroom,
  },
  {
    title: "Teach your classes.",
    description: "You can also add questions to each lesson plan, as well as assign them to individual students. If you want to give out homework, you can do so here too.",
    image: teach_your_classes,
  },
]