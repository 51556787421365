import { el } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import apiClients from "src/apiClients/apiClients";
import { setUser } from "src/Redux/userSlice";
import { formatDateRange } from "src/utils/formateDateRange";
import coverImage from "src/assets/images/online-classes/New/Classroom_Content_Online_Courses_Cover.webp"

function JoinRoom() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  const queryParams = new URLSearchParams(location.search);
  const Room_id = queryParams.get("roomId");
  const Sheduled_id = queryParams.get("scheduleId");
  const [room, setRoom] = useState();
  const [profile, setProfile] = useState("");
  const [scheduleMeetingData, setScheduleMeetingData] = useState();
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [access_Code, setAccess_Code] = useState("");
  const [accessCode, setAccessCode] = useState(false);
  const [moderator_AccessCode, setModerator_AccessCode] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [accessCodeError, setAccessCodeError] = useState(false);
  const [verifyPayment, setVerifyPayment] = useState(false);

  useEffect(() => {
    if (Room_id) {
      fetchRoom(Room_id);
      if (Room_id && Sheduled_id) {
        getSheduledMeetingDetails(Room_id, Sheduled_id);
      }
    }
  }, []);
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await apiClients.sessionData();
        if (response?.success === true) {
          if (response?.data) {
            setName(response.data.user.name);
            dispatch(setUser(response.data));
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRoom = async (id) => {
    try {
      const response = await apiClients.getroomUser(id);
      if (response.data) {
        setRoom(response.data);
        if (response.data?.room?.user?.avatar) {
          const newUrl = `${process.env.REACT_APP_OVERRIDE_HOST}/api/images/${response.data?.room?.user?.avatar}`;
          setProfile(newUrl);
        }
        // setProfileName(response.data?.room?.user?.name);
        // setProfileEmail(response.data?.room?.user?.email);
        if (response.data.settings.glRequireAuthentication === "true") {
        }

        if (response.data.settings) {
          if (response.data.settings.glViewerAccessCode !== "false") {
            setAccessCode(true);
          }
          if (response.data.settings.glModeratorAccessCode !== "false") {
            setModerator_AccessCode(true);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSheduledMeetingDetails = async (room_id, SheduledId) => {
    try {
      const data = {
        roomId: room_id,
        scheduleMeeting_Id: SheduledId || null,
      };
      const response = await apiClients.SheduledMeetingDetails(data);
      if (response?.currentMeeting || response?.nextMeeting) {
        setScheduleMeetingData(
          response?.currentMeeting || response?.nextMeeting
        );
        // if (user?.user) {
        //   setShowJoinRoom(true);
        //   // navigate("/login");
        // } else {
        //   setShowJoinRoom(false);
        // }
        // if (!user?.user) {
        //   setShowJoinRoom(false);
        // } else {
        //   setShowJoinRoom(true);
        // }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const checkFeild = () => {
    let error = false;
    if (!name) {
      error = true;
      setNameError(true);
    } else {
      setNameError(false);
    }
    if (
      (moderator_AccessCode &&
        room?.settings?.glAnyoneJoinAsModerator === "false" &&
        !accessCode) ||
      (!moderator_AccessCode && !accessCode)
    ) {
      setAccessCodeError(false);
    } else {
      if (!access_Code) {
        error = true;
        setAccessCodeError(true);
      } else {
        setAccessCodeError(false);
      }
    }
    if (room?.settings?.glModeratorAccessCode !== "false") {
      if (access_Code) {
        if (access_Code === room?.settings?.glModeratorAccessCode) {
          setAccessCodeError(false);
        } else if (access_Code === room?.settings?.glViewerAccessCode) {
          setAccessCodeError(false);
        } else {
          error = true;
          setAccessCodeError(true);
          return {
            error: error,
            message: "access code invalid",
          };
        }
      }
    }

    return error;
  };

  const handleJoinMeeting = async () => {
    try {
      let checkfield = checkFeild();

      if (checkfield === false) {
        setLoading(true);
        let data = {
          name,
          access_code: access_Code,
        };
        const friendly_id = room.room.friendly_id;
        const response = await apiClients.joinMeeting(friendly_id, data);
        if (response.success === true) {
          if (response.data) {
            setLoading(false);
            window.location.href = response.data.joinAttendeeUrl;
          }
        } else {
          toast.error(response.message);
          setLoading(false);
        }
      } else {
        toast.error(
          checkfield.message ? `${checkfield.message}` : "All fields required "
        );
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const checkPayment = async (userId, scheduleId) => {
    try {
      const data = {
        user_id: userId,
        schedule_id: scheduleId,
      };
      const response = await apiClients.checkMeetingPayment(data);
      if (response?.paymentStatus === true) {
        setVerifyPayment(true);
      } else {
        setVerifyPayment(false);
      }
    } catch (error) {
      console.log();
    }
  };

  const Payment = async () => {
    try {
      if(user?.user?.email){
        const data = {
          amount: scheduleMeetingData?.price,
        };
        const response = await apiClients.checkout(data);
        if (response) {
          initPayment(response.data);
        }
      }else{
        navigate(`/login?roomId=${Room_id}`)
      }
    
    } catch (error) {
      console.log(error);
    }
  };

  const initPayment = (data) => {
    var options = {
      key: process.env.RAZORPAY_ID,
      amount: data.amount,
      currency: data.currency,
      name: "Atlearn",
      description: "Test Transaction",
      image: "/assets/atlearnlogo.svg",
      order_id: data.id,
      handler: async function (response) {
        const body = {
          ...response,
          planName: "ScheduleMeetingPayment",
          userEmail: user?.user?.email,
          scheduleId: scheduleMeetingData?.id,
          roomId: scheduleMeetingData?.room_id,
          totalPrice: scheduleMeetingData?.price,
        };
        const validateRes = await fetch(
          `${process.env.REACT_APP_OVERRIDE_HOST}/api/checkout/roomPayment`,
          {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const jsonRes = await validateRes.json();
        if (jsonRes.success) {
          // handlenext();
          checkPayment(user?.user?.id, scheduleMeetingData?.id);
        }
      },
      prefill: {
        name: user?.user?.name,
        email: user?.user?.email,
        // contact: phone,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", async function (response) {
      const data = {
        razorpay_order_id: response.error.metadata.order_id,
        razorpay_payment_id: response.error.metadata.payment_id,
        planName: "addon",
        userEmail: user?.user?.email,
        totalPrice: scheduleMeetingData?.price,
      };
      // eslint-disable-next-line no-unused-vars
      const faildPayment = await apiClients.failedTransaction(data);
    });
    rzp1.open();
  };

  return (
    <div>
      <>
        {/*meeting details section */}
        <section>
          <div className="container py-10 md:py-50px lg:py-60px 2xl:py-100px">
            {/* meeting thumbnail */}
            <div className="overflow-hidden relative mb-50px">
              {/* <img
          // src="../../assets/images/zoom/details.jpg"
          src='http://localhost:7000/api/images/image-1731313129616.png'
   
          alt=""
          className="w-full h"
        /> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "400px",
                  overflow: "hidden",
                }}
              >
                <img
                  // src='http://localhost:7000/api/images/image-1731313129616.png'
                  // src={`${process.env.REACT_APP_OVERRIDE_HOST}/api/images/${room?.room?.cover_image_url}`}
                  src={room?.room?.cover_image_url 
                    ? `${process.env.REACT_APP_OVERRIDE_HOST}/api/images/${room?.room?.cover_image_url}` 
                    : coverImage}
                  alt={"alt"}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-12 gap-30px">
              <div className="lg:col-start-1 lg:col-span-8 space-y-[35px]">
                <div data-aos="fade-up">
                  {/*meeting content */}
                  <div className="space-y-5">
                    <h3
                      data-aos="fade-up"
                      className="text-2xl md:text-size-32 lg:text-size-28 2xl:text-size-34 leading-34px md:leading-10 2xl:leading-13.5 font-bold text-blackColor2 hover:text-primaryColor dark:text-blackColor2-dark dark:hover:text-primaryColor"
                    >
                      {room?.room?.name}
                    </h3>
                    {scheduleMeetingData && (
                      <p
                        className="text-sm 2xl:text-lg text-darkdeep4 !leading-30px"
                        data-aos="fade-up"
                      >
                        {scheduleMeetingData
                          ? scheduleMeetingData?.description
                          : ""}
                      </p>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-1 xl:grid-cols-1 mb-30px gap-30px">
                  <div data-aos="fade-up" className="relative">
                    <input
                      name="con_name"
                      id="con_name"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter your name*"
                      className={`w-full pl-26px bg-transparent focus:outline-none 
                        text-contentColor dark:text-contentColor-dark 
                        placeholder:text-placeholder placeholder:opacity-80 
                        h-15 leading-15 font-medium rounded border
                        ${
                          nameError
                            ? "border-red-500"
                            : " border-borderColor2 dark:border-borderColor2-dark"
                        }`}
                    />
                    <div className="text-xl leading-23px text-primaryColor absolute right-6 top-1/2 -translate-y-1/2">
                      <i className="icofont-businessman" />
                    </div>
                  </div>
                  {moderator_AccessCode || accessCode ? (
                    <div data-aos="fade-up" className="relative">
                      <label
                        htmlFor="con_name"
                        className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
                      >
                        {moderator_AccessCode &&
                        room?.settings?.glAnyoneJoinAsModerator === "false" &&
                        !accessCode
                          ? "Moderator Access Code (optional)"
                          : moderator_AccessCode &&
                            room?.settings?.glAnyoneJoinAsModerator ===
                              "true" &&
                            !accessCode
                          ? "Moderator Access Code"
                          : "Access Code"}
                      </label>
                      <input
                        name="con_name"
                        id="con_name"
                        type="text"
                        value={access_Code}
                        onChange={(e) => {
                          setAccess_Code(e.target.value);
                          setAccessCodeError(false);
                        }}
                        placeholder="Enter the access code"
                        className={`w-full pl-26px bg-transparent focus:outline-none 
                          text-contentColor dark:text-contentColor-dark 
                          placeholder:text-placeholder placeholder:opacity-80 
                          h-15 leading-15 font-medium rounded border
                          ${
                            accessCodeError
                              ? "border-red-500"
                              : " border-borderColor2 dark:border-borderColor2-dark"
                          }`}
                      />
                      <div className="text-xl leading-23px text-primaryColor absolute right-6 top-1/2 -translate-y-1/2">
                        <i className="icofont-ui-password" />
                      </div>
                    </div>
                  ) : null}
                  <div>
                    {verifyPayment || !scheduleMeetingData?.price ? (
                      <button
                        onClick={handleJoinMeeting}
                        type="submit"
                        className="text-size-15 text-whiteColor bg-primaryColor px-14 py-10px border border-primaryColor hover:text-primaryColor hover:bg-whiteColor inline-block rounded group dark:hover:text-whiteColor dark:hover:bg-whiteColor-dark"
                      >
                        {loading && (
                          <span className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full border-t-transparent mr-2"></span>
                        )}
                        Join Meeting
                      </button>
                    ) : (
                      <button
                        onClick={Payment}
                        type="submit"
                        className="text-size-15 text-whiteColor bg-primaryColor px-14 py-10px border border-primaryColor hover:text-primaryColor hover:bg-whiteColor inline-block rounded group dark:hover:text-whiteColor dark:hover:bg-whiteColor-dark"
                      >
                        {loading && (
                          <span className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full border-t-transparent mr-2"></span>
                        )}
                        Pay For Meeting
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {/* blog sidebar */}
              <div className="lg:col-start-9 lg:col-span-4">
                <div>
                  {/* enroll section */}
                  <div
                    className="py-33px px-25px shadow-event mb-30px bg-whiteColor dark:bg-whiteColor-dark"
                    data-aos="fade-up"
                  >
                    <div className="flex justify-between mb-50px">
                      {scheduleMeetingData?.price > 0 ? (
                        <div className="text-size-21 font-bold text-primaryColor font-inter leading-25px">
                          ₹ {scheduleMeetingData?.price}
                        </div>
                      ) : (
                        <span className="">
                          <span className="text-base font-semibold text-greencolor">
                            Free
                          </span>
                        </span>
                      )}

                      {/* {scheduleMeetingData?.price > 0 && (
                    <div className="flex items-center">
                      <div className="bg-primary text-white font-bold py-1 px-4 rounded-full text-lg shadow-sm">
                        ₹ {scheduleMeetingData?.price}
                      </div>
                      {verifyPayment && (
                        <div className="ml-2 bg-green-500 text-xs text-white px-2 py-1 rounded-full">
                          Paid
                        </div>
                      )}
                    </div>
                  )} */}
                    </div>
                    <ul>
                      <li className="flex items-center mb-4.5 pb-4.5 border-b border-borderColor dark:border-borderColor-dark">
                        <div>
                          <i className="icofont-teacher text-size-22 mr-10px text-blackColor dark:text-blackColor-dark" />
                        </div>
                        <div className="flex-grow flex justify-between items-center text-blackColor dark:text-blackColor-dark">
                          <p>Instructor:</p>
                          <a
                            className="font-bold text-contentColor hover:text-secondaryColor dark:text-contentColor-dark dark:hover:text-secondaryColor"
                            href="../../instructor-details.html"
                          >
                            {room?.room?.user?.name}
                          </a>
                        </div>
                      </li>
                      <li className="flex items-center mb-4.5 pb-4.5 border-b border-borderColor dark:border-borderColor-dark">
                        <div>
                          <i className="icofont-clock-time text-size-22 mr-10px text-blackColor dark:text-blackColor-dark" />
                        </div>
                        <div className="flex-grow flex justify-between items-center text-blackColor dark:text-blackColor-dark">
                          <p>Time:</p>
                          {/* <p>3.00PM</p> */}
                          <p>
                            {" "}
                            {
                              formatDateRange(
                                scheduleMeetingData?.startDate,
                                scheduleMeetingData?.endDate
                              ).formattedStartTime
                            }{" "}
                            -{" "}
                            {
                              formatDateRange(
                                scheduleMeetingData?.startDate,
                                scheduleMeetingData?.endDate
                              ).formattedEndTime
                            }
                          </p>
                        </div>
                      </li>
                      <li className="flex items-center mb-4.5 pb-4.5 border-b border-borderColor dark:border-borderColor-dark">
                        <div>
                          <i className="icofont-calendar text-size-22 mr-10px text-blackColor dark:text-blackColor-dark" />
                        </div>
                        <div className="flex-grow flex justify-between items-center text-blackColor dark:text-blackColor-dark">
                          <p>Date:</p>
                          <p>
                            {" "}
                            {
                              formatDateRange(
                                scheduleMeetingData?.startDate,
                                scheduleMeetingData?.endDate
                              ).formattedStartDate
                            }
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </div>
  );
}

export default JoinRoom;
