import React, { useEffect } from "react";
import FeaturesCard from "src/components/New components/FeaturesCard";
import SectionHeading from "src/components/New components/SectionHeading";
import SplitSection from "src/components/New components/SplitSection";
import BrandSection from "src/components/New components/BrandSection";
import {
  analyticsandReporting,
  AssignmentIntegration,
  communityTools,
  CourseIntegration,
  CourseIntegrationfeatures,
  courseManagementSystem,
  integrationBenefits,
  integrationBenefitsFeatures,
  onlineEventManagement,
  teachersRegistration,
  test_Quiz_Integration,
} from "src/Page_Content/Integration";
import { Link, useLocation } from "react-router-dom";

function Integration() {
  const subjectSectoion = [
    {
      title: "Course Integration",
      link: "/integrationPage#Course-Integration",
    },
    {
      title: "Assignment Integration",
      link: "/integrationPage#Assignment-Integration",
    },
    {
      title: "Test / Quiz Integration",
      link: "/integrationPage#Quiz-Integration",
    },
    {
      title: "Teacher's Registration",
      link: "/integrationPage#Teacher-Registration",
    },
    {
      title: "Course Management System",
      link: "/integrationPage#Course-Management-System",
    },
    {
      title: "Online Event Management",
      link: "/integrationPage#Online-Event-Management",
    },
    {
      title: "Community Tools",
      link: "/integrationPage#Community-Tools",
    },
    {
      title: "Analytics and Reporting",
      link: "/integrationPage#Analytics-Reporting",
    },
    {
      title: "3rd Party Integration",
      link: "/integrationPage#Party-Integration",
    },
  ];

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const section = document.querySelector(location.hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div>
      <section>
        {/* bannaer section */}
        <div className="container2-xl bg-darkdeep1 py-50px  rounded-2xl relative overflow-hidden shadow-brand">
          <div className="container">
            <div className="flex flex-col items-center text-center w-full lg:w-83% xl:w-3/4 mx-auto">
              {/* banner Left */}
              <div data-aos="fade-up" className="w-4/5">
                <h3 className="uppercase text-secondaryColor text-size-15 mb-5px md:mb-15px font-inter tracking-5px">
                  EDUCATION SOLUTION
                </h3>
                <h1 className="text-3xl text-whiteColor md:text-6xl lg:text-size-50 2xl:text-6xl leading-10 md:leading-18 lg:leading-62px 2xl:leading-18 md:tracking-half lg:tracking-normal 2xl:tracking-half font-bold mb-15px sm:mb-30px">
                  Seamless Integration: Elevate Your Learning Experience with
                  Atlearn
                  <span className="text-secondaryColor">.</span>
                </h1>
              </div>

              <ul className="flex gap-x-5 flex-wrap justify-center items-center mt-7 md:mt-50px">
                <li>
                  <p className="text-whiteColor">
                    <i className="icofont-check-alt" />
                    <span className="ml-5px">More than 2k Courses</span>
                  </p>
                </li>
                <li>
                  <p className="text-whiteColor">
                    <i className="icofont-check-alt" />
                    <span className="ml-5px">1.1k Free Courses</span>
                  </p>
                </li>
                <li>
                  <p className="text-whiteColor">
                    <i className="icofont-check-alt" />
                    <span className="ml-5px">150+ Instructors</span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <img
              className="absolute left-1/2 bottom-[15%] animate-spin-slow"
              src="./assets/images/register/register__2.png"
              alt=""
            />
            <img
              className="absolute left-[42%] sm:left-[65%] md:left-[42%] lg:left-[5%] top-[4%] sm:top-[1%] md:top-[4%] lg:top-[10%] animate-move-hor"
              src="./assets/images/herobanner/herobanner__6.png"
              alt=""
            />
            <img
              className="absolute right-[5%] bottom-[15%]"
              src="./assets/images/herobanner/herobanner__7.png"
              alt=""
            />
            <img
              className="absolute top-[5%] left-[45%]"
              src="./assets/images/herobanner/herobanner__7.png"
              alt=""
            />
          </div>
        </div>
      </section>
      {/*  brand section */}
      <BrandSection />
      <>
        {/* Popular Subject Sectoion*/}
        <section>
          <div className="container">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-30px pt-10 md:pt-0 pb-100px">
              {subjectSectoion?.map((item, index) => (
                <Link
                  to={`${item?.link}`}
                  className="pt-5 pb-15px px-30px rounded-5px bg-borderColor text-center hover:bg-secondaryColor dark:bg-borderColor-dark dark:hover:bg-secondaryColor group"
                  data-aos="fade-up"
                >
                  <h4 className="text-blackColor text-lg font-medium group-hover:text-blackColor-dark dark:text-blackColor-dark">
                    {/* Art &amp; Design */}
                    {item?.title}
                  </h4>
                  {/* <p className="text-xs text-contentColor group-hover:text-contentColor-dark dark:text-contentColor-dark">
                    10 Courses
                  </p> */}
                </Link>
              ))}
            </div>
          </div>
        </section>
      </>
      <div id="Course-Integration">
        <SectionHeading data={CourseIntegration} />
        <FeaturesCard data={CourseIntegrationfeatures} row={3} />
      </div>
      <div id="Assignment-Integration">
        <SplitSection data={AssignmentIntegration} />
      </div>
      <div id="Quiz-Integration">
        <SplitSection data={test_Quiz_Integration} rightImage={true} />
      </div>
      <div id="Teacher-Registration">
        <SplitSection data={teachersRegistration} />
      </div>
      <div id="Course-Management-System">
        <SplitSection data={courseManagementSystem} rightImage={true} />
      </div>
      <div id="Online-Event-Management">
        <SplitSection data={onlineEventManagement} />
      </div>
      <div id="Community-Tools">
        <SplitSection data={communityTools} rightImage={true} />
      </div>
      <div id="Analytics-Reporting">
        <SplitSection data={analyticsandReporting} />
      </div>

      <div id="Party-Integration">
        <SectionHeading data={integrationBenefits} />
        <FeaturesCard data={integrationBenefitsFeatures} row={3} />
      </div>
    </div>
  );
}

export default Integration;
